<template>
  <div class="text-center">
    <v-dialog persistent v-model="estatus" width="500">
      <v-card>
        <v-card-title class="headline indigo">
          <h4>Select a team member</h4>
        </v-card-title>

        <v-card-text>
          <v-row align="center">
            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                :items="itemsEncargados"
                label="Team members"
                dense
                v-model="selectEncargados"
                outlined
                item-value="idEmpleado"
                item-text="nombre"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#EF6C00" dark @click="AgregarEncargado"> Acept </v-btn>

          <v-btn color="#EF6C00" dark @click="Cerrar"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db } from "../firebase";
export default {
  props: {
    estatus: Boolean,
    datos: {
      type: Object,
      default: () => {},
    },
    estatus: Boolean,
  },
  data: () => ({
    itemsEncargados: [],
    selectEncargados: [],
  }),
  created() {
    this.ConsultarEncargados();
  },
  methods: {
    Cerrar() {
      this.$emit("cerrar");
    },
    ConsultarEncargados() {
      console.log(this.selectEncargados);
      let parametros = new URLSearchParams();
      parametros.append("accion", 5);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.itemsEncargados = [];
            response.data.forEach((element) => {
              this.itemsEncargados.push(element);
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    AgregarEncargado() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 16);
      parametros.append("idEmpleado", this.selectEncargados);
      parametros.append("idTarea", this.datos.idTarea);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.EnviarNotificacion();
            this.Cerrar();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    EnviarNotificacion() {
      var nombre = this.selectEncargados;
      db.collection("noty" + nombre)
        .add({
          //Nodo padre del que queremos sacar los nodos hijos
          name: nombre,
          type: "Room chats",
          message: "Fuiste agregado al chat de la tarea:",
          time: Date.now(),
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error
    },
  },
};
</script>

<style>
h4 {
  color: white;
}
</style>