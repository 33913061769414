<template>
  <div>
    <v-row>
      <v-row class="mt-16 ml-4 mr-4">
        <v-col>
          <v-col>
            <v-menu
              class="hidden-sm-and-down"
              v-for="([tex, rounded], index) in btns2"
              :key="tex"
              offset-y
            >
              <template v-slot:activator="{ attrs, on }">
                <v-card
                  v-bind="attrs"
                  v-on="on"
                  height="70px"
                  class="rounded-xl"
                  :color="taxes ? '#ef6c00' : '#FFFFFF'"
                  elevation="16"
                  @click="
                    (taxes = true),
                      (company = false),
                      (bookkeeping = false),
                      (payroll = false)
                  "
                >
                  <v-row>
                    <v-btn
                      class="mt-5 hidden-sm-and-down dark--text"
                      text
                      block
                    >
                      <v-icon :color="taxes ? '#FFFFFF' : '#0d47a1'" large left
                        >mdi-cash</v-icon
                      >
                      <h3
                        class="ml-2"
                        :style="taxes ? 'color:#FFFFFF' : 'color:#0d47a1'"
                      >
                        Payroll
                      </h3>
                    </v-btn></v-row
                  >
                </v-card>
              </template>

              <v-list v-if="departamentos.payroll">
                <v-list-item
                  v-on:click="EnviarAccion(items)"
                  v-for="items in toolsPayroll"
                  :key="items"
                  link
                >
                  <v-list-item-title v-text="items"></v-list-item-title>
                </v-list-item>
              </v-list>

              <v-list v-else>
                <v-list-item
                  v-on:click="EnviarAccion(item)"
                  v-for="item in toolsPayrollBook"
                  :key="item"
                  link
                >
                  <v-list-item-title v-text="item"></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-col>

        <v-col>
          <v-col>
            <v-menu
              class="hidden-sm-and-down"
              v-for="([texto, rounded], index) in btns1"
              :key="texto"
              offset-y
            >
              <template v-slot:activator="{ attrs, on }">
                <v-card
                  v-bind="attrs"
                  v-on="on"
                  class="rounded-xl"
                  elevation="16"
                  :color="bookkeeping ? '#EF6C00' : '#FFFFFF'"
                  height="70px"
                  @click="
                    (taxes = false),
                      (company = false),
                      (bookkeeping = true),
                      (payroll = false)
                  "
                >
                  <v-row>
                    <v-btn
                      class="mt-5 hidden-sm-and-down dark--text"
                      block
                      text
                    >
                      <v-icon
                        :color="bookkeeping ? '#FFFFFF' : '#0d47a1'"
                        large
                        left
                        >mdi-book</v-icon
                      >
                      <h3
                        class="ml-2"
                        :style="bookkeeping ? 'color:#FFFFFF' : 'color:#0d47a1'"
                      >
                        Bookkeeping
                      </h3>
                    </v-btn>
                  </v-row>
                </v-card>
              </template>

              <v-list :if="departamentos.bookkepping">
                <v-list-item
                  v-on:click="EnviarAccion(item)"
                  v-for="item in toolsBookkeeping"
                  :key="item"
                  link
                >
                  <v-list-item-title v-text="item"></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-col>

        <v-col>
          <v-col>
            <v-card
              v-bind="attrs"
              v-on="on"
              class="rounded-xl"
              :color="payroll ? '#EF6C00' : '#FFFFFF'"
              elevation="16"
              height="70px"
              @click="
                EnviarAccion('Taxes Return'),
                  (taxes = false),
                  (company = false),
                  (bookkeeping = false),
                  (payroll = true)
              "
            >
              <v-row>
                <v-btn
                  class="mt-5 hidden-sm-and-down dark--text"
                  :color="taxes ? '#0D47A1' : '#FFFFFF'"
                  block
                  text
                >
                  <v-icon :color="payroll ? '#FFFFFF' : '#0d47a1'" large left
                    >mdi-sale</v-icon
                  >
                  <h3
                    class="ml-2"
                    :style="payroll ? 'color:#FFFFFF' : 'color:#0d47a1'"
                  >
                    Taxes
                  </h3>
                </v-btn>
              </v-row>
            </v-card>
          </v-col>
        </v-col>
        <v-col>
          <v-col>
            <v-card
              :color="company ? '#EF6C00' : '#FFFFFF'"
              v-bind="attrs"
              v-on="on"
              class="rounded-xl"
              @click="
                EnviarAccion('Folders'),
                  (taxes = false),
                  (company = true),
                  (bookkeeping = false),
                  (payroll = false)
              "
              elevation="16"
              height="70px"
            >
              <v-row> </v-row>
              <v-btn
                class="mt-5 hidden-sm-and-down"
                :color="company ? '#0D47A1' : '#FFFFFF'"
                block
                text
              >
                <v-icon :color="company ? '#FFFFFF' : '#0d47a1'" large left
                  >mdi-domain</v-icon
                >
                <h3
                  class="ml-2"
                  :style="company ? 'color:#FFFFFF' : 'color:#0d47a1'"
                >
                  Companies
                </h3>
              </v-btn>
            </v-card>

            <v-list :if="departamentos.loans">
              <v-list-item
                v-on:click="EnviarAccion(item)"
                v-for="item in toolsLoands"
                :key="item"
                link
              >
                <v-list-item-title v-text="item"></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-col>
        </v-col>
      </v-row>
    </v-row>
  </div>
</template>

<script>
import main from "../App";
import EventBus from "../bus";
import Card from "@/examples/Cards/CardsService.vue";

export default {
  props: {
    estatus: Boolean,
  },
  components: {
    main,
    Card,
  },
  data() {
    return {
      taxes: false,
      company: false,
      bookkeeping: false,
      payroll: false,
      items: [
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg",
        },
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/sky.jpg",
        },
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/bird.jpg",
        },
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/planet.jpg",
        },
      ],
      stats: {
        money: {
          title: "Payroll",
          value: "",
          percentage: "",
          iconClass: "",
          detail: "",
          iconBackground: "",
        },
        users: {
          title: "Bookkeeping",
          value: "",
          percentage: "",
          iconClass: "",
          iconBackground: "",
          detail: "",
        },
        clients: {
          title: "Company",
          value: "",
          percentage: "",
          iconClass: "",
          percentageColor: "",
          iconBackground: "",
          detail: "",
        },
        sales: {
          title: "Tax Forms",
          value: "",
          percentage: "",
          iconClass: "",
          iconBackground: "",
          detail: "",
        },
      },

      colors: ["", "error", "teal darken-1"],
      idDepartamentos: [],
      accion: "",
      toolsAdmin: ["Task Manager", "Task Center", "Users"],
      tools: ["Task Center"],
      departamentos: {
        payroll: false,
        bookkepping: false,
        system: false,
        support: false,
        managers: false,
        loans: false,
        taxes: false,
      },
      toolsBookkeeping: [
        "Financial Statements",
        "Sales Taxes",
        "Accounting Books",
        "Vendors",
        "Excel Editor",
      ],
      toolsPayroll: ["Checks", "Payroll Reports", "Forms", "Other", "EFTPS"],
      toolsCliente: ["Bookkeeping"],
      toolsPayrollBook: [
        "Checks",
        "Payroll Reports",
        "Forms",
        "Other",
        "EFTPS",
      ], //["Reports", "Documents"],
      toolsLoands: [""],
      toolsTaxes: [""],
      btns1: [["Custom1", "b-xl"]],
      btns2: [["Custom2", "b-xl"]],
      btns3: [["Custom3", "b-xl"]],
      btns4: [["Custom4", "b-xl"]],
      btns5: [["Custom5", "b-xl"]],
      btns6: [["Custom6", "b-xl"]],
    };
  },
  methods: {
    ShowMyPayroll() {
      this.$router.push("/MyPayroll").catch((failure) => {
        alert("this page is no available");
      });
    },
    ShowMyBookkeeping() {
      this.$router.push("/MyBookkeeping").catch((failure) => {
        alert("this page is no available");
      });
    },
    ShowMyTax() {
      this.$router.push("/MyTax").catch((failure) => {
        alert("this page is no available");
      });
    },
    addComment: function (addComment) {
      EventBus.$emit(
        "add-comment",
        addComment,
        sessionStorage.getItem("cliente")
      );
    },
    AccionMenu(accion) {
      this.accion = accion;
    },
    EnviarAccion(accion) {
      this.addComment(accion);
      this.$emit("AccionMenu", accion);
    },
    MostrarDepartamentos() {
      this.idDepartamentos.forEach((element) => {
        if (element.idDepartamento == 12) {
          this.departamentos.payroll = true;
        }

        /*if(element.idDepartamento==45){
          this.departamentos.taxes=true;
        }*/

        if (element.idDepartamento == 88) {
          this.departamentos.bookkepping = true;
        }

        if (element.idDepartamento == 89) {
          this.departamentos.system = true;
        }

        if (element.idDepartamento == 90) {
          this.departamentos.support = true;
        }

        if (element.idDepartamento == 91) {
          this.departamentos.managers = true;
        }

        if (element.idDepartamento == 92) {
          this.departamentos.loans = true;
        }

        if (element.idDepartamento == 93) {
          this.departamentos.taxes = true;
        }
      });
    },
  },
  mounted() {
    this.idDepartamentos = JSON.parse(sessionStorage.getItem("idDepartamento"));
    this.MostrarDepartamentos();
  },
};
</script>

<style>
</style>