<template>
  <v-row class="mt-16 ml-5 mr-5">
    <v-dialog v-model="dialogViewer">
      <VueDocPreview :value="rutaArchivo" type="office" />
    </v-dialog>
    <v-col cols="12" sm="6" md="4">
      <v-text-field
        v-model="clientes[0].nombre"
        label="Company"
        outlined
        readonly
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <v-text-field
        v-model="clientes[0].nombreContacto"
        label="Owner"
        outlined
        readonly
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <v-text-field
        v-model="clientes[0].telefonoContacto"
        label="Telephone"
        outlined
        readonly
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <v-text-field
        v-model="clientes[0].telefono2"
        label="Telephone 2"
        outlined
        readonly
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <v-text-field
        v-model="clientes[0].telefono3"
        label="Telephone 3"
        outlined
        readonly
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <v-text-field
        v-model="clientes[0].correo"
        label="Email"
        outlined
        readonly
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <v-text-field
        v-model="clientes[0].direccion"
        label="Address"
        outlined
        readonly
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <v-text-field
        v-model="clientes[0].city"
        label="City"
        outlined
        readonly
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <v-text-field
        v-model="clientes[0].state"
        label="State"
        outlined
        readonly
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <v-text-field
        v-model="clientes[0].Country"
        label="Country"
        outlined
        readonly
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <v-text-field
        v-model="clientes[0].EIN"
        label="EIN"
        outlined
        readonly
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <v-select
        item-value="idBusiness"
        item-text="nombre"
        v-model="clientes[0].idBusinessType"
        :items="business"
        label="Business Type"
        required
        outlined
      ></v-select>
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <v-select
        v-model="clientes[0].idioma"
        :items="idioma"
        label="Language"
        required
        outlined
      ></v-select>
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <v-select
        item-value="idServicio"
        item-text="servicio"
        v-model="selectedServices"
        :items="servicios"
        label="Services"
        multiple
        outlined
      >
        <template v-slot:prepend-item>
          <v-list-item ripple @click="toggle">
            <v-list-item-action>
              <v-icon
                :color="selectedServices.length > 0 ? 'indigo darken-4' : ''"
              >
                {{ icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title> Select All </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>

        <template v-slot:append-item>
          <v-divider class="mb-2"></v-divider>
          <v-list-item disabled> </v-list-item>
        </template>
      </v-select>
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <v-btn
        color="#0D47A1"
        class="text-none"
        dark
        rounded
        x-large
        @click="dialogoArchivos = true"
      >
        <v-icon left> mdi-file </v-icon>
        Files
      </v-btn>
    </v-col>

    <v-dialog v-model="dialogoArchivos" width="40%">
      <div
        class="flex w-full h-screen items-center justify-center text-center"
        id="app"
      >
        <div
          class="p-12 bg-gray-100 border border-gray-300"
          @dragover="dragover"
          @dragleave="dragleave"
          @drop="drop"
        >
          <input
            type="file"
            multiple
            name="fields[assetsFieldHandle][]"
            class="w-px h-px opacity-0 overflow-hidden absolute d-none filepond"
            @change="SubirArchisServidor"
            ref="file"
            accept=".pdf,.jpg,.jpeg,.png"
          />

          <label for="assetsFieldHandle" class="block cursor-pointer">
            <div>





              <!--Aqui va el componente o vista para archivos-->
              <v-card>
                <v-card-title class="headline indigo">
                  <h4>Files</h4>
                </v-card-title>

                <v-card-text>
                  <v-col cols="2">
                    <v-btn
                      color="#0D47A1"
                      class="text-none"
                      dark
                      round
                      depressed
                      @click="onButtonClick"
                    >
                      <v-icon left> mdi-cloud-upload </v-icon>
                      Upload
                    </v-btn>

                    <input
                      ref="uploader"
                      class="d-none filepond"
                      type="file"
                      @change="SubirArchisServidor"
                    />
                  </v-col>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headerFile"
                        :items="archivos"
                        :items-per-page="5"
                        class="elevation-15"
                      >
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon
                            color="indigo"
                            class="mr-2"
                            @click="downloadItem(item)"
                          >
                            mdi-download
                          </v-icon>

                          <v-icon
                            color="indigo"
                            class="mr-2"
                            @click="
                              (confirmDeleteFile = true),
                                (idDocumento = item.idDocumento)
                            "
                          >
                            mdi-delete
                          </v-icon>

                          <v-icon
                            color="indigo"
                            class="mr-2"
                            @click="Redireccionar(item)"
                          >
                            mdi-eye
                          </v-icon>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="#EF6C00" dark @click="dialogoArchivos = false">
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>






              
            </div>
          </label>
          <ul class="mt-4" v-if="this.filelist.length" v-cloak></ul>
        </div>
      </div>
    </v-dialog>

    <v-dialog v-model="confirmDeleteFile" max-width="25%">
      <v-tabs show-arrows background-color="#0D47A1" icons-and-text dark grow>
        <v-tabs-slider color="#0D47A1 "></v-tabs-slider>
        <v-tab>
          <v-icon right></v-icon>
        </v-tab>
        <v-tab-item>
          <v-card>
            <v-card-text>
              <v-form lazy-validation>
                <v-row>
                  <v-card-text>
                    <v-row cols="12" sm="3" md="6">
                      <v-col><h3>Are you sure you want to delete ?</h3></v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      large
                      color="#EF6C00"
                      @click="confirmDeleteFile = false"
                      dark
                    >
                      Cancel
                    </v-btn>

                    <v-btn
                      color="#EF6C00"
                      large
                      dark
                      @click="EliminarArchivo(idDocumento)"
                    >
                      OK
                    </v-btn>
                  </v-card-actions>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-dialog>

    <v-dialog v-model="confirmDeleteEmployee" max-width="25%">
      <v-tabs show-arrows background-color="#0D47A1" icons-and-text dark grow>
        <v-tabs-slider color="#0D47A1 "></v-tabs-slider>
        <v-tab>
          <v-icon right></v-icon>
        </v-tab>
        <v-tab-item>
          <v-card>
            <v-card-text>
              <v-form lazy-validation>
                <v-row>
                  <v-card-text>
                    <v-row cols="12" sm="3" md="6">
                      <v-col><h3>Are you sure you want to delete ?</h3></v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      large
                      color="#EF6C00"
                      @click="confirmDeleteEmployee = false"
                      dark
                    >
                      Cancel
                    </v-btn>

                    <v-btn
                      color="#EF6C00"
                      large
                      dark
                      @click="EliminarArchivo(idDocumento)"
                    >
                      OK
                    </v-btn>
                  </v-card-actions>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-dialog>
  </v-row>
</template>


<script>
import { db, storage } from "../firebase";
import moment from "moment";
import pdf from "vue-pdf";
import EventBus from "../bus";
import VueDocPreview from "vue-doc-preview";
var qs = require("qs");
export default {
  components: {
    VueDocPreview,
    pdf,
  },
  data: () => ({
    rutaArchivo: "",
    dialogViewer: false,
    extension: "",
    confirmDeleteEmployee: false,
    filelist: [],
    confirmDeleteFile: false,
    idDocumento: "",
    archivos: [],
    headerFile: [
      {
        text: "Name file",
        align: "start",
        sortable: false,
        value: "nombreArchivo",
      },
      { text: "Accions", value: "actions", sortable: false, align: "center" },
    ],
    dialogoArchivos: false,
    dialogoDetalle: false,
    business: [],
    idioma: ["English", "Spanish"],
    search: "",
    selectedServices: [],
    servicios: [],
    value: [],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Company",

        sortable: false,
        value: "nombre",
      },
      {
        text: "Owner",

        sortable: false,
        value: "nombreContacto",
      },
      { text: "Telephone", value: "telefonoContacto" },
      { text: "Email", value: "correo" },

      { text: "Business type", value: "business" },
      { text: "Actions", value: "actions" },
    ],
    clientes: [],
    editedIndex: -1,
    editedItem: {
      Company: "",
      Owner: 0,
      Telephone: 0,
      Email: 0,
      Address: 0,
      city: "",
      state: "",
      EIN: "",
      idBusinessType: "",
      Services: [],
      idUsuario: "",
    },
    defaultItem: {
      Company: "",
      Owner: 0,
      Telephone: 0,
      Email: 0,
      Address: 0,
      city: "",
      state: "",
      EIN: "",
      idBusinessType: "",
      Services: [],
      idUsuario: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Company" : "Edit Company";
    },
    likesAllFruit() {
      return this.selectedServices.length === this.servicios.length;
    },
    likesSomeFruit() {
      return this.selectedServices.length > 0 && !this.likesAllFruit;
    },
    icon() {
      if (this.likesAllFruit) return "mdi-close-box";
      if (this.likesSomeFruit) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    
    this.ConsultarServicios();
    this.ConsultarBusiness();
    this.initialize();
    EventBus.$emit("hint-taskcenter");
  },
  methods: {
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    ConsultarArchivos() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 9);
      parametros.append("idCliente", sessionStorage.getItem("idCliente"));

      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorTrabajadores.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.archivos = [];
            console.log(response.data);

            response.data.forEach((element) => {
              var extension = /[.]/.exec(element.nombreArchivo)
                ? /[^.]+$/.exec(element.nombreArchivo)[0]
                : undefined;

              this.archivos.push({
                idDocumento: element.idDocumento,
                nombreArchivo: element.nombreArchivo,
                ruta: element.ruta,
                fecha: element.fecha,
                estatus: element.estatus,
                extension: extension,
                idCliente: element.idCliente,
                idTrabajador: element.idTrabajador,
              });
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },

    SubirArchisServidor(event) {
      this.file = event.target.files[0];
      var formData = new FormData();
      formData.append("archivo", event.target.files[0]);
      formData.append("accion", 8);
      formData.append("idTrabajador", this.idTrabajador);
      formData.append("idCliente", sessionStorage.getItem("idCliente"));
      formData.append("nombreArchivo", this.file.name);

      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorTrabajadores.php",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(
          function (response) {
            console.log(response.data);
            this.ConsultarArchivos();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    Redireccionar(item) {
      console.log(item.ruta);
      this.rutaArchivo = item.ruta;
      this.extension = item.extension;
      //console.log(this.rutaArchivo);
      // window.location.href = item.url;

      if (this.extension == "docx" || this.extension == "xlsx") {
        //deterina que extension tiene y a si abre el dialogo o abre una ventana nueva
        this.dialogViewer = true;
      } else {
        window.open(this.rutaArchivo, "_blank");
      }
    },
    onChange() {
      this.filelist = [...this.$refs.file.files];
    },
    remove(i) {
      this.filelist.splice(i, 1);
      this.filelist.clear();
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      this.BuscarArchivo(event.dataTransfer.files);

      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    BuscarArchivo(file) {
      console.log(file[0]);
      //this.file = event.target.files[0];
      var formData = new FormData();
      formData.append("archivo", file[0]);
      formData.append("accion", 8);
      formData.append("idTrabajador", this.idTrabajador);
      formData.append("idCliente", sessionStorage.getItem("idCliente"));
      formData.append("nombreArchivo", file[0].name);

      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorTrabajadores.php",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(
          function (response) {
            console.log(response.data);
            this.ConsultarArchivos();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    EliminarArchivo() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 7);
      parametros.append("idDocumento", this.idDocumento);

      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorTrabajadores.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.ConsultarArchivos();
            this.confirmDeleteFile = false;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    EliminarArchivosss(id) {
      db.collection("CompanyInfo" + sessionStorage.getItem("idCliente"))
        .doc(id)
        .update({
          status: 0,
        })
        .then(
          function () {
            console.log("delete susseful");
            this.directorio = [];
            this.ConsultarArchivos();
            this.confirmDeleteFile = false;
          }.bind(this)
        )
        .catch((error) => {
          console.log(error);
        }); //promesa para el error

      this.cargaArchivos = true;
    },
    downloadItem(item) {
      window.open(item.ruta, "download");
    },
    onButtonClick() {
      this.$refs.uploader.click();
    },
    BuscarImagen(event) {
      this.file = event.target.files[0];
      this.SubirImagen();
    },
    async SubirImagen() {
      try {
        const refImagen = storage
          .ref()
          .child("CompanyInfo" + sessionStorage.getItem("idCliente"))
          .child(this.file.name);

        const res = await refImagen.put(this.file);

        const urlDescarga = await refImagen.getDownloadURL();

        this.EnviarURL(urlDescarga, this.file.name);
      } catch (error) {
        console.log(error);
      }
    },
    EnviarURL(urlDescarga, fileName) {
      db.collection("CompanyInfo" + sessionStorage.getItem("idCliente"))
        .add({
          name: fileName,
          url: urlDescarga,
          time: Date.now(),
          status: 1,
        })
        .then(
          function () {
            console.log("url susseful");
            this.ConsultarArchivos();
          }.bind(this)
        )
        .catch((error) => {
          console.log(error);
        }); //promesa para el error

      this.cargaArchivos = true;
      this.dialog = false;
    },
    ConsultarArchivoss() {
      moment.locales("es");

      let ref = db
        .collection("CompanyInfo" + sessionStorage.getItem("idCliente"))
        .where("status", "==", 1); // con el order by ordena los datos de acuerdo al campo especificado.

      ref.onSnapshot((querySnapshot) => {
        //this.archivos = querySnapshot;
        this.dialog = false;
        this.archivos = [];
        querySnapshot.forEach((element) => {
          var extension = /[.]/.exec(element.name)
            ? /[^.]+$/.exec(element.name)[0]
            : undefined;
          //forEach que recorre toda la coleccion de mensajes
          this.archivos.push({
            name: element.data().name,
            extension: extension,
            size: element.data().size,
            time: moment(element.data().time).format("lll"),
            id: element.id,
          });
        });
      });
    },
    Detalles(value) {
      this.editedItem.idCliente = value.idCliente;

      this.ConsultarServiciosXCliente();
      this.dialogoDetalle = true;
      this.editedItem = value;
      this.dialogoDetalle = true;
    },
    Accion() {
      if (this.editedIndex === -1) {
        this.ADDCliente();
      } else {
        this.EditarCliente();
      }
    },
    EliminarCliente() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 39);
      parametros.append("idCliente", this.editedItem.idCliente);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(function (response) {}.bind(this))
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ADDCliente() {
      axios(sessionStorage.getItem("ruta") + "/core/ControladorClientes.php", {
        params: {
          format: "json",
          accion: 41,
          idCliente: this.editedItem.idCliente,
          nombreEmpresa: this.editedItem.nombre,
          nombreContacto: this.editedItem.nombreContacto,
          telefonoContacto: this.editedItem.telefonoContacto,
          telefono2: this.editedItem.telefono2,
          direccion: this.editedItem.direccion,
          state: this.editedItem.state,
          city: this.editedItem.city,
          EIN: this.editedItem.EIN,
          idBusiness: this.editedItem.idBusinessType,
          idioma: this.editedItem.idioma,
          correo: this.editedItem.correo,
          servicios: this.selectedServices,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      })
        .then(
          function (response) {
            this.ConsultarClientes();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },

    EditarCliente() {
      axios(sessionStorage.getItem("ruta") + "/core/ControladorClientes.php", {
        params: {
          format: "json",
          accion: 42,
          idCliente: this.editedItem.idCliente,
          nombreEmpresa: this.editedItem.nombre,
          nombreContacto: this.editedItem.nombreContacto,
          telefonoContacto: this.editedItem.telefonoContacto,
          telefono2: this.editedItem.telefono2,
          telefono3: this.editedItem.telefono3,
          direccion: this.editedItem.direccion,
          Country: this.editedItem.Country,
          state: this.editedItem.state,
          city: this.editedItem.city,
          EIN: this.editedItem.EIN,
          idBusiness: this.editedItem.idBusinessType,
          idioma: this.editedItem.idioma,
          correo: this.editedItem.correo,
          servicios: this.selectedServices,
          idUsuario: this.editedItem.idUsuario,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      })
        .then(function (response) {}.bind(this))
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarServiciosXCliente() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 38);
      parametros.append("idCliente", sessionStorage.getItem("idCliente"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.selectedServices = [];
            response.data.forEach((element) => {
              this.selectedServices.push(element.idServicio);
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllFruit) {
          this.selectedServices = [];
        } else {
          this.selectedServices = this.servicios.slice();
        }
      });
    },
    ConsultarBusiness() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 40);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.business = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarServicios() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 37);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.servicios = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    initialize() {
      this.ConsultarClientes();
      this.ConsultarServiciosXCliente();
      this.ConsultarArchivos();
    },
    ConsultarClientes() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 43);
      parametros.append("idCliente", sessionStorage.getItem("idCliente"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            // console.log(this.clientes);
            this.clientes = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    InsertarCliente() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 1);
      parametros.append("cliente", JSON.stringify(this.editedItem));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.clientes = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
  },
};
</script>

<style>
</style>