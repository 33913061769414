<template>
  <h1>{{texto}}</h1>
</template>

<script>
export default {
    props:{
        texto: String
    }

}
</script>

<style>
h1{
  color:#003366
}
</style>