<template>
  <v-layout>
    <v-row class="no-gutters elevation-5">
      <v-col cols="auto" class="flex-grow-1 flex-shrink-0">
        <v-responsive
          v-if="activeChat"
          class="overflow-y-hidden fill-height"
          :height="isMobile ? '600' : '500'"
          :width="isMobile ? '100%' : '100%'"
        >
          <v-card color="#e6f2f4" flat class="d-flex flex-column fill-height">
            <v-card-text
              class="flex-grow-1 overflow-y-auto messages"
              v-chat-scroll="{ enabled: enabled }"
            >
              <template>
                <div
                  class="text-xs-right"
                  v-for="(item, index) in messages"
                  :key="index"
                  :class="
                    item.name == nombreEncargado
                      ? 'd-flex flex-row-reverse'
                      : 'd-flex '
                  "
                >
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-hover v-slot:default="{ hover }">
                        <v-sheet
                          v-if="item.id === mensaje"
                          v-model="sheet"
                          elevation="24"
                          color="#000000"
                          dark
                          @load="enabled = false"
                          @click="downloadItem(item.message)"
                          :class="
                            item.name == nombreEncargado
                              ? 'v-chip-chat-negro pa-4 mt-2'
                              : 'v-chip-chat-right-negro pa-4 mt-2'
                          "
                          v-on="on"
                        >
                          {{ item.message }}
                          <v-icon v-if="item.url" left small>
                            mdi-download
                          </v-icon>

                          <v-btn
                            x-small
                            class="btnFoco"
                            text
                            ref="email"
                            id="email"
                          >
                            <v-icon>mdi-magnify</v-icon>
                          </v-btn>

                          <br />

                          <sub
                            v-if="hover"
                            class="ml-2"
                            style="font-size: 0.5rem"
                            >{{ item.time + " by " + item.name }}</sub
                          >
                        </v-sheet>

                        <v-sheet
                          v-else
                          elevation="24"
                          :color="
                            item.name == nombreEncargado ? '#0d47a1' : '#59677c'
                          "
                          dark
                          @click="downloadItem(item.message)"
                          :class="
                            item.name == nombreEncargado
                              ? 'v-chip-chat pa-4 mt-2'
                              : 'v-chip-chat-right pa-4 mt-2'
                          "
                          v-on="on"
                        >
                          <sub
                            v-if="hover && item.name == nombreEncargado"
                            class="ml-2"
                            style="font-size: 0.5rem"
                            @click="
                              (dialogDeleteMessage = true),
                                (messageToDelete = item)
                            "
                          >
                            <v-icon>mdi-close</v-icon>
                          </sub>
                          <br />
                          {{ item.message }}
                          <v-icon v-if="item.url" left small>
                            mdi-download
                          </v-icon>

                          <br />
                          <sub
                            v-if="hover"
                            class="ml-2"
                            style="font-size: 0.5rem"
                            >{{ item.time + " by " + item.name }}</sub
                          >

                          <v-icon
                            v-if="item.check == true && item.author == 'me'"
                            color="white"
                            small
                            >mdi-check-all</v-icon
                          >
                        </v-sheet>
                      </v-hover>
                    </template>
                  </v-menu>
                </div>
              </template>
            </v-card-text>

            <v-card-text class="flex-shrink-1">
              <v-form @submit.prevent="enviarMensaje" v-model="valido">
                <v-card
                  color="#FFFFFF"
                  flat
                  class="d-flex flex-column fill-height"
                >
                  <v-textarea
                    id="CajaMensaje"
                    v-model="messageForm.content"
                    label=""
                    placeholder="Enter your message"
                    type="text"
                    no-details
                    clearable
                    required
                    outlined
                    rows="1"
                    color="#000000"
                    append-outer-icon="mdi-send"
                    @keyup.enter="EnviarMensaje"
                    :rules="reglas"
                    @click:append-outer="EnviarMensaje"
                    hide-details
                  />
                </v-card>
              </v-form>
            </v-card-text>

            <v-dialog v-model="dialogDeleteMessage" max-width="550px">
              <v-card>
                <v-card-title class="text-h5"
                  >Are you sure you want to delete this message?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogDeleteMessage = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="
                      EliminarMensajeGrupo(messageToDelete),
                        (dialogDeleteMessage = false)
                    "
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
        </v-responsive>
      </v-col>
    </v-row>
  </v-layout>
</template>

<style>
.a {
  color: aliceblue;
}
.v-chip-chat-right {
  margin-left: 20px;
  width: 35%;
  height: 5%;
  background: red;
  position: relative;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 20px;
}
.v-chip-chat-right:before {
  content: "";
  position: absolute;
  right: 99%;
  top: 55%;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-right: 25px solid #59677c;
  border-bottom: 5px solid transparent;
}

.v-chip-chat-right-negro {
  margin-left: 20px;
  width: 35%;
  height: 5%;
  background: rgb(0, 0, 0);
  position: relative;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 20px;
}
.v-chip-chat-right-negro:before {
  content: "";
  position: absolute;
  right: 99%;
  top: 55%;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-right: 25px solid #000000;
  border-bottom: 5px solid transparent;
}

.v-chip-chat {
  margin-right: 20px;
  width: auto;
  height: auto;
  background: red;
  position: relative;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 20px;
}
.v-chip-chat:before {
  content: "";
  transform: scaleX(-1);
  position: absolute;
  left: 99%;
  top: 55%;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-right: 25px solid #0d47a1;
  border-bottom: 5px solid transparent;
}

.v-chip-chat-negro {
  margin-right: 20px;
  width: auto;
  height: auto;
  background: rgb(0, 0, 0);
  position: relative;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 20px;
}
.v-chip-chat-negro:before {
  content: "";
  transform: scaleX(-1);
  position: absolute;
  left: 99%;
  top: 55%;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-right: 25px solid #000000;
  border-bottom: 5px solid transparent;
}
</style>

<script>
import Vue from "vue";
import * as easings from "vuetify/es5/services/goto/easing-patterns";
import { db, storage } from "../firebase";
import moment from "moment";
import VueChatScroll from "vue-chat-scroll";
import EventBus from "../bus";
Vue.use(VueChatScroll);

var time = new Date();

export default {
  props: {
    datos: String,
  },
  data: () => ({
    isMobile: false,
    componente: true,
    messageToDelete: [],
    dialogDeleteMessage: false,
    sheet: "", //Este es el nombre con el identificador para ligar el elementById POR ID
    mensaje: "", // variable que se usa para almacenar la frase completa a buscar en el chat
    enabled: true, // enbled establece el tru o false del scroll dependiendo de si se quiere usar o no
    valido: false,
    nombreEncargado: "",
    messages: [],
    activeChat: 1,
    firebase: null,
    messageForm: {
      content: "",
      download: false,
      created_at: "11:11am",
    },
    reglas: [(v) => !!v || "Message is required"],
  }),
  watch: {},
  mounted() {
    this.ValidarDispositivo();
    this.CerrarConexionFirebase();
    sessionStorage.getItem("urlActual", window.location);
    console.log(window.location);
    this.nombreEncargado = sessionStorage.getItem("fullname");
    this.ConsultarMensajes();
    EventBus.$emit("hint-taskcenter");
    EventBus.$emit("add-comment", "Messenger");

    EventBus.$on("mostrarConversacion", (value) => {
      //Con este event bus se recibe el mensaje completo desde ChatNotificacion
      this.mensaje = value; // Se pasa el valo a la varibale para poderla usar de manera global dentro de esta clase

      const input = document.getElementById("email"); //Se establece el conexion con la variable input y el boton que resalta la palabra buscada

      input.focus(); //Encargado de enfocar el componente boton dentro del componente chat para que haga scrool automatico y a si lo pueda ver el usuario
      //this.sheet.focus();// lo mismo que arriba pero mas barato

      //Notas del proceso
      //*La funcion presenta problemas ya que tienes que dar doble click para poder redireccionar hacia la frase a buscar
      //*El cuandro del chat al ser resltado se usa un boton por que en apariencia no es funcional ya que si se encuentra con un chat mas grande este de desborda
      //*Buscar un input conpatible con los textos grandes ya que el focus solo funciona con inputs
      //11/10/2021 Omar Campos
    });
  },
  methods: {
    ValidarDispositivo() {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        sessionStorage.setItem("isMobile", true);
        this.isMobile = true;
      } else {
        sessionStorage.setItem("isMobile", false);
        this.isMobile = false;
      }
    },
    CerrarConexionFirebase() {
      EventBus.$on("CloseConexionFirebaseChat", () => {
        this.componente = false;
      });
    },
    EliminarMensajeGrupo(item) {
      let ref = db
        .collection(this.datos)
        .doc(item.id)
        .delete()
        .then(function () {
          console.log("Document successfully deleted!");
        })
        .catch(function (error) {
          console.error("Error al aceptar notificación: ", error);
        });
    },
    downloadItem(name) {
      storage
        .ref()
        .child("Archivos" + this.datos)
        .child(name)
        .getDownloadURL()
        .then(function (url) {
          // `url` is the download URL for 'images/stars.jpg'
          axios({
            method: "get",
            url: url,
            responseType: "blob",
            "Access-Control-Allow-Origin": "*",
          })
            .then(
              function (response) {
                const data = window.URL.createObjectURL(response.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = name;
                link.click();
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        })
        .catch(function (error) {
          console.log(error);
          // Handle any errors
        });
    },
    send: function () {
      this.chat.push({
        from: "user",
        item: this.item,
      });
      this.item = null;
      this.addReply();
    },
    addReply() {
      this.chat.push({
        from: "sushant",
        item: "Hmm",
      });
    },
    InicializarChat() {
      this.firebase
        .database()
        .ref(this.datos)
        .on(
          "value",
          function (snapshot) {
            this.messages = [];
            snapshot.forEach(
              function (e) {
                var element = e.val();

                this.messages.push(element);
              }.bind(this)
            );
          }.bind(this)
        );
    },
    EnviarMensaje() {
      if (this.valido) {
        var nombre = sessionStorage.getItem("fullname");
        var mensaje = this.messageForm.content;

        this.messageForm.content = "";
        db.collection(this.datos)
          .add({
            //Nodo padre del que queremos sacar los nodos hijos
            name: nombre,
            message: mensaje,
            me: true,
            url: "",
            download: false,
            time: Date.now(),
            check: false,
            notificacion: true,
            idEmpleado: sessionStorage.getItem("uid"),
            type: "text",
            status: 1,
            nodo: this.datos,
          })
          .catch((error) => {
            console.log(error);
          }); //promesa para el error

        var temp = this.datos.split("-");
        temp.forEach((element) => {
          if (element != sessionStorage.getItem("uid") && element != "chat") {
            this.Enviarnoty(element);
          }
        });
      }
    },
    ConsultarMensajes() {
      moment.locales("es");

      let ref = db
        .collection(this.datos)
        // .where("status", "==", 1)
        .orderBy("time"); // con el order by ordena los datos de acuerdo al campo especificado.

      ref.onSnapshot((querySnapshot) => {
        this.messages = [];

        let nombre;
        var idEmpleado = "";

        querySnapshot.forEach((element) => {
          //forEach que recorre toda la coleccion de mensajes
          idEmpleado = element.data().idEmpleado;

          let nombre = "";

          if (element.data().name == sessionStorage.getItem("fullname")) {
            nombre = "me";
          } else {
            nombre = element.data().name;

            if (element.data().check === false && this.componente) {
              this.ChecarMensajeGrupo(element.id);
            }
          }

          this.messages.push({
            id: element.id,
            name: element.data().name,
            message: element.data().message,
            me: element.data().me,
            url: element.data().url,
            check: element.data().check,
            author: nombre,
            download: element.data().download,
            time: moment(element.data().time).format("lll"),
            status: element.data().status,
          });

          if (sessionStorage.getItem("fullname") != element.data().name) {
            nombre = element.data().name;
            this.RegresarNombre(element.data().name); // esta funciona regresa el nombre de la persona del chat
          }
        });

        if (this.componente) {
          this.LimpiarNotificacionChat(idEmpleado);
        } else {
        }
      });
    },
    ChecarMensajeGrupo(idNodo) {
      db.collection(this.datos).doc(idNodo).update({
        check: true,
      });
    },
    LimpiarNotificacionChat(idEmpleado) {
      var nombre = sessionStorage.getItem("uid");
      let ref = db
        .collection("noty" + nombre)
        .doc("notificacionPersonal" + idEmpleado)
        .update({
          estatus: false,
        }); // Con este metodo actualizamos la notificacion a false.
    },
    RegresarNombre(nombre) {
      var temp = this.datos.split("-");
      this.$emit("showName", nombre, temp[1], this.messages);
    },
    Enviarnoty(idDestinatario) {
      var nombre = sessionStorage.getItem("uid");
      db.collection("noty" + idDestinatario)
        .doc("notificacionPersonal" + nombre)
        .set({
          //Nodo padre del que queremos sacar los nodos hijos
          name: sessionStorage.getItem("fullname"),
          type: "2",
          message: "New message:  " + sessionStorage.getItem("nombre"),
          time: Date.now(),
          idEmpleado: sessionStorage.getItem("uid"),
          estatus: true,
          notificado: false,
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error
      this.ConsultarMensajes();
    },
  },
};
</script>

<style>
.v-chip-chat {
  max-height: 3000px;
  max-width: 330px;
  border-radius: 20px;
}

.btnFoco {
  width: 0px;
  height: 0px;
  padding: 0px;
}
</style>
