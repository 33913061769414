<script>
var date = new Date();
import { db, storage } from "../firebase";

import DxButton from "devextreme-vue/button";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxPaging,
  DxLookup,
  DxRequiredRule,
  DxEmailRule,
  DxPatternRule,
  DxAsyncRule,
} from "devextreme-vue/data-grid";
import { locale, loadMessages, formatMessage } from "devextreme/localization";

import { employees, states } from "./data.js";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxButton,
    DxLookup,
    DxRequiredRule,
  },
  data() {
    return {
      saleAmountFormat: {
        style: "currency",
        currency: "EUR",
        useGrouping: true,
        minimumSignificantDigits: 3,
      },
      cellComision: true,
      cellMarketing: true,
      cellAdvertising: true,
      cellOther: true,
      insertVenta: true,
      insertOther: true,
      insertAdvertising: true,
      insertMarketing: true,
      insertComision: true,

      headerFile: [
        {
          text: "Name file",
          align: "start",
          sortable: false,
          value: "nombreDocumento",
        },
        { text: "Accions", value: "actions", sortable: false },
      ],
      archivos: [],
      dialogoArchivos: false,
      tipoComision: "",
      others: [],
      marketing: [],
      advertensing: [],
      comisiones: [],
      primerQ: [],
      segundoQ: [],
      tercerQ: [],
      cuartoQ: [],
      checkTotalTax: true,
      checkTotalTaxable: true,
      checkGrossSales: true,
      PeriodoActual: "",
      checkNontaxable: false,
      checkGrubhub: false,
      checkDoorDash: false,
      checkPos1: false,
      checkUber: false,
      checkPos2: false,
      checkStrip: false,
      checkOther: false,
      checkComision: true,
      loadPanelPosition: { of: "#gridContainer" },
      dataGrid: false,
      selectEmpresa: [],
      empresas: [],
      events: [],
      dataSource: [],
      states: states,
      dialogForm: false,
      itemVenta: [],
      dialogForm: false,
      marketingAdvertisign: "",
      checkMarketing: false,
      item: [],
      editedIndex: -1,
      confirmDelete: false,
      idVenta: "",
      añoSales: "",
      añoSalesNonTaxes: "",
      añoComision: "",
      añoTax: "",
      añoGross: "",
      impuestoPagar: "",
      fechaInicio: "",
      fechaFin: "",
      comision: "",
      nota: "",
      periodoVenta: "",
      ventasTrimestrales: false,
      ventasMenusales: false,
      historioVentas: false,
      btnArrow: false,
      checkComision: false,
      periodos: [
        { periodo: "Day" },
        { periodo: "Week" },
        { periodo: "Month" },
        { periodo: "Quatery" },
      ],
      modal: false,
      modalInicio: false,
      modalFin: false,
      date: null,
      inicioTrimestre: null,
      finTrimestre: null,
      menu: false,
      menu2: false,
      daate: "",
      confirmacion: false,
      ventasTrimestre1: [],
      ventasTrimestre2: [],
      ventasTrimestre3: [],
      ventasTrimestre4: [],
      dialogCalendar: false,
      dates: [],
      impuesto: 0,
      taxes: 0,
      notTaxes: 0,
      rage: [],
      selectRage: [],
      total: "",
      empresas: [],
      selectEmpresas: [],
      headers: [
        {
          text: "Month",
          align: "start",
          sortable: false,
          value: "periodo",
          align: "center",
        },
        {
          text: "Total sales",
          align: "center",
          sortable: false,
          value: "taxes",
          class: "ml-16",
        },
        { text: "Non taxable sales", value: "notTaxes", align: "center" },
        { text: "Taxable sales", value: "impuestoPagar", align: "center" },
        { text: "Sales tax", value: "total", align: "center" },
      ],
      selectPeriodos: [],
      selectImpuesto: [],
    };
  },
  computed: {
    //...mapGetters(['orders', 'isLoading']),
    editRowKey: {
      get() {
        return this.$store.state.editRowKey;
      },
      set(value) {
        this.setEditRowKey(value);
      },
    },
    changes: {
      get() {
        return this.$store.state.changes;
      },
      set(value) {
        this.setChanges(value);
      },
    },
    changesText: {
      get() {
        return JSON.stringify(
          this.changes.map((change) => ({
            type: change.type,
            key: change.type !== "insert" ? change.key : undefined,
            data: change.data,
          })),
          null,
          " "
        );
      },
    },
  },
  mounted() {
    this.selectEmpresas = sessionStorage.getItem("idCliente");
    //this.ConsultarEmpresas();
    this.ModificarConfiguracionTablaVentas();
    this.ConsultarVentasMensuales1();
    this.ConsultarTotalPrimerQ();
    this.ConsultarVentasMensuales2();
    this.ConsultarTotalSegundoQ();
    this.ConsultarVentasMensuales3();
    this.ConsultarTotalTercerQ();
    this.ConsultarVentasMensuales4();
    this.ConsultarTotalCuartoQ();
    //this.ConsultarVentasTrimestrales();
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  methods: {
    //...mapActions(['setEditRowKey', 'setChanges', 'loadOrders', 'insert', 'update', 'remove', 'saveChange']),
    onSaving(e) {
      //e.cancel = true;
      // e.promise = this.saveChange(e.changes[0]);
      //console.log(e.changes[0]);
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    saves(daate) {
      this.$refs.menu1.save(daate);
    },
    ConsultarEmpresas() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 1);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            // console.log(response.data);
            this.empresas = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    CalcularTotalVenta() {
      if (this.impuesto >= 1) {
        var impuesto = (this.impuesto * 1 * this.taxes) / 100;
        this.impuestoPagar = impuesto;
        this.total = impuesto + this.taxes * 1 + this.notTaxes * 1;
      }
    },
    ConsultarVentasTrimestrales() {
      this.ventasTrimestrales = true;
      this.btnArrow = false;
      let parametros = new URLSearchParams();
      parametros.append("accion", 3);
      //parametros.append("idCliente", this.selectEmpresas);
      parametros.append("idCliente", 1);
      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            // console.log(response.data);
            //this.ventas = response.data;
            this.ventas = [];
            response.data.forEach((elemento) => {
              this.ventas.push({
                periodo: elemento[0].periodo,
                taxes: elemento[0].taxes,
                notTaxes: elemento[0].notTaxes,
                impuesto: elemento[0].impuesto,
                total: elemento[0].total,
                comision: elemento[0].comision,
                impuestoPagar: elemento[0].impuestoPagar,
                marketing: elemento[0].marketing,
              });
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarVentasMensuales1() {
      this.btnArrow = true;
      let parametros = new URLSearchParams();

      parametros.append("accion", 4);

      parametros.append("idCliente", this.selectEmpresas);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.ventasTrimestre1 = [];
            response.data.forEach((elemento) => {
              this.ventasTrimestre1.push({
                periodo: elemento[0].periodo,
                taxes: elemento[0].taxes,
                notTaxes: elemento[0].notTaxes,
                impuesto: elemento[0].impuesto,
                impuestoPagar: elemento[0].impuestoPagar,
                total: elemento[0].total,
                comision: elemento[0].comision,
                marketing: elemento[0].marketing,
              });
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarTotalPrimerQ() {
      this.btnArrow = true;
      let parametros = new URLSearchParams();

      parametros.append("accion", 23);

      parametros.append("idCliente", this.selectEmpresas);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            response.data.forEach((element) => {
              this.primerQ = element;
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarTotalSegundoQ() {
      this.btnArrow = true;
      let parametros = new URLSearchParams();

      parametros.append("accion", 24);

      parametros.append("idCliente", this.selectEmpresas);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            response.data.forEach((element) => {
              this.segundoQ = element;
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarTotalTercerQ() {
      this.btnArrow = true;
      let parametros = new URLSearchParams();

      parametros.append("accion", 25);

      parametros.append("idCliente", this.selectEmpresas);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            response.data.forEach((element) => {
              //console.log(element);
              this.terceroQ = element;
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarTotalCuartoQ() {
      this.btnArrow = true;
      let parametros = new URLSearchParams();

      parametros.append("accion", 26);

      parametros.append("idCliente", this.selectEmpresas);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            response.data.forEach((element) => {
              this.cuartoQ = element;
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarVentasMensuales2() {
      this.btnArrow = true;
      let parametros = new URLSearchParams();

      parametros.append("accion", 5);

      parametros.append("idCliente", this.selectEmpresas);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.ventasTrimestre2 = [];
            response.data.forEach((elemento) => {
              this.ventasTrimestre2.push({
                periodo: elemento[0].periodo,
                taxes: elemento[0].taxes,
                notTaxes: elemento[0].notTaxes,
                impuesto: elemento[0].impuesto,
                impuestoPagar: elemento[0].impuestoPagar,
                total: elemento[0].total,
                comision: elemento[0].comision,
                marketing: elemento[0].marketing,
              });
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarVentasMensuales3() {
      this.btnArrow = true;
      let parametros = new URLSearchParams();

      parametros.append("accion", 6);

      parametros.append("idCliente", this.selectEmpresas);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.ventasTrimestre3 = [];
            response.data.forEach((elemento) => {
              this.ventasTrimestre3.push({
                periodo: elemento[0].periodo,
                taxes: elemento[0].taxes,
                notTaxes: elemento[0].notTaxes,
                impuesto: elemento[0].impuesto,
                impuestoPagar: elemento[0].impuestoPagar,
                total: elemento[0].total,
                comision: elemento[0].comision,
                marketing: elemento[0].marketing,
              });
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarVentasMensuales4() {
      this.btnArrow = true;
      let parametros = new URLSearchParams();

      parametros.append("accion", 7);

      parametros.append("idCliente", this.selectEmpresas);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.ventasTrimestre4 = [];
            response.data.forEach((elemento) => {
              this.ventasTrimestre4.push({
                periodo: elemento[0].periodo,
                taxes: elemento[0].taxes,
                notTaxes: elemento[0].notTaxes,
                impuesto: elemento[0].impuesto,
                impuestoPagar: elemento[0].impuestoPagar,
                total: elemento[0].total,
                comision: elemento[0].comision,
                marketing: elemento[0].marketing,
              });
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarVentasXMes(value) {
      this.btnArrow = true;
      let parametros = new URLSearchParams();
      parametros.append("accion", 8);
      parametros.append("idCliente", this.selectEmpresas);
      var periodo = "";

      if (value.periodo == "January") {
        periodo = date.getFullYear() + "-01-01";
        parametros.append("periodo", 1);
      } else if (value.periodo == "February") {
        periodo = date.getFullYear() + "-02-01";
        parametros.append("periodo", 2);
      } else if (value.periodo == "March") {
        periodo = date.getFullYear() + "-03-01";
        parametros.append("periodo", 3);
      } else if (value.periodo == "April") {
        periodo = date.getFullYear() + "-04-01";
        parametros.append("periodo", 4);
      } else if (value.periodo == "May") {
        periodo = date.getFullYear() + "-05-01";
        parametros.append("periodo", 5);
      } else if (value.periodo == "June") {
        periodo = date.getFullYear() + "-06-01";
        parametros.append("periodo", 6);
      } else if (value.periodo == "July") {
        periodo = date.getFullYear() + "-07-01";
        parametros.append("periodo", 7);
      } else if (value.periodo == "August") {
        periodo = date.getFullYear() + "-08-01";
        parametros.append("periodo", 8);
      } else if (value.periodo == "September") {
        periodo = date.getFullYear() + "-09-01";
        parametros.append("periodo", 9);
      } else if (value.periodo == "October") {
        periodo = date.getFullYear() + "-10-01";
        parametros.append("periodo", 10);
      } else if (value.periodo == "November") {
        periodo = date.getFullYear() + "-11-01";
        parametros.append("periodo", 11);
      } else if (value.periodo == "December") {
        periodo = date.getFullYear() + "-12-01";
        parametros.append("periodo", 12);
      }

      parametros.append("periodo", periodo);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.ConsultarConfiguracionTablas();

            if (response.data.length == 0) {
              this.insertVenta = true;
              this.dataSource = [];
            } else {
              this.insertVenta = false;

              this.dataSource = [];
              this.dataSource = response.data;
              var idVenta = "";
              this.dataSource.forEach((element) => {
                idVenta = element["idVenta"];
                this.idVenta = idVenta;
                this.ConsultarArchivosXTarea();
              });
              this.ConsultarComisionesXVenta(idVenta);
              this.ConsultarMarketingXVenta(idVenta);
              this.ConsultarAdvertensingXVenta(idVenta);
              this.ConsultarOthersXVenta(idVenta);
            }
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarVentasAnuales() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 10);
      parametros.append("idCliente", this.selectEmpresas);
      parametros.append("año", date.getFullYear());

      // console.log("Año: " + date.getFullYear());

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.añoSales = "";
            this.añoSalesNonTaxes = "";
            this.añoComision = "";
            this.añoTax = "";
            this.añoGross = "";

            // console.log(response.data);

            response.data.forEach((element) => {
              // console.log(element.taxes);

              this.añoSales = element.taxes;
              this.añoSalesNonTaxes = element.notTaxes;
              this.añoComision = element.comision;
              this.añoTax = element.impuestoPagar;
              this.añoGross = element.total;
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    EventoClickConsultaVentas(value) {
      this.PeriodoActual = value.periodo;
      this.itemVenta = value;
      this.dialogForm = true;

      this.ConsultarVentasXMes(value);
    },
    EventoClickReturn() {
      //este metodo retorna los valores previos de la tabla
      if (this.ventasMenusales) {
        this.ConsultarVentasTrimestrales();

        this.ventasTrimestrales = true;
        this.ventasMenusales = false;
        this.historioVentas = false;
      } else if (this.historioVentas) {
        this.ConsultarVentasMensuales(this.periodoVenta);
        this.ventasTrimestrales = false;
        this.ventasMenusales = true;
        this.historioVentas = false;
      }
    },
    LimpiarFormulario() {
      this.taxes = "";
      this.notTaxes = "";
      this.impuesto = "";
      this.total = "";
      this.nota = "";
      this.selectPeriodos = [];
    },
    EliminarVentas(item) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 11);
      parametros.append("idVenta", item.idVenta);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            // console.log(response.data);
            if (response.data == true) {
              this.insertVenta = true;
              alert("delete susseful");
              this.deleteItemConfirm(item);
            }
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    deleteItem(item) {
      this.editedIndex = this.ventas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.item = item;
      this.confirmDelete = true;
    },
    deleteItemConfirm() {
      this.ventas.splice(this.editedIndex, 1);
    },
    ModificarCampo(valor) {
      let parametros = new URLSearchParams();

      parametros.append("accion", 17);
      parametros.append("valor", valor);
      parametros.append("idCliente", this.selectEmpresas);
      parametros.append("campo", campo);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            // console.log(response.data);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    logEvent(eventName) {
      console.log(eventName);
      //this.events.unshift(eventName);
    },
    clearEvents() {
      this.events = [];
      // console.log(this.events);
    },
    insertRowRemote: function (dataObj) {
      // console.log(this.dataSource);
      /*ds.store()
        .insert(dataObj)
        .then(() => ds.reload());*/
    },
    ObtenerNuevoRegistro() {
      //console.log(this.dataSource);
      this.dataSource.forEach((element) => {
        //console.log(element);
        if (this.isNumeric(element.idVenta) == false) {
          if (element["Non taxable sales"] === undefined) {
            element["Non taxable sales"] = 0;
          }

          if (element["Taxable sales"] === undefined) {
            element["Taxable sales"] = 0;
          }

          if (element["Door dash"] === undefined) {
            element["Door dash"] = 0;
          }

          if (element["Uber"] === undefined) {
            element["Uber"] = 0;
          }

          if (element["Stripe"] === undefined) {
            element["Stripe"] = 0;
          }

          if (element["Grubhub"] === undefined) {
            element["Grubhub"] = 0;
          }

          if (element["Pos1"] === undefined) {
            element["Pos1"] = 0;
          }

          if (element["Pos2"] === undefined) {
            element["Pos2"] = 0;
          }

          if (element["Non taxable sales"] === undefined) {
            element["Non taxable sales"] = 0;
          }

          if (element["Other"] === undefined) {
            element["Other"] = 0;
          }

          var rate = element["Rate"];
          var postTax = element["Taxable sales"];
          var nonTaxable = element["Non taxable sales"];

          var TtlTaxableSales =
            postTax * 1 +
            element["Door dash"] * 1 +
            element["Uber"] * 1 +
            element["Stripe"] * 1 +
            element["Grubhub"] * 1 +
            element["Pos1"] * 1 +
            element["Pos2"] * 1;

          //Operacion gross sales
          var grossSales = nonTaxable * 1 + TtlTaxableSales * 1;

          var ttlTax = (rate * 1 * (TtlTaxableSales * 1)) / 100;

          var periodo = "";

          if (this.PeriodoActual == "January") {
            periodo = date.getFullYear() + "-01-01";
          } else if (this.PeriodoActual == "February") {
            periodo = date.getFullYear() + "-02-01";
          } else if (this.PeriodoActual == "March") {
            periodo = date.getFullYear() + "-03-01";
          } else if (this.PeriodoActual == "April") {
            periodo = date.getFullYear() + "-04-01";
          } else if (this.PeriodoActual == "May") {
            periodo = date.getFullYear() + "-05-01";
          } else if (this.PeriodoActual == "June") {
            periodo = date.getFullYear() + "-06-01";
          } else if (this.PeriodoActual == "July") {
            periodo = date.getFullYear() + "-07-01";
          } else if (this.PeriodoActual == "August") {
            periodo = date.getFullYear() + "-08-01";
          } else if (this.PeriodoActual == "September") {
            periodo = date.getFullYear() + "-09-01";
          } else if (this.PeriodoActual == "October") {
            periodo = date.getFullYear() + "-10-01";
          } else if (this.PeriodoActual == "November") {
            periodo = date.getFullYear() + "-11-01";
          } else if (this.PeriodoActual == "December") {
            periodo = date.getFullYear() + "-12-01";
          }

          let parametros = new URLSearchParams();
          parametros.append("accion", 2);
          parametros.append("periodo", periodo);
          parametros.append("nontaxable", element["Non taxable sales"]);
          parametros.append("postax", element["Taxable sales"]);
          parametros.append("doordash", element["Door dash"]);
          parametros.append("uber", element["Uber"]);
          parametros.append("stripe", element["Stripe"]);
          parametros.append("grubhub", element["Grubhub"]);
          parametros.append("pos1", element["Pos1"]);
          parametros.append("pos2", element["Pos2"]);
          parametros.append("rate", element["Rate"]);
          parametros.append("other", element["Other"]);
          parametros.append("grosssales", grossSales);
          parametros.append("nonTaxable3", element["Non Taxable3"]);
          parametros.append("taxablesales", TtlTaxableSales);
          parametros.append("ttltax", ttlTax);
          parametros.append("idCliente", this.selectEmpresas);

          //console.log("resultados: "+ gross+" totaltax: "+totalTax+" total:"+total);

          axios({
            method: "post",
            url:
              sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
            data: parametros,
          })
            .then(
              function (response) {
                //console.log(response.data);
                this.AddCell();
                this.ConsultarVentasXMes(this.itemVenta);
                this.ConsultarComisionesXVenta(this.idVenta);
                this.ConsultarMarketingXVenta(this.idVenta);
                this.ConsultarAdvertensingXVenta(this.idVenta);
                this.ConsultarOthersXVenta(this.idVenta);
                //console.log(response.data);
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        }
      });
    },
    isNumeric(value) {
      return !isNaN(parseFloat(value)) && isFinite(value);
    },
    InsertarRegistro(item, total, totalTax, gross) {
      var periodo = "";

      if (this.PeriodoActual == "January") {
        periodo = date.getFullYear() + "-01-01";
      } else if (this.PeriodoActual == "February") {
        periodo = date.getFullYear() + "-02-01";
      } else if (this.PeriodoActual == "March") {
        periodo = date.getFullYear() + "-03-01";
      } else if (this.PeriodoActual == "April") {
        periodo = date.getFullYear() + "-04-01";
      } else if (this.PeriodoActual == "May") {
        periodo = date.getFullYear() + "-05-01";
      } else if (this.PeriodoActual == "June") {
        periodo = date.getFullYear() + "-06-01";
      } else if (this.PeriodoActual == "July") {
        periodo = date.getFullYear() + "-07-01";
      } else if (this.PeriodoActual == "August") {
        periodo = date.getFullYear() + "-08-01";
      } else if (this.PeriodoActual == "September") {
        periodo = date.getFullYear() + "-09-01";
      } else if (this.PeriodoActual == "October") {
        periodo = date.getFullYear() + "-10-01";
      } else if (this.PeriodoActual == "November") {
        periodo = date.getFullYear() + "-11-01";
      } else if (this.PeriodoActual == "December") {
        periodo = date.getFullYear() + "-12-01";
      }

      let parametros = new URLSearchParams();
      parametros.append("accion", 2);
      parametros.append("periodo", periodo);
      parametros.append("nontaxable", item["Non taxable sales"]);
      parametros.append("postax", item["Post tax"]);
      parametros.append("doordash", item["Door dash"]);
      parametros.append("uber", item["Uber"]);
      parametros.append("stripe", item["Stripe"]);
      parametros.append("grubhub", item["Grubhub"]);
      parametros.append("pos1", item["Pos1"]);
      parametros.append("pos2", item["Pos2"]);
      parametros.append("rate", item["Rate"]);
      parametros.append("grosssales", total);
      //parametros.append("nonTaxable3", item["Non Taxable3"]);
      parametros.append("taxablesales", gross);
      parametros.append("ttltax", totalTax);
      parametros.append("idCliente", item);
      parametros.append("other", item["other"]);
      parametros.append("Non taxabl3", item["Non taxabl3"]);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            //console.log(response.data);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    RemoveCell() {
      this.checkGrossSales = false;
      this.checkTotalTax = false;
      this.checkTotalTaxable = false;
    },
    AddCell() {
      this.checkGrossSales = true;
      this.checkTotalTax = true;
      this.checkTotalTaxable = true;
    },
    EliminarVenta(item) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 11);
      parametros.append("idVenta", item.data.idVenta);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            //console.log(response.data);
            this.insertVenta = true;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarComisionesXVenta(idVenta) {
      this.comisiones = [];
      console.log(idVenta);
      let parametros = new URLSearchParams();
      parametros.append("accion", 27);
      parametros.append("idVenta", idVenta);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);

            this.comisiones = [];
            if (response.data[0].Total == null) {
              console.log("1");
              this.insertComision = true;
              this.comisiones = [];
            } else {
              console.log("2");
              this.insertComision = false;
              this.comisiones = response.data;
            }
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarMarketingXVenta(idVenta) {
      console.log(idVenta);
      let parametros = new URLSearchParams();
      parametros.append("accion", 28);
      parametros.append("idVenta", idVenta);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            //console.log(response.data);
            this.marketing = [];
            this.marketing = [];
            this.marketing = [];
            if (response.data[0].Total == null) {
              this.insertMarketing = true;
              this.marketing = [];
            } else {
              this.insertMarketing = false;
              this.marketing = response.data;
            }
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarAdvertensingXVenta(idVenta) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 29);
      parametros.append("idVenta", idVenta);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.advertensing = [];
            if (response.data[0].Total == null) {
              this.insertAdvertising = true;
              this.advertensing = [];
            } else {
              this.insertAdvertising = false;
              this.advertensing = response.data;
            }
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarOthersXVenta(idVenta) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 30);
      parametros.append("idVenta", idVenta);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.others = [];
            if (response.data[0].Total == null) {
              this.insertOther = true;
              this.others = [];
            } else {
              this.insertOther = false;
              this.others = response.data;
            }
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ObtenerComision(tipo, id) {
      //console.log(this.dataSource);
      if (tipo == "comisiones") {
        this.comisiones.forEach((element) => {
          if (this.isNumeric(element[id]) == false) {
            if (element["Door dash"] === undefined) {
              element["Door dash"] = 0;
            }

            if (element["Uber"] === undefined) {
              element["Uber"] = 0;
            }

            if (element["Stripe"] === undefined) {
              element["Stripe"] = 0;
            }

            if (element["Grubhub"] === undefined) {
              element["Grubhub"] = 0;
            }

            var idVenta = "";
            this.dataSource.forEach((element) => {
              idVenta = element["idVenta"];
            });

            let parametros = new URLSearchParams();
            parametros.append("accion", 31);
            parametros.append("doordash", element["Door dash"]);
            parametros.append("uber", element["Uber"]);
            parametros.append("stripe", element["Stripe"]);
            parametros.append("grubhub", element["Grubhub"]);
            parametros.append("tipo", tipo);
            parametros.append("idVenta", idVenta);

            axios({
              method: "post",
              url:
                sessionStorage.getItem("ruta") +
                "/core/ControladorClientes.php",
              data: parametros,
            })
              .then(
                function (response) {
                  //console.log(response.data);

                  if (response.data == true) {
                    alert("Save successful!!");
                  }

                  this.AddCell();
                  this.ConsultarComisionesXVenta(idVenta);
                  this.ConsultarMarketingXVenta(idVenta);
                  this.ConsultarAdvertensingXVenta(idVenta);
                  this.ConsultarOthersXVenta(idVenta);

                  //console.log(response.data);
                }.bind(this)
              )
              .catch(
                function (response) {
                  //handle error
                  console.log(response);
                }.bind(this)
              );
          }
        });
      } else if (tipo == "Marketing") {
        this.marketing.forEach((element) => {
          if (this.isNumeric(element[id]) == false) {
            if (element["Door dash"] === undefined) {
              element["Door dash"] = 0;
            }

            if (element["Uber"] === undefined) {
              element["Uber"] = 0;
            }

            if (element["Stripe"] === undefined) {
              element["Stripe"] = 0;
            }

            if (element["Grubhub"] === undefined) {
              element["Grubhub"] = 0;
            }

            var idVenta = "";
            this.dataSource.forEach((element) => {
              idVenta = element["idVenta"];
            });

            let parametros = new URLSearchParams();
            parametros.append("accion", 31);
            parametros.append("doordash", element["Door dash"]);
            parametros.append("uber", element["Uber"]);
            parametros.append("stripe", element["Stripe"]);
            parametros.append("grubhub", element["Grubhub"]);
            parametros.append("tipo", tipo);
            parametros.append("idVenta", idVenta);

            axios({
              method: "post",
              url:
                sessionStorage.getItem("ruta") +
                "/core/ControladorClientes.php",
              data: parametros,
            })
              .then(
                function (response) {
                  if (response.data == true) {
                    alert("Save successful!!");
                  }
                  this.AddCell();
                  this.ConsultarComisionesXVenta(idVenta);
                  this.ConsultarMarketingXVenta(idVenta);
                  this.ConsultarAdvertensingXVenta(idVenta);
                  this.ConsultarOthersXVenta(idVenta);

                  //console.log(response.data);
                }.bind(this)
              )
              .catch(
                function (response) {
                  //handle error
                  console.log(response);
                }.bind(this)
              );
          }
        });
      } else if (tipo == "advertising") {
        this.advertensing.forEach((element) => {
          if (this.isNumeric(element[id]) == false) {
            if (element["Door dash"] === undefined) {
              element["Door dash"] = 0;
            }

            if (element["Uber"] === undefined) {
              element["Uber"] = 0;
            }

            if (element["Stripe"] === undefined) {
              element["Stripe"] = 0;
            }

            if (element["Grubhub"] === undefined) {
              element["Grubhub"] = 0;
            }

            var idVenta = "";
            this.dataSource.forEach((element) => {
              idVenta = element["idVenta"];
            });

            let parametros = new URLSearchParams();
            parametros.append("accion", 31);
            parametros.append("doordash", element["Door dash"]);
            parametros.append("uber", element["Uber"]);
            parametros.append("stripe", element["Stripe"]);
            parametros.append("grubhub", element["Grubhub"]);
            parametros.append("tipo", tipo);
            parametros.append("idVenta", idVenta);

            axios({
              method: "post",
              url:
                sessionStorage.getItem("ruta") +
                "/core/ControladorClientes.php",
              data: parametros,
            })
              .then(
                function (response) {
                  if (response.data == true) {
                    alert("Save successful!!");
                  }
                  this.AddCell();
                  this.ConsultarComisionesXVenta(idVenta);
                  this.ConsultarMarketingXVenta(idVenta);
                  this.ConsultarAdvertensingXVenta(idVenta);
                  this.ConsultarOthersXVenta(idVenta);

                  //console.log(response.data);
                }.bind(this)
              )
              .catch(
                function (response) {
                  //handle error
                  console.log(response);
                }.bind(this)
              );
          }
        });
      } else if (tipo == "others") {
        this.others.forEach((element) => {
          if (this.isNumeric(element[id]) == false) {
            if (element["Door dash"] === undefined) {
              element["Door dash"] = 0;
            }

            if (element["Uber"] === undefined) {
              element["Uber"] = 0;
            }

            if (element["Stripe"] === undefined) {
              element["Stripe"] = 0;
            }

            if (element["Grubhub"] === undefined) {
              element["Grubhub"] = 0;
            }

            var idVenta = "";
            this.dataSource.forEach((element) => {
              idVenta = element["idVenta"];
            });

            let parametros = new URLSearchParams();
            parametros.append("accion", 31);
            parametros.append("doordash", element["Door dash"]);
            parametros.append("uber", element["Uber"]);
            parametros.append("stripe", element["Stripe"]);
            parametros.append("grubhub", element["Grubhub"]);
            parametros.append("tipo", tipo);
            parametros.append("idVenta", idVenta);

            axios({
              method: "post",
              url:
                sessionStorage.getItem("ruta") +
                "/core/ControladorClientes.php",
              data: parametros,
            })
              .then(
                function (response) {
                  if (response.data == true) {
                    alert("Save successful!!");
                  }
                  this.AddCell();
                  this.ConsultarComisionesXVenta(idVenta);
                  this.ConsultarMarketingXVenta(idVenta);
                  this.ConsultarAdvertensingXVenta(idVenta);
                  this.ConsultarOthersXVenta(idVenta);

                  //console.log(response.data);
                }.bind(this)
              )
              .catch(
                function (response) {
                  //handle error
                  console.log(response);
                }.bind(this)
              );
          }
        });
      }
    },
    ActualizarComision(tipo, id) {
      //console.log(this.dataSource);

      if (tipo == "comisiones") {
        this.comisiones.forEach((element) => {
          if (this.isNumeric(element[id]) == true) {
            var idVenta = "";

            idVenta = element["idVenta"];

            let parametros = new URLSearchParams();
            parametros.append("accion", 33);
            parametros.append("doordash", element["Door dash"]);
            parametros.append("uber", element["Uber"]);
            parametros.append("stripe", element["Stripe"]);
            parametros.append("grubhub", element["Grubhub"]);
            parametros.append("tipo", tipo);
            parametros.append("idVenta", element["idComision"]);

            axios({
              method: "post",
              url:
                sessionStorage.getItem("ruta") +
                "/core/ControladorClientes.php",
              data: parametros,
            })
              .then(
                function (response) {
                  this.AddCell();
                  this.ConsultarComisionesXVenta(idVenta);
                  this.ConsultarMarketingXVenta(idVenta);
                  this.ConsultarAdvertensingXVenta(idVenta);
                  this.ConsultarOthersXVenta(idVenta);

                  //console.log(response.data);
                }.bind(this)
              )
              .catch(
                function (response) {
                  //handle error
                  console.log(response);
                }.bind(this)
              );
          }
        });
      } else if (tipo == "Marketing") {
        this.marketing.forEach((element) => {
          if (this.isNumeric(element[id]) == true) {
            var idVenta = "";

            idVenta = element["idVenta"];

            let parametros = new URLSearchParams();
            parametros.append("accion", 33);
            parametros.append("doordash", element["Door dash"]);
            parametros.append("uber", element["Uber"]);
            parametros.append("stripe", element["Stripe"]);
            parametros.append("grubhub", element["Grubhub"]);
            parametros.append("tipo", tipo);
            parametros.append("idVenta", element["idMarketing"]);

            axios({
              method: "post",
              url:
                sessionStorage.getItem("ruta") +
                "/core/ControladorClientes.php",
              data: parametros,
            })
              .then(
                function (response) {
                  this.AddCell();
                  this.ConsultarComisionesXVenta(idVenta);
                  this.ConsultarMarketingXVenta(idVenta);
                  this.ConsultarAdvertensingXVenta(idVenta);
                  this.ConsultarOthersXVenta(idVenta);

                  //console.log(response.data);
                }.bind(this)
              )
              .catch(
                function (response) {
                  //handle error
                  console.log(response);
                }.bind(this)
              );
          }
        });
      } else if (tipo == "advertising") {
        this.advertensing.forEach((element) => {
          if (this.isNumeric(element[id]) == true) {
            var idVenta = "";

            idVenta = element["idVenta"];

            let parametros = new URLSearchParams();
            parametros.append("accion", 33);
            parametros.append("doordash", element["Door dash"]);
            parametros.append("uber", element["Uber"]);
            parametros.append("stripe", element["Stripe"]);
            parametros.append("grubhub", element["Grubhub"]);
            parametros.append("tipo", tipo);
            parametros.append("idVenta", element["idAdvertising"]);

            axios({
              method: "post",
              url:
                sessionStorage.getItem("ruta") +
                "/core/ControladorClientes.php",
              data: parametros,
            })
              .then(
                function (response) {
                  this.AddCell();
                  this.ConsultarComisionesXVenta(idVenta);
                  this.ConsultarMarketingXVenta(idVenta);
                  this.ConsultarAdvertensingXVenta(idVenta);
                  this.ConsultarOthersXVenta(idVenta);

                  //console.log(response.data);
                }.bind(this)
              )
              .catch(
                function (response) {
                  //handle error
                  console.log(response);
                }.bind(this)
              );
          }
        });
      } else if (tipo == "others") {
        this.others.forEach((element) => {
          if (this.isNumeric(element[id]) == true) {
            var idVenta = "";

            idVenta = element["idVenta"];

            let parametros = new URLSearchParams();
            parametros.append("accion", 33);
            parametros.append("doordash", element["Door dash"]);
            parametros.append("uber", element["Uber"]);
            parametros.append("stripe", element["Stripe"]);
            parametros.append("grubhub", element["Grubhub"]);
            parametros.append("tipo", tipo);
            parametros.append("idVenta", element["idOther"]);

            axios({
              method: "post",
              url:
                sessionStorage.getItem("ruta") +
                "/core/ControladorClientes.php",
              data: parametros,
            })
              .then(
                function (response) {
                  this.AddCell();
                  this.ConsultarComisionesXVenta(idVenta);
                  this.ConsultarMarketingXVenta(idVenta);
                  this.ConsultarAdvertensingXVenta(idVenta);
                  this.ConsultarOthersXVenta(idVenta);

                  //console.log(response.data);
                }.bind(this)
              )
              .catch(
                function (response) {
                  //handle error
                  console.log(response);
                }.bind(this)
              );
          }
        });
      }
    },
    EliminarComision(item) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 32);
      parametros.append("tipo", this.tipoComision);

      if (this.tipoComision == "comisiones") {
        parametros.append("idComision", item.data.idComision);
      } else if (this.tipoComision == "Marketing") {
        parametros.append("idComision", item.data.idMarketing);
      } else if (this.tipoComision == "advertising") {
        parametros.append("idComision", item.data.idAdvertising);
      } else if (this.tipoComision == "others") {
        parametros.append("idComision", item.data.idOther);
      }

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.AddCell();
            this.ConsultarComisionesXVenta(this.idVenta);
            this.ConsultarMarketingXVenta(this.idVenta);
            this.ConsultarAdvertensingXVenta(this.idVenta);
            this.ConsultarOthersXVenta(this.idVenta);

            //console.log(response.data);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    alerta() {
      alert("hola");
    },
    BuscarImagen(event) {
      this.file = event.target.files[0];
      this.SubirImagen();
    },
    async SubirImagen() {
      try {
        const refImagen = storage
          .ref()
          .child("SalesTax" + this.idVenta)
          .child(this.file.name);

        const res = await refImagen.put(this.file);

        const urlDescarga = await refImagen.getDownloadURL();

        this.EnviarURL(urlDescarga, this.file.name);
      } catch (error) {
        console.log(error);
      }
    },
    EnviarURL(urlDescarga, fileName) {
      let parametros = new URLSearchParams();
      this.usuariosChat = [];
      parametros.append("accion", 2);
      parametros.append("idVenta", this.idVenta);
      parametros.append("nombreDocumento", fileName);
      parametros.append("ruta", urlDescarga);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorVentas.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.ConsultarArchivosXTarea();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarArchivosXTarea() {
      let parametros = new URLSearchParams();
      this.usuariosChat = [];
      parametros.append("accion", 1);
      parametros.append("idVenta", this.idVenta);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorVentas.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.archivos = [];
            this.archivos = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    RemoveCellComision() {
      this.cellComision = false;
    },
    AddCellComision() {
      this.cellComision = true;
    },
    priceColumn_customizeText(cellInfo) {
      return "$" + cellInfo.value;
    },
    ModificarVenta(valor) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 17);

      parametros.append("nontaxable", valor.data["Non taxable sales"]);
      parametros.append("postax", valor.data["Taxable sales"]);
      parametros.append("doordash", valor.data["Door dash"]);
      parametros.append("uber", valor.data["Uber"]);
      parametros.append("stripe", valor.data["Stripe"]);
      parametros.append("grubhub", valor.data["Grubhub"]);
      parametros.append("pos1", valor.data["Pos1"]);
      parametros.append("pos2", valor.data["Pos2"]);
      parametros.append("rate", valor.data["Rate"]);
      parametros.append("other", valor.data["Other"]);
      parametros.append("grosssales", valor.data["Total sales"]);
      //parametros.append("nonTaxable3", item["Non Taxable3"]);
      parametros.append("taxablesales", valor.data["Total Taxable sales"]);
      parametros.append("ttltax", valor.data["Total sales tax"]);
      parametros.append("idVenta", valor.data["idVenta"]);

      //console.log("resultados: "+ gross+" totaltax: "+totalTax+" total:"+total);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.AddCell();
            this.ConsultarVentasXMes(this.itemVenta);
            this.ConsultarComisionesXVenta(this.idVenta);
            this.ConsultarMarketingXVenta(this.idVenta);
            this.ConsultarAdvertensingXVenta(this.idVenta);
            this.ConsultarOthersXVenta(this.idVenta);
            //console.log(response.data);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ModificarConfiguracionTablaVentas() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 34);
      parametros.append("idVenta", this.selectEmpresas);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            //console.log(response.data);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ModificarConfiguracionTablas() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 35);

      parametros.append("idVenta", this.selectEmpresas); //es idCliente

      if (this.checkNontaxable) {
        parametros.append("nontaxable", 1);
      } else {
        parametros.append("nontaxable", 0);
      }

      if (this.checkDoorDash) {
        parametros.append("doordash", 1);
      } else {
        parametros.append("doordash", 0);
      }

      if (this.checkUber) {
        parametros.append("uber", 1);
      } else {
        parametros.append("uber", 0);
      }

      if (this.checkStrip) {
        parametros.append("stripe", 1);
      } else {
        parametros.append("stripe", 0);
      }

      if (this.checkGrubhub) {
        parametros.append("grubhub", 1);
      } else {
        parametros.append("grubhub", 0);
      }

      if (this.checkPos1) {
        parametros.append("pos1", 1);
      } else {
        parametros.append("pos1", 0);
      }

      if (this.checkPos2) {
        parametros.append("pos2", 1);
      } else {
        parametros.append("pos2", 0);
      }

      if (this.checkOther) {
        parametros.append("other", 1);
      } else {
        parametros.append("other", 0);
      }

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            //console.log(response.data);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarConfiguracionTablas() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 36);

      parametros.append("idVenta", this.selectEmpresas); //es idCliente

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            //console.log(response.data);
            response.data.forEach((element) => {
              //console.log(element);

              if (element["nonTaxable"] == 1) {
                this.checkNontaxable = true;
              } else {
                this.checkNontaxable = false;
              }

              if (element["grubhub"] == 1) {
                this.checkGrubhub = true;
              } else {
                this.checkGrubhub = false;
              }

              if (element["doorDash"] == 1) {
                this.checkDoorDash = true;
              } else {
                this.checkDoorDash = false;
              }

              if (element["pos1"] == 1) {
                this.checkPos1 = true;
              } else {
                this.checkPos1 = false;
              }

              if (element["pos2"] == 1) {
                this.checkPos2 = true;
              } else {
                this.checkPos2 = false;
              }

              if (element["uber"] == 1) {
                this.checkUber = true;
              } else {
                this.checkUber = false;
              }

              if (element["strip"] == 1) {
                this.checkStrip = true;
              } else {
                this.checkStrip = false;
              }

              if (element["others"] == 1) {
                this.checkOther = true;
              } else {
                this.checkOther = false;
              }
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    LimpiarTablas() {
      this.comisiones = [];
      this.marketing = [];
      this.advertensing = [];
      this.others = [];

      this.insertComision = true;
      this.insertOther = true;
      this.insertAdvertising = true;
      this.insertMarketing = true;
    },
  },
};
</script>