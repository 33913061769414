<template>
  <v-dialog
    v-model="estatus"
    persistent
    @keydown.esc="AccionCerrar"
    :max-width="isMobile ? '100%' : '65%'"
  >
    <v-tabs show-arrows background-color="#0D47A1" icons-and-text dark grow>
      <v-tabs-slider color="#0D47A1 "></v-tabs-slider>
      <v-btn
        class="mt-4 ml-1"
        dark
        text
        @click="
          AccionCerrar(),
            (estatusChat = false),
            CerrarConexion(),
            (cerraConexion = true)
        "
      >
        <v-icon large>mdi-close</v-icon>
      </v-btn>
      <v-tab>
        <v-btn text x-large
          ><v-icon left> mdi-page-next</v-icon>Task detail:
          <h5>{{ datos.nombreTarea }}</h5></v-btn
        >
      </v-tab>
      <v-space> </v-space>

      <v-btn
        color="#EF6C00"
        class="mt-5 mr-10"
        @click="confirmacion = true"
        dark
        text
        v-if="btnAccionEdit == false && datos.idManager == uid"
      >
        <v-icon large color="white"> mdi-delete </v-icon>
      </v-btn>
      <v-tab-item>
        <v-card>
          <v-card-text>
            <v-form ref="loginForm" lazy-validation>
              <v-card-text>
                <v-row dense>
                  <v-col cols="6">
                    <v-btn
                      color="#EF6C00"
                      class="mb-5 ml-1"
                      @click="(estatusArchivo = true), (estatusArchivos = true)"
                      v-if="btnAccionEdit == false"
                      dark
                    >
                      <v-icon color="white"> mdi-file </v-icon> FILES
                    </v-btn>

                    <v-btn
                      color="#ff0000"
                      :class="
                        btnAccionEdit
                          ? 'mb-5 ml-1 '
                          : isMobile
                          ? 'ml-0 mb-5 blob'
                          : ' ml-5 mb-5 blob'
                      "
                      v-if="datos.prioridad == 1 ? true : false"
                      dark
                      @click="
                        datos.prioridad == 1 && btnAccionEdit == true
                          ? (datos.prioridad = 0)
                          : (datos.prioridad = 1)
                      "
                    >
                      <v-icon> mdi-alert </v-icon> Priority
                    </v-btn>

                    <v-btn
                      color="#9F9F9F"
                      :class="isMobile ? '' : 'mb-5 ml-2'"
                      v-else
                      :x-small="isMobile"
                      dark
                      @click="
                        datos.prioridad == 1
                          ? (datos.prioridad = 0)
                          : (datos.prioridad = 1)
                      "
                    >
                      <v-icon> mdi-alert </v-icon> Not Priority
                    </v-btn>

                    <v-btn
                      v-if="btnAccionEdit == false && datos.idManager == uid"
                      color="#EF6C00"
                      class="mb-5 ml-5"
                      @click="
                        btnAccionEdit1
                          ? (btnAccionEdit = false)
                          : (btnAccionEdit = true)
                      "
                      dark
                    >
                      <v-icon color="white"> mdi-pencil </v-icon>
                    </v-btn>
                  </v-col>

                  <v-col
                    class="text-lg-right"
                    v-if="validador && btnAccionEdit == false"
                    cols="6"
                    md="6"
                    sm="6"
                  >
                    <h2>CHANGE STATUS TASK</h2>

                    <v-icon
                      x-large
                      v-if="datos.estatus != 'In progress'"
                      color="orange"
                      class="mt-2 mr-2"
                      @click="TareaEnProceso(datos.idTarea, datos.fechaInicio)"
                    >
                      mdi-clock
                    </v-icon>

                    <v-icon
                      x-large
                      class="mt-2 mr-2"
                      color="green"
                      v-if="datos.estatus != 'Finished'"
                      @click="TerminarTarea(datos.idTarea)"
                    >
                      mdi-calendar-check
                    </v-icon>

                    <v-icon
                      x-large
                      color="red"
                      class="mt-2 mr-2"
                      v-if="datos.estatus != 'Canceled'"
                      @click="CancelarTarea(datos.idTarea)"
                    >
                      mdi-calendar-remove
                    </v-icon>
                  </v-col>

                  <v-col
                    :cols="btnAccionEdit ? 12 : isMobile ? '12' : '3'"
                    class="mt-0"
                  >
                    <v-select
                      :items="departamentos"
                      v-model="selectDepartamentos"
                      label="Departments"
                      outlined
                      v-if="btnAccionEdit"
                      @change="
                        MostrarEncargadosXDepartamentos(selectDepartamentos)
                      "
                      color="indigo"
                      item-value="idDepartamento"
                      item-text="nombreDepartamento"
                    ></v-select>

                    <v-select
                      v-if="btnAccionEdit"
                      v-model="selectEmpleados"
                      label="Participant"
                      outlined
                      :items="empleados"
                      item-value="idEmpleado"
                      item-text="nombreEmpleado"
                    ></v-select>

                    <v-text-field
                      v-else
                      label="Manager"
                      outlined
                      :readonly="!btnAccionEdit"
                      v-model="participanteChat"
                    ></v-text-field>

                    <v-text-field
                      readonly
                      v-if="!btnAccionEdit"
                      label="Create on"
                      outlined
                      v-model="datos.fechaCreacion"
                    ></v-text-field>

                    <v-text-field
                      label="Start date"
                      outlined
                      readonly
                      v-model="datos.fechaInicio"
                    ></v-text-field>

                    <v-text-field
                      label="Finished on"
                      outlined
                      readonly
                      required
                      v-model="datos.fechaFin"
                    ></v-text-field>

                    <v-text-field
                      v-if="btnAccionEdit"
                      label="Title"
                      placeholder="Enter a title for this task"
                      outlined
                      v-model="datos.nombreTarea"
                    ></v-text-field>

                    <v-textarea
                      outlined
                      name="input-7-4"
                      label="description"
                      rows="2"
                      v-model="datos.descripcion"
                      :readonly="!btnAccionEdit"
                    ></v-textarea>

                    <v-text-field
                      v-if="false"
                      v-model="dates"
                      label="New date range"
                      prepend-icon="mdi-calendar"
                      readonly
                    ></v-text-field>
                    <v-btn
                      v-if="btnAccionEdit"
                      width="100%"
                      x-large
                      color="#EF6C00"
                      @click="dialogCalendar = true"
                      dark
                    >
                      <v-icon left>mdi-calendar</v-icon>
                      Set new date
                    </v-btn>

                    <v-row>
                      <v-col
                        v-if="btnAccionEdit"
                        class="col text-center"
                        cols="12"
                        sm="6"
                      >
                        <v-btn
                          width="100%"
                          x-large
                          color="#EF6C00"
                          @click="(btnAccionEdit = false), RegresarValores()"
                          dark
                        >
                          <v-icon left>mdi-cancel</v-icon>
                          Cancel
                        </v-btn>
                      </v-col>

                      <v-col class="col text-center" cols="12" sm="6">
                        <v-btn
                          x-large
                          v-if="btnAccionEdit"
                          width="100%"
                          :color="
                            oldValues.encargado != selectEmpleados ||
                            oldValues.fechaInicio != datos.fechaInicio ||
                            oldValues.fechaFin != datos.fechaFin ||
                            oldValues.titulo != datos.nombreTarea ||
                            oldValues.descripcion != datos.descripcion ||
                            oldValues.prioridad != datos.prioridad
                              ? 'green'
                              : '#000000'
                          "
                          dark
                          @click="ModificarTarea()"
                        >
                          <v-icon left>mdi-content-save</v-icon>
                          Save
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-dialog
                    v-model="dialogCalendar"
                    hide-overlay
                    persistent
                    width="350"
                  >
                    <v-card>
                      <v-card-title>Select new date</v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-date-picker
                              v-model="dates"
                              color="indigo"
                              range
                              elevation="10"
                            ></v-date-picker>
                          </v-col>

                          <v-card-actions>
                            <v-btn
                              color="orange"
                              @click="(dialogCalendar = false), LimpiarFecha()"
                              dark
                              v-if="false"
                            >
                              Cancel
                            </v-btn>

                            <v-btn
                              color="orange"
                              @click="dialogCalendar = false"
                              dark
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-dialog>

                  <v-col v-if="!btnAccionEdit" :cols="isMobile ? '12' : '9'">
                    <div class="flex w-full h-screen items-center" id="app">
                      <div
                        class="p-12 bg-gray-100 border border-gray-300"
                        @dragover="dragover"
                        @dragleave="dragleave"
                        @drop="drop"
                      >
                        <input
                          type="file"
                          multiple
                          name="fields[assetsFieldHandle][]"
                          class="
                            w-px
                            h-px
                            opacity-0
                            overflow-hidden
                            absolute
                            d-none
                            filepond
                          "
                          @change="BuscarImagen"
                          ref="file"
                          accept=".pdf,.jpg,.jpeg,.png"
                        />

                        <label
                          for="assetsFieldHandle"
                          class="block cursor-pointer"
                        >
                          <div>
                            <Chat
                              v-if="estatusChat"
                              :datos="datos"
                              :cerrar="cerraConexion"
                            />
                          </div>
                        </label>
                        <ul class="mt-4" v-if="this.filelist.length" v-cloak>
                          <li class="text-sm p-1" v-for="file in filelist">
                            {{ file.name
                            }}<button
                              class="ml-2"
                              type="button"
                              @click="remove(filelist.indexOf(file))"
                              title="Remove file"
                            >
                              remove
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <Encargados
                      v-if="encargados"
                      @cerrar="CerrarSelect"
                      :datos="datos"
                      :estatus="estatusSelect"
                    />

                    <Archivos
                      v-if="estatusArchivo"
                      :nodo="datos.idTarea"
                      @cerrar="CerrarArchivos"
                      :estatus="estatusArchivos"
                    />

                    <input
                      type="file"
                      ref="boton"
                      class="d-done"
                      @change="BuscarImagen($event)"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <v-dialog v-model="confirmacion" persistent max-width="25%">
      <v-tabs show-arrows background-color="#0D47A1" icons-and-text dark grow>
        <v-tabs-slider color="#0D47A1 "></v-tabs-slider>
        <v-tab>
          <v-icon right>mdi-delete</v-icon>
        </v-tab>
        <v-tab-item>
          <v-card>
            <v-card-text>
              <v-form lazy-validation>
                <v-row>
                  <v-card-text>
                    <v-row cols="12" sm="3" md="6">
                      <v-col
                        ><h3>
                          Are you sure you want to delete this task?
                        </h3></v-col
                      >
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      large
                      color="#EF6C00"
                      @click="confirmacion = false"
                      dark
                    >
                      Cancel
                    </v-btn>

                    <v-btn
                      color="#EF6C00"
                      large
                      dark
                      @click="EliminarTarea(), (estatusChat = false)"
                    >
                      OK
                    </v-btn>
                  </v-card-actions>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-dialog>
  </v-dialog>
</template>

<script>
import Chat from "../components/chat";
import Encargados from "../components/Encargados";
import { firebase, storage, db } from "../firebase";
import Archivos from "../components/Archivos";
import EventBus from "../bus";

export default {
  components: {
    Chat,
    Encargados,
    Archivos,
  },
  props: {
    estatusArchivo: true,
    estatus: Boolean,
    datos: {
      type: Object,
      default: () => [],
    },
    idTarea: {
      type: String,
      default: () => "",
    },
    validador: {
      type: Boolean,
      default: true,
    },
  },
  delimiters: ["${", "}"],
  data: () => ({
    isMobile: false,
    alert: false,
    oldValues: {
      encargado: "",
      fechaInicio: "",
      fechaFin: "",
      titulo: "",
      descripcion: "",
      prioridad: "",
    },
    dialogCalendar: false,
    dates: [],
    fechas: "",
    selectEmpleados: [],
    empleados: [],
    departamentos: [],
    selectDepartamentos: [],
    btnAccionEdit: false,
    editTaskStatus: "",
    rutaActual: window.location.href,
    ruta: "https://www.myfastax.com/adminTask",
    rutaSecundaria: "https://myfastax.com/adminTask",
    rutaActualSecundaria: window.location.href,
    cerraConexion: false,
    filelist: [],
    rol: sessionStorage.getItem("rol"),
    participanteChat: "",
    file: null,
    urlTemporal: "",
    encargados: false,
    estatusChat: true,
    rol: sessionStorage.getItem("rol"),
    estatusSelect: false,
    estatusArchivo: false,
    confirmacion: false,
    idEmpleado: "",
    fechaInicio: "",
    fechaFin: "",
    uid: sessionStorage.getItem("uid"),
  }),
  watch: {
    btnAccionEdit: function (val) {
      if (this.btnAccionEdit) {
        this.ConsultarEmpleadosConDepartamentos();
      }
    },
    dates: function (val) {
      if (this.dates.length > 1) {
        this.datos.fechaInicio = this.dates[0];
        this.datos.fechaFin = this.dates[1];
      } else {
        this.datos.fechaInicio = this.dates[0];
        this.datos.fechaFin = this.dates[0];
      }
    },
  },
  mounted() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      //alert("You're using Mobile Device!!");
      sessionStorage.setItem("isMobile", true);
      this.isMobile = true;
    } else {
      sessionStorage.setItem("isMobile", false);
      this.isMobile = false;
    }

    this.idEmpleado = sessionStorage.getItem("idEmpleado");
    this.ConsultarEmpleadosXTarea();

    //this.ValidalidarAccesoTarea();
    console.log(this.rutaActual);
  },
  computed: {
    dateRangeText() {
      this.fechas = this.dates.join(" ~ ");
    },
  },
  methods: {
    ValidalidarAccesoTarea() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 23);
      parametros.append("idTarea", this.datos.idTarea);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    RegresarValores() {
      this.selectEmpleados = this.oldValues.encargado;
      this.datos.fechaInicio = this.oldValues.fechaInicio;
      this.datos.fechaFin = this.oldValues.fechaFin;
      this.datos.nombreTarea = this.oldValues.titulo;
      this.datos.descripcion = this.oldValues.descripcion;
      this.datos.prioridad = this.oldValues.prioridad;
    },
    ClonarValores() {
      this.oldValues.encargado = this.selectEmpleados;
      this.oldValues.fechaInicio = this.datos.fechaInicio;
      this.oldValues.fechaFin = this.datos.fechaFin;
      this.oldValues.titulo = this.datos.nombreTarea;
      this.oldValues.descripcion = this.datos.descripcion;
      this.oldValues.prioridad = this.datos.prioridad;
    },
    LimpiarFecha() {
      this.dates = [];

      this.datos.fechaInicio = this.oldValues.fechaInicio;

      this.datos.fechaFin = this.oldValues.fechaFin;
    },
    ModificarTarea() {
      if (
        this.oldValues.encargado != this.selectEmpleados ||
        this.oldValues.fechaInicio != this.datos.fechaInicio ||
        this.oldValues.fechaFin != this.datos.fechaFin ||
        this.oldValues.titulo != this.datos.nombreTarea ||
        this.oldValues.descripcion != this.datos.descripcion ||
        this.oldValues.prioridad != this.datos.prioridad
      ) {
        if (
          this.selectEmpleados != 0 &&
          this.selectDepartamentos != 0 &&
          this.datos.descripcion != "" &&
          this.datos.nombreTarea != ""
        ) {
          let parametros = new URLSearchParams();

          parametros.append("id", sessionStorage.getItem("uid"));
          parametros.append("accion", 10);
          parametros.append("idTarea", this.datos.idTarea);
          parametros.append("idDepartamento", this.selectDepartamentos);
          parametros.append("idEmpleado", this.selectEmpleados);
          parametros.append("idEmpleadoAnterior", this.oldValues.encargado);
          parametros.append("prioridad", this.datos.prioridad);

          if (this.dates.length > 1) {
            this.fechaInicio = this.dates[0];
            this.fechaFin = this.dates[1];
          } else {
            this.fechaInicio = this.dates[0];
            this.fechaFin = this.dates[0];
          }

          parametros.append("nombreTarea", this.datos.nombreTarea);
          parametros.append("descripcion", this.datos.descripcion);

          if (this.dates != "") {
            parametros.append("fechaInicio", this.fechaInicio);
            parametros.append("fechaFin", this.fechaFin);
          } else {
            parametros.append("fechaInicio", this.datos.fechaInicio);
            parametros.append("fechaFin", this.datos.fechaFin);
          }

          axios({
            method: "post",
            url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
            data: parametros,
          })
            .then(
              function (response) {
                console.log(response);
                this.btnAccionEdit = false;

                this.AccionCerrar();
                this.estatusChat = false;
                this.CerrarConexion();
                this.cerraConexion = true;

                this.Enviarnoty(
                  this.selectEmpleados,
                  "New Task: " + this.datos.nombreTarea
                );

                alert("Success");

                //this.dialogAviso = true;
                /*  if (editor) {
                  this.aviso = "Save succeful";
                  this.btn_Agregar_Actualizar = "Send";
                } else {
                  this.aviso = "Save succeful";
                  this.Enviarnoty(
                    this.selectEncargados.idEmpleado,
                    response.data
                  );
                }*/
                // location.reload();

                // this.MostrarTareasXEmpleado();
                //this.loading = false;
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        } else {
          alert("Debe llenar todos los datos");
        }
      } else {
        alert("Error");
      }
    },
    MostrarEncargadosXDepartamentos(idDepartamento) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 4);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));
      parametros.append("rol", sessionStorage.getItem("rol"));
      parametros.append("idDepartamento", idDepartamento);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.empleados = [];

            response.data.forEach((element) => {
              this.empleados.push({
                idEmpleado: element.idEmpleado,
                nombreEmpleado: element.nombre,
              });
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarEmpleadosConDepartamentos() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 18);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.departamentos = [];
            this.selectDepartamentos = [];
            this.empleados = [];
            this.selectEmpleados = [];

            response.data.forEach((element) => {
              this.departamentos.push({
                idDepartamento: element.idDepartamento,
                nombreDepartamento: element.nombreDepartamento,
              });

              this.empleados.push({
                idEmpleado: element.idEmpleado,
                nombreEmpleado: element.nombreEmpleado,
              });

              if (this.datos.idEmpleado == element.idEmpleado) {
                this.selectDepartamentos = element.idDepartamento;

                this.selectEmpleados = element.idEmpleado;
                this.ClonarValores();
              }
            });

            this.MostrarEncargadosXDepartamentos(this.selectDepartamentos);

            //this.selected;
            // this.departamentos.push({ idDepartamento: 0, nombreDepartamento: "Select Other departament"});
          }.bind(this)
        )
        .catch(
          function (response) {
            console.log(response);
          }.bind(this)
        );
    },
    CerrarConexion() {
      EventBus.$emit("CloseConexionFirebase");
    },

    EnviarURL(url, name) {
      var nombre = sessionStorage.getItem("fullname");
      var urk = url;
      var mensaje = name;
      db.collection(this.datos.idTarea)
        .add({
          //Nodo padre del que queremos sacar los nodos hijos
          name: nombre,
          message: mensaje,
          url: url,
          download: true,
          time: Date.now(),
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error
      this.ConsultarColaboradores();
    },
    ConsultarColaboradores() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 18);
      parametros.append("idTarea", this.datos.idTarea);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            response.data.forEach((element) => {
              this.Enviarnoty(
                element.idEmpleado,
                "New message:  " + this.datos.nombreTarea
              );
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    Enviarnoty(uid, message) {
      console.log("noty" + uid);
      console.log(message);
      var nombre = "";
      var nombre = "";

      nombre = sessionStorage.getItem("fullname");
      db.collection("noty" + uid)
        .doc("notificacionChatTarea" + this.datos.idTarea)
        .set({
          //Nodo padre del que queremos sacar los nodos hijos
          name: nombre,
          type: "1",
          message: "New message:  " + this.datos.nombreTarea,
          time: Date.now(),
          idTarea: this.datos.idTarea,
          estatus: true,
          notificado: false,
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error
    },
    async SubirImagen() {
      try {
        const refImagen = storage
          .ref()
          .child("Archivos" + this.datos.idTarea)
          .child(this.file.name);

        const res = await refImagen.put(this.file);

        const urlDescarga = await refImagen.getDownloadURL();

        console.log(urlDescarga);
        this.EnviarURL(urlDescarga, this.file.name);
      } catch (error) {
        console.log(error);
      }
    },
    BuscarImagen(event) {
      this.file = event.target.files[0];
      this.SubirImagen();
    },
    CerrarSelect() {
      this.encargados = false;
    },
    TerminarTarea(idTarea) {
      let parametros = new URLSearchParams();

      parametros.append("accion", 5);
      parametros.append("idTarea", idTarea);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            alert("Finished");
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    CancelarTarea(idTarea) {
      let parametros = new URLSearchParams();

      parametros.append("accion", 6);
      parametros.append("idTarea", idTarea);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            alert("Canceled");
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    TareaEnProceso(idTarea, fechaInicio) {
      if (fechaInicio == "0000-00-00") {
        this.dialogCalendar = true;
      } else {
        let parametros = new URLSearchParams();

        parametros.append("accion", 7);
        parametros.append("idTarea", idTarea);

        axios({
          method: "post",
          url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
          data: parametros,
        })
          .then(
            function (response) {
              alert("In process");
            }.bind(this)
          )
          .catch(
            function (response) {
              //handle error
              console.log(response);
            }.bind(this)
          );
      }
    },
    AccionCerrar() {
      this.$emit("cerrar");
    },
    async subirImagen() {
      try {
        const refImagen = storage.ref().child("Archivos" + datos.idTarea);
        const res = await refImagen.put(file);
      } catch (error) {
        conole.log(error);
      }
    },
    CerrarArchivos() {
      this.estatusArchivo = false;
    },

    EliminarTarea() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 8);
      parametros.append("idTarea", this.datos.idTarea);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response);
            this.AccionCerrar();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    EventoCerrarESC() {
      alert("evento esc");
    },
    ConsultarEmpleadosXTarea() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 7);
      parametros.append("idTarea", this.datos.idTarea);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data[0].idEmpleado);
            this.participanteChat = response.data[0].nombre;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    onChange() {
      this.filelist = [...this.$refs.file.files];
    },
    remove(i) {
      this.filelist.splice(i, 1);
      this.filelist.clear();
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      this.BuscarArchivo(event.dataTransfer.files);
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    BuscarArchivo(file) {
      this.dialog = true;
      this.file = file[0];
      this.SubirImagen();
    },
  },
};
</script>

<style >
.blob {
  box-shadow: 0 0 0 0 rgb(255, 0, 0);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}
</style>