<template>
  <v-div>
    <v-dialog v-model="dialogViewer">
      <VueDocPreview
        v-if="extension == 'docx' || extension == 'xlsx'"
        :value="rutaArchivo"
        type="office"
      />

      <a v-else :href="rutaArchivo" target="_blank">pdf</a>
    </v-dialog>

    <v-dialog v-model="confirmDeleteFile" max-width="25%">
      <v-tabs show-arrows background-color="#0D47A1" icons-and-text dark grow>
        <v-tabs-slider color="#0D47A1 "></v-tabs-slider>
        <v-tab>
          <v-icon right></v-icon>
        </v-tab>
        <v-tab-item>
          <v-card>
            <v-card-text>
              <v-form lazy-validation>
                <v-row>
                  <v-card-text>
                    <v-row cols="12" sm="3" md="6">
                      <v-col><h3>Are you sure you want to delete ?</h3></v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      large
                      color="#EF6C00"
                      @click="confirmDeleteFile = false"
                      dark
                    >
                      Cancel
                    </v-btn>

                    <v-btn color="#EF6C00" large dark @click="EliminarArchivo">
                      OK
                    </v-btn>
                  </v-card-actions>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-dialog>

    <v-data-table
      :headers="headers"
      :items="trabajadores"
      sort-by="calories"
      :search="search"
      class="elevation-1"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon v-if="item.isDirectory" color="indigo" class="mr-2">
          mdi-folder
        </v-icon>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title></v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="700%">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                New Employee
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <h2 style="color: #ff7f00">Personal information</h2>
                  <v-divider class="mx-4"></v-divider>
                  <v-row class="mt-6">
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.nombre"
                        label="Name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.aPaterno"
                        label="Last name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.Direccion"
                        label="Address"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.city"
                        label="City, State, Country and ZIP code"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.SSN"
                        label="SSN"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-container fluid>
                        <v-checkbox
                          v-model="editedItem.estadoCivil"
                          label="Single or Married filing separately"
                          value="1"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="editedItem.estadoCivil"
                          label="Married filing jointly or Qualifying window(er)"
                          value="2"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="editedItem.estadoCivil"
                          label="Head of household (Check only if you´re unmarried and pay more than half the costs of keeping up a home for yourself and a qualifying individual)"
                          value="3"
                        ></v-checkbox>
                      </v-container>
                    </v-col>
                  </v-row>
                  <h2 style="color: #ff7f00">Claim dependents</h2>
                  <v-divider class="mx-4" inset></v-divider>
                  <v-row class="mt-6">
                    <v-col cols="12" sm="6" md="4">
                      <h3 style="color: #000000">
                        If your total income will be $200,000 or less ($400,000
                        or less if married filing jointly).
                      </h3>

                      <br />
                      Multiply the number of qualifying children under age 17 by
                      $2,000
                      <v-text-field
                        outlined
                        placeholder="$"
                        v-model="editedItem.NumberChildren"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" class="mt-16">
                      Multiply the number of other dependets by $500
                      <v-text-field
                        class="mt-6"
                        outlined
                        placeholder="$"
                        v-model="editedItem.dependets"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <h2 style="color: #ff7f00">Other Adjustments</h2>
                  <v-divider class="mx-4" inset></v-divider>
                  <v-row class="mt-6">
                    <v-col cols="12" sm="6" md="4">
                      (a) Other income (not from jobs). if you want tax withheld
                      for other income you expect this year that won´t have
                      withholding, enter the amount of other income here. this
                      may include interest, dividends, and retirements income.
                      <v-text-field
                        outlined
                        placeholder="$"
                        v-model="editedItem.OtherIncome"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      (b) Deductions. if you expect to claim deductions other
                      than the standard deduction and want to reduce your
                      withholding, use the deductions worksheet on page 3 and
                      enter the result here.
                      <v-text-field
                        outlined
                        placeholder="$"
                        v-model="editedItem.Deductions"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4" class="mt-11">
                      (c) Extra withholding. Enter any additional tax you want
                      withheld pay period.
                      <v-text-field
                        outlined
                        placeholder="$"
                        v-model="editedItem.ExtraWithholding"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>

        <v-icon
          small
          class="mr-2"
          @click="
            (confirmDeleteEmployee = true), (idTrabajador = item.idTrabajador)
          "
        >
          mdi-delete
        </v-icon>

        <v-icon small class="mr-2" @click="AbrirDialogo(item)">
          mdi-folder
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset </v-btn>
      </template>
    </v-data-table>

    <v-row>
      <v-dialog v-model="dialogoArchivos" width="40%">
        <div
          class="flex w-full h-screen items-center justify-center text-center"
          id="app"
        >
          <div
            class="p-12 bg-gray-100 border border-gray-300"
            @dragover="dragover"
            @dragleave="dragleave"
            @drop="drop"
          >
            <input
              type="file"
              multiple
              name="fields[assetsFieldHandle][]"
              class="
                w-px
                h-px
                opacity-0
                overflow-hidden
                absolute
                d-none
                filepond
              "
              @change="SubirArchisServidor"
              ref="file"
              accept=".pdf,.jpg,.jpeg,.png"
            />

            <label for="assetsFieldHandle" class="block cursor-pointer">
              <div>
                <v-card>
                  <v-card-title class="headline indigo">
                    <h4>Files</h4>
                  </v-card-title>

                  <v-card-text>
                    <v-col cols="2">
                      <v-btn
                        color="#0D47A1"
                        class="text-none"
                        dark
                        round
                        depressed
                        @click="onButtonClick"
                      >
                        <v-icon left> mdi-cloud-upload </v-icon>
                        Upload
                      </v-btn>

                      <input
                        ref="uploader"
                        class="d-none filepond"
                        type="file"
                        @change="SubirArchisServidor"
                      />
                    </v-col>
                    <v-row>
                      <v-col cols="12">
                        <v-data-table
                          :headers="headerFile"
                          :items="archivos"
                          :items-per-page="5"
                          class="elevation-15"
                        >
                          <template v-slot:[`item.actions`]="{ item }">
                            <v-icon
                              color="indigo"
                              class="mr-2"
                              @click="downloadItem(item)"
                            >
                              mdi-download
                            </v-icon>

                            <v-icon
                              color="indigo"
                              class="mr-2"
                              @click="
                                (confirmDeleteFile = true),
                                  (idDocumento = item.idDocumento)
                              "
                            >
                              mdi-delete
                            </v-icon>

                            <v-icon
                              color="indigo"
                              class="mr-2"
                              @click="Redireccionar(item)"
                            >
                              mdi-eye
                            </v-icon>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="#EF6C00"
                      dark
                      @click="dialogoArchivos = false"
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </div>
            </label>
            <ul class="mt-4" v-if="this.filelist.length" v-cloak></ul>
          </div>
        </div>
      </v-dialog>

      <v-dialog v-model="confirmDeleteEmployee" max-width="25%">
        <v-tabs show-arrows background-color="#0D47A1" icons-and-text dark grow>
          <v-tabs-slider color="#0D47A1 "></v-tabs-slider>
          <v-tab>
            <v-icon right></v-icon>
          </v-tab>
          <v-tab-item>
            <v-card>
              <v-card-text>
                <v-form lazy-validation>
                  <v-row>
                    <v-card-text>
                      <v-row cols="12" sm="3" md="6">
                        <v-col
                          ><h3>Are you sure you want to delete ?</h3></v-col
                        >
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        large
                        color="#EF6C00"
                        @click="confirmDeleteEmployee = false"
                        dark
                      >
                        Cancel
                      </v-btn>

                      <v-btn
                        color="#EF6C00"
                        large
                        dark
                        @click="EliminarTrabajador(idTrabajador)"
                      >
                        OK
                      </v-btn>
                    </v-card-actions>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-dialog>
    </v-row>
  </v-div>
</template>
<script>
import { db, storage } from "../firebase";
import moment from "moment";
import pdf from "vue-pdf";

import EventBus from "../bus";
import VueDocPreview from "vue-doc-preview";
export default {
  components: {
    VueDocPreview,
    pdf,
  },
  data: () => ({
    idTrabajador: "",
    confirmDeleteEmployee: false,
    idDocumento: "",
    extension: "",
    rutaArchivo: "",
    dialogViewer: false,
    search: "",
    confirmDeleteFile: false,
    idFile: "",
    fileName: "",
    cargaArchivos: false,
    filelist: [],
    file: null,
    files: [],
    directorio: [],
    selected: [],
    archivos: [],
    headerFile: [
      {
        text: "Name file",
        align: "start",
        sortable: false,
        value: "nombreArchivo",
      },
      {
        text: "Extension",
        sortable: false,
        value: "extension",
      },
      { text: "Accions", value: "actions", sortable: false },
    ],
    dialogoArchivos: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "nombre",
      },
      { text: "Last name", value: "aPaterno" },
      { text: "Address", value: "Direccion" },
      { text: "SSN", value: "SSN" },
      { text: "Actions", value: "actions" },
      ,
    ],
    trabajadores: [],
    editedIndex: -1,
    editedItem: {
      nombre: "",
      aPaterno: "",
      Direccion: "",
      SSN: "",
      city: "",
      estadoCivil: "",
      NumberChildren: "",
      dependents: "",
      OtherIncome: "",
      Deductions: "",
      ExtraWithholding: "",
      idCliente: "",
      idTrabajador: "",
    },
    defaultItem: {
      nombre: "",
      aPaterno: "",
      Direccion: "",
      SSN: "",
      city: "",
      estadoCivil: "",
      NumberChildren: "",
      dependents: "",
      OtherIncome: "",
      Deductions: "",
      ExtraWithholding: "",
      idCliente: "",
      idTrabajador: "",
    },
  }),
  mounted() {
    this.ConsultarTrabajadores();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Employee´s" : "Edit Employee´s";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.initialize();
  },

  methods: {
    onChange() {
      this.filelist = [...this.$refs.file.files];
    },
    remove(i) {
      this.filelist.splice(i, 1);
      this.filelist.clear();
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      this.BuscarArchivo(event.dataTransfer.files);

      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    BuscarArchivo(file) {
      console.log(file[0]);
      //this.file = event.target.files[0];
      var formData = new FormData();
      formData.append("archivo", file[0]);
      formData.append("accion", 5);
      formData.append("idTrabajador", this.idTrabajador);
      formData.append("idCliente", sessionStorage.getItem("idCliente"));
      formData.append("nombreArchivo", file[0].name);

      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorTrabajadores.php",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(
          function (response) {
            console.log(response.data);
            this.ConsultarArchivos();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    EliminarArchivo() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 7);
      parametros.append("idDocumento", this.idDocumento);

      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorTrabajadores.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.ConsultarArchivos();
            this.confirmDeleteFile = false;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarArchivos() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 6);
      parametros.append("idTrabajador", this.idTrabajador);

      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorTrabajadores.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.archivos = [];

            response.data.forEach((element) => {
              var extension = /[.]/.exec(element.nombreArchivo)
                ? /[^.]+$/.exec(element.nombreArchivo)[0]
                : undefined;

              this.archivos.push({
                idDocumento: element.idDocumento,
                nombreArchivo: element.nombreArchivo,
                ruta: element.ruta,
                fecha: element.fecha,
                estatus: element.estatus,
                extension: extension,
                idCliente: element.idCliente,
                idTrabajador: element.idTrabajador,
              });
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    SubirArchisServidor(event) {
      this.file = event.target.files[0];
      var formData = new FormData();
      formData.append("archivo", event.target.files[0]);
      formData.append("accion", 5);
      formData.append("idTrabajador", this.idTrabajador);
      formData.append("idCliente", sessionStorage.getItem("idCliente"));
      formData.append("nombreArchivo", this.file.name);

      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorTrabajadores.php",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(
          function (response) {
            console.log(response.data);
            this.ConsultarArchivos();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    Redireccionar(item) {
      console.log(item.ruta);
      this.rutaArchivo = item.ruta;
      this.extension = item.extension;
      //console.log(this.rutaArchivo);
      // window.location.href = item.url;

      if (this.extension == "docx" || this.extension == "xlsx") {
        //deterina que extension tiene y a si abre el dialogo o abre una ventana nueva
        this.dialogViewer = true;
      } else {
        window.open(this.rutaArchivo, "_blank");
      }
    },

    EliminarTrabajador(idTrabajador) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 4);
      parametros.append("idTrabajador", idTrabajador);

      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorTrabajadores.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.ConsultarTrabajadores();
            this.confirmDeleteEmployee = false;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    OpenDialogDeleteFile(item) {
      console.log(item);
      this.idTrabajador = item.idTRabajador;
      this.fileName = item.name;
      this.idFile = item.id;
      this.confirmDeleteFile = true;
      console.log(item);
    },
    ObtenerIdTrabajador(item) {},
    downloadItem(item) {
      window.open(item.ruta, "download");
    },
    AbrirDialogo(item) {
      this.idTrabajador = item.idTrabajador;
      console.log(this.idTrabajador);
      this.dialogoArchivos = true;
      console.log(this.dialogoArchivos);
      this.ConsultarArchivos();
    },
    ModificarTrabajador() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 3);
      parametros.append("idCliente", sessionStorage.getItem("idCliente"));
      parametros.append("nombre", this.editedItem.nombre);
      parametros.append("aPaterno", this.editedItem.aPaterno);
      parametros.append("Direccion", this.editedItem.Direccion);
      parametros.append("SSN", this.editedItem.SSN);
      parametros.append("city", this.editedItem.city);
      parametros.append("estadoCivil", this.editedItem.estadoCivil);
      parametros.append("NumberChildren", this.editedItem.NumberChildren);
      parametros.append("dependets", this.editedItem.dependets);
      parametros.append("OtherIncome", this.editedItem.OtherIncome);
      parametros.append("Deductions", this.editedItem.Deductions);
      parametros.append("ExtraWithholding", this.editedItem.ExtraWithholding);
      parametros.append("idTrabajador", this.editedItem.idTrabajador);

      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorTrabajadores.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.ConsultarTrabajadores();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    InsertarTrabajador() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 2);
      parametros.append("idCliente", sessionStorage.getItem("idCliente"));
      parametros.append("nombre", this.editedItem.nombre);
      parametros.append("aPaterno", this.editedItem.aPaterno);
      parametros.append("Direccion", this.editedItem.Direccion);
      parametros.append("SSN", this.editedItem.SSN);
      parametros.append("city", this.editedItem.city);
      parametros.append("estadoCivil", this.editedItem.estadoCivil);
      parametros.append("NumberChildren", this.editedItem.NumberChildren);
      parametros.append("dependents", this.editedItem.dependets);
      parametros.append("OtherIncome", this.editedItem.OtherIncome);
      parametros.append("Deductions", this.editedItem.Deductions);
      parametros.append("ExtraWithholding", this.editedItem.ExtraWithholding);

      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorTrabajadores.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.ConsultarTrabajadores();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarTrabajadores() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 1);
      parametros.append("idCliente", sessionStorage.getItem("idCliente"));

      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorTrabajadores.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.trabajadores = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    initialize() {
      this.trabajadores = [];
    },
    editItem(item) {
      this.editedIndex = this.trabajadores.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.trabajadores.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.trabajadores.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.trabajadores[this.editedIndex], this.editedItem);
        this.ModificarTrabajador();
      } else {
        this.trabajadores.push(this.editedItem);
        this.InsertarTrabajador();
      }

      this.close();
    },
    BuscarImagen(event) {
      this.dialog = true;
      this.file = event.target.files[0];
      this.SubirImagen();
    },
    SubirArchivos(event) {
      this.IniciarProgress();
      this.cargaArchivos = false;
      this.dialog = true;

      this.file = event[0];

      this.SubirImagen();
    },
    async SubirImagen() {
      try {
        const refImagen = storage
          .ref()
          .child(
            "Employee-" +
              sessionStorage.getItem("idCliente") +
              "-" +
              new Date().getFullYear() +
              "-" +
              this.idTrabajador
          )
          .child(this.file.name);

        const res = await refImagen.put(this.file);

        const urlDescarga = await refImagen.getDownloadURL();

        this.EnviarURL(urlDescarga, this.file.name, this.file.size);
      } catch (error) {
        console.log(error);
      }
    },
    onButtonClick() {
      this.$refs.uploader.click();
    },
    EnviarURL(urlDescarga, fileName, fileSize) {
      db.collection(
        "Employee-" +
          sessionStorage.getItem("idCliente") +
          "-" +
          new Date().getFullYear() +
          "-" +
          this.idTrabajador
      )
        .add({
          name: fileName,
          size: fileSize,
          url: urlDescarga,
          time: Date.now(),
          status: 1,
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error

      this.cargaArchivos = true;
      this.dialog = false;
    },

    /*ConsultarArchivos() {
      moment.locales("es");

      let ref = db
        .collection(
          "Employee-" +
            sessionStorage.getItem("idCliente") +
            "-" +
            new Date().getFullYear() +
            "-" +
            this.idTrabajador
        )
        .where("status", "==", 1); // con el order by ordena los datos de acuerdo al campo especificado.

      ref.onSnapshot((querySnapshot) => {
        this.archivos = [];
        querySnapshot.forEach((element) => {
          //forEach que recorre toda la coleccion de mensajes

          this.archivos.push({
            name: element.data().name,
            size: element.data().size,
            url: element.data().url,
            time: moment(element.data().time).format("lll"),
            id: element.id,
          });
        });
      });
    },*/
  },
};
</script>

<style>
</style>