import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Panel from '../views/MainPanel.vue'
import Tools from '../components/Herramientas.vue';
import sales from '../views/SalesTaxes.vue';
import client from '../views/Clientes.vue';
const salesTax = sales
const mainPanel = Panel;
const clients = client;

Vue.use(VueRouter)

const routes = [

  {

    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',

    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {

    path: '/TaskCenter',
    name: 'TaskCenter',
    component: () => import(/* webpackChunkName: "about" */ '../views/MainPanel')
  },
  {
    path: '/tools',
    name: 'Tools',
    component: Tools
  },
  {
    path: '/adminTask',
    name: 'AdminTask',

    component: () => import(/* webpackChunkName: "about" */ '../views/AdministradorTareas.vue')
  },
  {
    path: '/Vendors',
    name: 'Vendors',

    component: () => import(/* webpackChunkName: "about" */ '../views/Vendors.vue')
  },
  {
    path: '/Usuarios',
    name: 'Usuarios',

    component: () => import(/* webpackChunkName: "about" */ '../views/Usuarios.vue')
  },
  {
    path: '/RoomChats',
    name: 'RoomChats',

    component: () => import(/* webpackChunkName: "about" */ '../views/RoomChats.vue')
  },
  {
    path: '/SalesTaxes',
    component: () => import(/* webpackChunkName: "about" */ '../views/SalesTaxes.vue')
  },
  {
    path: '/PrincipalCliente',
    name: 'PrincipalCliente',

    component: () => import(/* webpackChunkName: "about" */ '../views/PrincipalCliente.vue')
  },
  {
    path: '/Payroll',
    name: 'Payroll',

    component: () => import(/* webpackChunkName: "about" */ '../views/Payroll.vue')
  },
  {
    path: '/Bookkeeping',
    name: 'Bookkeeping',

    component: () => import(/* webpackChunkName: "about" */ '../views/Bookkeeping.vue')
  },
  {
    path: '/DocumentosCliente',
    name: 'DocumentosCliente',

    component: () => import(/* webpackChunkName: "about" */ '../views/DocumentosCliente.vue')
  },
  {
    path: '/Clientes',
    name: 'Company services',
    component: () => import(/* webpackChunkName: "about" */ '../views/Clientes.vue')
  },
  {
    path: '/TaskDetail/:id',
    name: 'TaskDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/TaskDetail.vue')
  },
  {
    path: '/ChatNotificacion/:id',
    name: 'ChatNotificacion',
    component: () => import(/* webpackChunkName: "about" */ '../views/ChatNotificacion.vue')
  },
  {
    path: '/Companies',
    name: 'Companies',
    component: () => import(/* webpackChunkName: "about" */ '../views/Companies.vue')
  },
  {
    path: '/Company',
    name: 'Company',
    component: () => import(/* webpackChunkName: "about" */ '../views/CompanyInfo.vue')
  },
  {
    path: '/GroupChat/:id',
    name: 'GroupChat',
    component: () => import(/* webpackChunkName: "about" */ '../views/GroupChat.vue')
  },
  {
    path: '/StatusBookKeeping',
    name: 'StatusBookKeeping',
    component: () => import(/* webpackChunkName: "about" */ '../views/StatusBookKeeping.vue')
  },
  {
    path: '/Calendar',
    name: 'Calendar',
    component: () => import(/* webpackChunkName: "about" */ '../views/Calendar.vue')
  },
  {
    path: '/Individuals',
    name: 'Individuals',
    component: () => import(/* webpackChunkName: "about" */ '../views/Individuals.vue')
  },
  {
    path: '/Clients',
    name: 'Clients',
    component: () => import(/* webpackChunkName: "about" */ '../views/Clients.vue')
  },
  {
    path: '/PrincipalClients',
    name: 'PrincipalClients',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrincipalClients.vue')
  },
  {
    path: '/Llamadas',
    name: 'Llamadas',
    component: () => import(/* webpackChunkName: "about" */ '../views/LLamadas.vue')
  },
  {
    path: '/MyBusiness',
    name: 'MyBusiness',
    component: () => import(/* webpackChunkName: "about" */ '../views/myBusiness.vue')
  },
  {
    path: '/MyPayroll',
    name: 'MyPayroll',
    component: () => import(/* webpackChunkName: "about" */ '../views/MyPayrroll.vue')
  },
  {
    path: '/MyBookkeeping',
    name: 'MyBookkeeping',
    component: () => import(/* webpackChunkName: "about" */ '../views/MyBookkeeping.vue')
  },
  {
    path: '/MyTax',
    name: 'MyTax',
    component: () => import(/* webpackChunkName: "about" */ '../components/cliente/MyTax.vue')
  },
  {
    path: '/MyCompany',
    name: 'MyCompany',
    component: () => import(/* webpackChunkName: "about" */ '../views/MyCompany.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  hash: false,
  routes,

})

export default router
