<script>
import EventBus from "../bus";
export default {
  data: () => ({
    tipo: sessionStorage.getItem("tipo"),
    dialog: false,
    dialogDelete: false,
    search: "",
    headers: [
      { text: "Acciones", value: "actions", sortable: false },
      {
        text: "VendorQB",
        align: "start",
        sortable: false,
        value: "vendorQB",
      },
      {
        text: "Vendor bank",
        align: "start",
        sortable: false,
        value: "vendor",
      },
      { text: "Category", value: "category" },
    ],
    vendors: [],
    editedIndex: -1,
    editedItem: {
      idVendor: "",
      vendorQB: "",
      vendor: "",
      category: "",
      idCliente: "",
    },
    defaultItem: {
      idVendor: "",
      vendorQB: "",
      vendor: "",
      category: "",
      idCliente: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  mounted() {
    EventBus.$emit("hint-taskcenter");
    this.initialize();
  },
  methods: {
    initialize() {
      this.ConsultarVendors();
    },

    editItem(item) {
      this.editedIndex = this.vendors.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.vendors.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
      this.editedItem.idVendor = item.idVendor;
    },

    deleteItemConfirm() {
      this.vendors.splice(this.editedIndex, 1);
      this.closeDelete();
      this.EliminarVendor();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.vendors[this.editedIndex], this.editedItem);
      } else {
        this.vendors.push(this.editedItem);
      }
      this.Accion();
      this.close();
    },
    ConsultarVendors() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 1);
      parametros.append("idCliente", sessionStorage.getItem("idCliente"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorVendors.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.vendors = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    Accion() {
      console.log(this.editedIndex);
      if (this.editedIndex === -1) {
        this.IngresarVendor();
      } else {
        this.ModificarVendor();
      }
    },
    IngresarVendor() {
      let parametros = new URLSearchParams();

      console.log(this.editedItem);
      parametros.append("accion", 2);
      parametros.append("VendorQB", this.editedItem.vendorQB);
      parametros.append("Vendor", this.editedItem.vendor);
      parametros.append("Category", this.editedItem.category);
      parametros.append("idCliente", sessionStorage.getItem("idCliente"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorVendors.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.LimpiarCajas();
            this.vendors = response.data;
            //alert("Insert Susseful");
            this.ConsultarVendors();
            this.dialog = false;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ModificarVendor() {
      let parametros = new URLSearchParams();

      parametros.append("accion", 3);
      parametros.append("idVendor", this.editedItem.idVendor);
      parametros.append("VendorQB", this.editedItem.vendorQB);
      parametros.append("Vendor", this.editedItem.vendor);
      parametros.append("Category", this.editedItem.category);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorVendors.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            //this.LimpiarCajas();
            //this.vendors = response.data;
            //alert("Insert Susseful");
            //this.ConsultarVendors();
            //this.dialog = false;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    EliminarVendor() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 4);
      parametros.append("idVendor", this.editedItem.idVendor);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorVendors.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.LimpiarCajas();
            this.vendors = response.data;
            //alert("Insert Susseful");
            this.ConsultarVendors();
            this.dialog = false;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    LimpiarCajas() {
      this.Vendor = "";
      this.constructora = "";
      this.herreria = "";
      this.salonBelleza = "";
      this.panaderia = "";
      this.restaurante = "";
      this.superMercado = "";
      this.boutique = "";
      this.floreria = "";
      this.plomeria = "";
      this.jardineria = "";
      this.gruas = "";
      this.transport = "";
    },
  },
};
</script>


