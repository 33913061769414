<template>
   <v-btn  href="" target="_blank" text>
        <span class="mr-2 hidden-sm-and-down" @click="dialogoArchivo = true"
          >LECTOR EXCEL</span
        >
      </v-btn>
</template>

<script>
export default {

}
</script>

<style>

</style>