var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":_vm.isMobile?'100%':'40%'},model:{value:(_vm.estatus),callback:function ($$v) {_vm.estatus=$$v},expression:"estatus"}},[_c('v-tabs',{attrs:{"show-arrows":"","background-color":"#0D47A1","icons-and-text":"","dark":"","grow":""}},[_c('v-tabs-slider',{attrs:{"color":"#0D47A1 "}}),_c('v-btn',{staticClass:"mt-4 ml-1",attrs:{"dark":"","text":""},on:{"click":function($event){(_vm.estatus = false), _vm.AccionCerrar()}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-close")])],1),_c('v-tab',[_c('v-btn',{attrs:{"text":"","x-large":""}},[_vm._v("Links "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-link")])],1)],1),_c('v-tab-item',[_c('v-card',[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.links,"sort-by":"calories","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-link")])],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"#EF6C00","dark":""},on:{"click":function($event){_vm.detalleLink ? (_vm.detalleLink = false) : (_vm.detalleLink = true)}}},[(!_vm.detalleLink)?_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-eye ")]):_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-eye-off ")]),_vm._v(" Link ")],1),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-10",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-dialog',{attrs:{"max-width":"50%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" New link ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Name"},model:{value:(_vm.editedItem.nombre),callback:function ($$v) {_vm.$set(_vm.editedItem, "nombre", $$v)},expression:"editedItem.nombre"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{attrs:{"outlined":"","label":"Link"},model:{value:(_vm.editedItem.ruta),callback:function ($$v) {_vm.$set(_vm.editedItem, "ruta", $$v)},expression:"editedItem.ruta"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Save ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Are you sure you want to delete this link ? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.ruta",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ruta)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.redirect",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.AbrirRuta(item)}}},[_vm._v(" mdi-page-next ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }