
export default {
    props: {
        isOpenDialogToken: {
            type: Boolean,
            default: () => false,
        },
        idUsuario: {
            type: String,
            default: () => '',
        },
        funcion: {
            type: Number,
            default: () => 0,
        },
        nombreUsuario: {
            type: String,
            default: () => '',
        }

    },
    data: () => ({
        loading: false,
        snackbar: false,
        snackbarColor: 'default',
        otp: '',
        text: '',
        expectedOtp: '0000',
    }),
    methods: {
        onFinish(rsp) {
            this.loading = true
            setTimeout(() => {

                this.EnviarToken(rsp);


            }, 3500)
        },
        EnviarToken(rsp) {
            console.log(this.funcion);
            let parametros = new URLSearchParams();
            parametros.append("accion", this.funcion);
            parametros.append("tipoCliente", 2);
            parametros.append("token", this.otp);
            parametros.append("idUsuario", this.idUsuario);
            // parametros.append("user",this.nombreUsuario);

            axios({
                method: "post",
                url: sessionStorage.getItem("ruta") + "/core/Authentication.php",
                data: parametros,
            })
                .then(
                    function (response) {
                        console.log(response.data);

                        if (response.data == 0) {
                            alert('Your Fastax code is not correct');
                            this.otp = '';
                            this.loading = false
                            return;

                        }
                        alert('You are all set!      Now you can log in and have access to your company information.');
                        /*
                        this.loading = false
                        this.snackbarColor = (response.data === 1) ? 'success' : 'red'
                        this.text = `Code incorrect "${rsp}" `
                        this.snackbar = true
                        this.isOpenDialogToken = false;*/
                        if (this.funcion == 15) {
                            this.$emit("OpenDialogForgetPasword");
                            this.loading = false
                            this.$emit("closeDialogToken");
                            
                        } else {
                            this.loading = false
                            location.reload();

                        }



                        /*this.isOpenDialogToken = false;
                        alert(
                            "You are all set!      Now you can log in and have access to your company information."
                        );
                        location.reload();*/
                    }.bind(this)
                )
                .catch(
                    function (response) {
                        //handle error
                        console.log(response);
                    }.bind(this)
                );
        },
    },
}
