<script>
var time = new Date();
import Titulo from "../components/Titulo";
import Aviso from "../components/Aviso";
import DetalleTarea from "../components/DetalleTarea";
import NuevTarea from "../components/NuevaTareaGeneral.vue";
import ChatPersonal from "../components/ChatPersonal";
import EventBus from "../bus";
import { db } from "../firebase";
var month = time.getUTCMonth() + 1; //months from 1-12

export default {
  components: {
    Titulo,
    Aviso,
    DetalleTarea,
    NuevTarea,
    ChatPersonal,
  },
  data() {
    return {
      isMobile: false,
      selectFiltro: "",
      empleadosfiltros: [],
      empleadosfiltros: [],
      itemsEmpleados: [],
      itemsEncargados: [],
      selectDepartamentos: "",
      departamentos: [],
      SwitchTask: false,
      search: "",
      dialogoNueva: false,
      estatus: false,
      dialogCalendar: false,
      dialogoColaborador: false,
      overlay: false,
      dates: "",
      fechaActual: new Date(Date.now()),
      fechaInicio: null,
      fechaFin: null,
      fechaCreacion: null,
      loading: false,
      detalles: [],
      datosTarea: [],
      headers: [
        {
          text: "My task",
          align: "star",
          sortable: false,
          value: "nombreTarea",
        },
        { text: "Description", align: "star", value: "descripcion" },
      ],
      tareas: [],
      dialogoDetalleTarea: false,
      commit: "",
      idTarea: "",
      itemsTareas: [],
      selectTareas: [],
      parents: [],
      idEmpleado: "",
      estatusNuevaTarea: false,
      tipoEmpleado: 0,
      tipo: sessionStorage.getItem("tipo"),
    };
  },
  computed: {
    activeFab() {
      switch (this.tabs) {
        case "one":
          return { class: "purple", icon: "account_circle" };
        case "two":
          return { class: "red", icon: "edit" };
        case "three":
          return { class: "green", icon: "keyboard_arrow_up" };
        default:
          return {};
      }
    },
  },
  watch: {
    SwitchTask: function (val) {
      if (this.SwitchTask) {
        this.headers = [
          {
            text: "My task",
            align: "start",
            sortable: false,
            value: "nombreTarea",
          },
          { text: "Users", value: "nombre" },
          { text: "Manager", value: "manager" },
          { text: "Description", value: "descripcion" },
        ];
        this.itemsTareas = [];
        this.tareas = [];
        this.ConsultarTareasPersonales();
      } else {
        this.headers = [
          {
            text: "My task",
            align: "start",
            sortable: false,
            value: "nombreTarea",
          },
          { text: "Description", value: "descripcion" },
        ];
        this.tareas = [];
        this.ConsultarTareas();

        this.itemsTareas.push(
          { state: "All tasks", id: "4" },
          { state: "Active", id: "1" },
          { state: "In progress", id: "3" },
          { state: "Finished", id: "2" },
          { state: "Canceled", id: "0" }
        );
      }
    },
    top(val) {
      this.bottom = !val;
    },
    right(val) {
      this.left = !val;
    },
    bottom(val) {
      this.top = !val;
    },
    left(val) {
      this.right = !val;
    },
  },
  mounted() {

    if(
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      //alert("You're using Mobile Device!!");
      sessionStorage.setItem("isMobile", true);
      this.isMobile = true;
    } else {
      sessionStorage.setItem("isMobile", false);
      this.isMobile = false;
    }



   // this.isMobile = sessionStorage.getItem("isMobile");


    EventBus.$emit("hint-taskcenter");
    this.overlay = false;
    this.tipoEmpleado = sessionStorage.getItem("tipo");
    this.idEmpleado = sessionStorage.getItem("uid");

    this.ConsultarDepartamentos();

    //this.LLenarTabla(1);
    this.ValidarSesion();
    this.InizializarCombo();
    this.ConsultarTareas();

    this.itemsTareas.push(
      { state: "All tasks", id: "4" },
      { state: "Active", id: "1" },
      { state: "In progress", id: "3" },
      { state: "Finished", id: "2" },
      { state: "Canceled", id: "0" }
    );
  },
  methods: {
    LlenarEstatus() {
      this.itemsTareas = [];
      this.itemsTareas.push(
        { state: "All tasks", id: "4" },
        { state: "Active", id: "1" },
        { state: "In progress", id: "3" },
        { state: "Finished", id: "2" },
        { state: "Canceled", id: "0" }
      );
    },
    editItem(item) {
      //este es el item que trae todos los valores
      this.dialogoNueva = true;
      this.datosTarea = item;
      this.estatusNuevaTarea = true;
    },
    ConsultarTareasXEstatus() {
      let id = this.selectTareas;
      this.events = [];
      let parametros = new URLSearchParams();
      parametros.append("uid", sessionStorage.getItem("uid"));
      if (this.selectTareas == 5) {
        parametros.append("accion", 25);
      } else {
        parametros.append("accion", 25);
      }

      parametros.append("id", id);

      if (this.selectDepartamentos > 0) {
        parametros.append("idDepartamento", this.selectDepartamentos);
      } else {
      }
      if (this.selectFiltro > 0) {
        parametros.append("idEmpleado", this.selectFiltro);
      }

      if (this.selectManagers > 0) {
        parametros.append("idManager", this.selectManagers);
      }

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            (this.headers = [
              {
                text: "Task",
                align: "start",
                sortable: false,
                value: "nombreTarea",
              },
              { text: "Users", value: "nombre" },
              { text: "Manager", value: "manager" },
              { text: "Description", value: "descripcion" },
            ]),
              console.log(response.data);
            this.tareas = response.data;
            const events = [];
            if (response.data.length == 0) {
              this.events = [];
            } else {
              this.tareas.forEach((element) => {
                let colors = "";

                if (element.estatus == 0) {
                  colors = "red"; //Cancelado
                  element.estatus = "Canceled";
                } else if (element.estatus == 1) {
                  colors = "blue"; //Asigando
                  element.estatus = "Active";
                } else if (element.estatus == 2) {
                  colors = "green"; //terminado
                  element.estatus = "Finished";
                } else if (element.estatus == 3) {
                  colors = "orange"; //en proceso
                  element.estatus = "In progress";
                }
                events.push({
                  name: element.nombreTarea,
                  start: element.fechaInicio,
                  end: element.fechaFin,
                  color: colors,
                  descripcion: element.descripcion,
                  Comentario: element.Comentario,
                  empleado: element.nombre,
                  idEmpleado: element.idEmpleado,
                  idTarea: element.idTarea,
                });
                this.reloading = false;
                this.events = events;
              });
            }
            this.reloading = false;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    MostrarEncargadosXDepartamentos(idDepartamento) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 4);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));
      parametros.append("rol", sessionStorage.getItem("rol"));
      parametros.append("idDepartamento", idDepartamento);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.itemsEncargados = [];
            this.itemsEmpleados = [];
            this.empleadosfiltros = [];
            response.data.forEach((element) => {
              this.itemsEncargados.push(element);
              this.itemsEmpleados.push(element);
              this.empleadosfiltros.push(element);
            });
            this.empleadosfiltros.push({ idEmpleado: 0, nombre: "All" });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarDepartamentos() {
      let parametros = new URLSearchParams();

      parametros.append("accion", 1);

      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorDepartamentos.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.departamentos = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
          }.bind(this)
        );
    },
    ConsultarTareasPersonales() {
      let parametros = new URLSearchParams();

      parametros.append("accion", 26);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            response.data.forEach((element) => {
              if (element.estatus == 0) {
                element.estatus = "Canceled";
              } else if (element.estatus == 1) {
                element.estatus = "Active";
              } else if (element.estatus == 2) {
                element.estatus = "Finished";
              } else if (element.estatus == 3) {
                element.estatus = "In progress";
              }

              this.tareas = response.data;
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
          }.bind(this)
        );
    },
    getColor(estatus) {
      if (calories > 400) return "red";
      else if (calories > 200) return "orange";
      else return "green";
    },
    ConsultarTareas() {
      let parametros = new URLSearchParams();

      parametros.append("accion", 9);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            response.data.forEach((element) => {
              if (element.estatus == 0) {
                element.estatus = "Canceled";
              } else if (element.estatus == 1) {
                element.estatus = "Active";
              } else if (element.estatus == 2) {
                element.estatus = "Finished";
              } else if (element.estatus == 3) {
                element.estatus = "In progress";
              }

              this.tareas = response.data;
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
          }.bind(this)
        );
    },
    InizializarCombo() {},
    getColor(estatus) {
      if (estatus == "Active") return "blue";
      else if (estatus == "Finished") return "green";
      else if (estatus == "In progress") return "orange";
      else return "red";
    },
    ValidarSesion() {
      if (sessionStorage.getItem("uid")) {
      } else {
        this.$router.push("/").catch((failure) => {});
      }
    },
    LLenarTabla() {
      let id = this.selectTareas;
      let num = 0;
      let parametros = new URLSearchParams();
      if (id == 4) {
        parametros.append("accion", 21);
      } else {
        parametros.append("accion", 3);
      }

      parametros.append("idEmpleado", sessionStorage.getItem("uid"));
      parametros.append("id", id);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.loading = false;
            this.tareas = response.data;

            this.tareas.forEach((element) => {
              if (element.estatus == 0) {
                element.estatus = "Canceled";
              } else if (element.estatus == 1) {
                element.estatus = "Active";
              } else if (element.estatus == 2) {
                element.estatus = "Finished";
              } else if (element.estatus == 3) {
                element.estatus = "In progress";
              }
            });
            //console.log(response.data);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response.data);
          }.bind(this)
        );
    },
    itemRowBackground: function (item) {
      if (item.estatus == "Active") {
        return "style-1";
      } else if (item.estatus == "In progress") {
        return "style-2";
      } else if (item.estatus == "Finished") {
        return "style-3";
      } else if (item.estatus == "Canceled") {
        return "style-4";
      }
    },
    handleClick(value) {
      console.log(value);
      this.estatus = true;
      this.detalles = value; //El value contiene todos los valores de la tarea que se pasa por props al detalle de la tarea que es un componente
      this.dialogoDetalleTarea = true;
      this.LimpiarNotificacionChat(value);
    },
    LimpiarNotificacionChat(tarea) {
      var nombre = sessionStorage.getItem("uid");
      let ref = db
        .collection("noty" + nombre)
        .doc("notificacionTarea" + tarea.idTarea)
        .update({
          estatus: false,
        }); // Con este metodo actualizamos la notificacion a false.
    },
    AgregarComentario() {
      let parametros = new URLSearchParams();

      parametros.append("accion", 4);
      parametros.append("idTarea", this.idTarea);
      parametros.append("commit", this.commit);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.dialogoDetalleTarea = false;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ActualizarFechaFinTarea() {
      let parametros = new URLSearchParams();

      parametros.append("accion", 11);
      parametros.append("idTarea", this.idTarea);
      parametros.append("fechaFin", this.dates);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.selectTareas = { state: "en proceso", id: "3" };
            this.LLenarTabla(3);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    AlertaPrueba() {
      alert("alerta");
    },
    cerrarDialogo() {
      this.estatus = false;
      this.dialogoDetalleTarea = false; //este es la variable que define el estatus del componente FALSE/TRUE
      if ((this.selectTareas = [])) {
        if (this.SwitchTask) {
          this.ConsultarTareasPersonales();
        } else {
          this.ConsultarTareas();
        }
      } else {
        this.LLenarTabla();
      }

      //this.ConsultarTareas();
    },
    cerrarNuevaTarea() {
      this.ActualizarTabla();
      this.estatusNuevaTarea = false;
      this.dialogoNueva = false; //este es la variable que define el estatus del componente detalleTarea FALSE/TRUE
    },
    ActualizarTabla() {
      if (this.SwitchTask) {
        console.log("funciona? conusltar Tareas personales");
        this.ConsultarTareasPersonales();
      } else {
        console.log("funciona? conusltar Tareas ");
        this.ConsultarTareas();
      }
    },
    MostrarNotificacionesXTarea() {
      var nombre = sessionStorage.getItem("uid");
      moment.locales("es");
      let ref = db.collection("noty" + nombre); // con el order by ordena los datos de acuerdo al campo especificado.

      this.ConsultarTareas();
    },
  },
};
</script>