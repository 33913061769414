<template>
  <v-dialog v-model="estatus" @keydown.esc="CerrarDialogo" persistent max-width="600px">
    <v-card>
      <v-toolbar color="indigo" dark><h2>New note</h2></v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="12" xl="12">
            <v-text-field outlined v-model="datos.titulo" label="Title" required></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="12" xl="12">

            <v-textarea
              outlined
              name="input-7-1"
              label="Default style"
              v-model="datos.descripcion"
              hint="Hint text"
            ></v-textarea>

          </v-col>
        </v-row>
         <v-btn color="#EF6C00" v-if="datos.idNota" dark @click="confirmacion=true">DELETE</v-btn>
         <v-btn color="#EF6C00"  class="ml-5 "  dark @click="datos.idNota ? ActualizarNota() : CrearNota()"> {{datos.idNota ? 'Save' : 'Submit'}} </v-btn>
        
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="#EF6C00" dark @click="CerrarDialogo">Close</v-btn>
      </v-card-actions>
    </v-card>
     <v-dialog v-model="confirmacion" persistent max-width="25%">
      <v-tabs show-arrows background-color="#0D47A1" icons-and-text dark grow>
        <v-tabs-slider color="#0D47A1 "></v-tabs-slider>
        <v-tab>
          <v-icon right>mdi-delete</v-icon>
        </v-tab>
        <v-tab-item>
          <v-card>
            <v-card-text>
              <v-form lazy-validation>
                <v-row>
                  <v-card-text>
                    <v-row cols="12" sm="3" md="6">
                      <v-col
                        ><h3>
                          DELETE
                        </h3>
                        </v-col
                      >
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                      <v-btn
                      color="#EF6C00"
                      large
                      dark
                      @click="EliminarNota()"
                    >
                      OK
                    </v-btn>
                    <v-btn
                      large
                      color="#EF6C00"
                      @click="confirmacion = false"
                      dark
                    >
                      Cancel
                    </v-btn>

                  
                  </v-card-actions>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-dialog>
  </v-dialog>
</template>

<script>
export default {
  props: {
    datos: Array,
    estatus: Boolean
  },
  data() {
    return {
      confirmacion:false,
      
    };
  },
  mounted() {},
  methods: {
    ActualizarNota(){
      let parametros = new URLSearchParams();
      this.usuariosChat = [];
      parametros.append("accion", 4);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));
      parametros.append("titulo", this.datos.titulo);
      parametros.append("descripcion", this.datos.descripcion);
      parametros.append("idNota", this.datos.idNota);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorNotas.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.notas=response.data;
            alert("Success");
            this.CerrarDialogo();
            //console.log(this.usuariosChat);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    EliminarNota(){
      let parametros = new URLSearchParams();
      this.usuariosChat=[];
      parametros.append("accion", 3);
      parametros.append("idNota", this.datos.idNota);
  
      axios({
        method: "post",
        url:sessionStorage.getItem("ruta") + "/core/ControladorNotas.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.notas=response.data;
            alert("Success");
            this.CerrarDialogo();
            //console.log(this.usuariosChat);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
      CerrarDialogo(){
          this.$emit("cerrar");
      },
    CrearNota() {
      let parametros = new URLSearchParams();
      this.usuariosChat = [];
      parametros.append("accion", 2);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));
      parametros.append("titulo", this.datos.titulo);
      parametros.append("descripcion", this.datos.descripcion);
                                                             
      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorNotas.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.notas=response.data;
            alert("Success");
            this.CerrarDialogo();
            //console.log(this.usuariosChat);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    
  },
};
</script>

<style>
</style>