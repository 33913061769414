<template>
  <v-dialog v-model="estatus" @keydown.esc="cerrar" persistent max-width="300">
    <v-tabs show-arrows background-color="#0D47A1" icons-and-text dark grow>
      <v-tabs-slider color="#0D47A1 "></v-tabs-slider>
      <v-tab>
        <v-btn text x-large
          >Change Status<v-icon right>mdi-account-badge</v-icon></v-btn
        >
      </v-tab>
      <v-tab-item>
        <v-card>
          <v-card-text>
            <v-form ref="loginForm" v-model="valid" lazy-validation>
              <v-row>
                <v-card-text>
                  <v-row cols="12" sm="3" md="6">
                    <v-col cols="12">
                      <v-select
                        :items="items"
                        v-model="selectItem"
                        label="Status"
                        item-text="text"
                        item-value="id"
                        outlined
                      >
                        <template v-slot:selection="{ item, index }">
                          <h4 style="color: #000000">{{ item.text }}</h4>
                          <v-chip
                            text-color="#ffffff"
                            :color="SelectColor(item.id)"
                            small
                          ></v-chip>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-row cols="12" sm="3" md="6">
                    <v-col cols="6">
                      <v-btn color="#EF6C00" @click="cerrar" dark>
                        Cancel
                      </v-btn></v-col
                    >
                    <v-col cols="6">
                      <v-btn color="#EF6C00" @click="Logout(), cerrar()" dark>
                        SAVE
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-dialog>
</template>

<script>
import { db } from "../firebase";
export default {
  props: {
    estatus: Boolean,
    urlAvatar: {
      type: String,
      default: () => [],
    },
  },
  data: () => ({
    items: [
      { id: 1, text: "Online" },
      { id: 0, text: "Offline" },
      { id: 2, text: "Be right back" },
    ],
    selectItem: "",
  }),
  created() {
    this.ConsultarEstatus();
    this.selectItem = sessionStorage.getItem("statusConnection");
  },
  methods: {
    SelectColor(id) {
      switch (id) {
        case 0:
          return "#ff2d00";

          break;
        case 1:
          return "#3bbd09";
          break;
        case 2:
          return "#FFA300";
          break;

        default:
          break;
      }
    },
    Logout() {
      this.DesconectarUsuario();
      this.$emit("logout");

      let parametros = new URLSearchParams();
      parametros.append("accion", 2);
      parametros.append("idUsuario", sessionStorage.getItem("idUsuario"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/Authentication.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    cerrar() {
      this.$emit("cerrar");
    },
    DesconectarUsuario() {
      sessionStorage.setItem("statusConnection", this.selectItem);
      db.collection("conexiones")
        .doc(sessionStorage.getItem("uid"))
        .update({
          //Nodo padre del que queremos sacar los nodos hijos
          idEmpleado: sessionStorage.getItem("uid"),
          nombre: sessionStorage.getItem("fullname"),
          tipo: this.selectItem,
          url: this.urlAvatar,
          estatus: "1",
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    ConsultarEstatus() {
      let ref = db
        .collection("conexiones")
        .where("idEmpleado", "==", sessionStorage.getItem("uid"));

      ref.onSnapshot((querySnapshot) => {
        querySnapshot.forEach((element) => {
          console.log(element.data());
          this.selectItem = element.data().tipo;
          /*if (element.data().nombre != sessionStorage.getItem("fullname")) {
            if (element.data().tipo == 1) {
              this.usuariosChat.push({
                idEmpleado: element.data().idEmpleado,
                nombre: element.data().nombre,
                tipo: element.data().tipo,
                url: element.data().url,
              });
            }
          } else {
            this.urlAvatar = element.data().url;
          }*/
        });
      });
    },
  },
};
</script>

<style>
</style>