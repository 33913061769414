<template>
   <v-dialog v-model="estatus" width="350">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              {{ texto }}
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-btn color="indigo" @click="estatus = false" dark>
                ACEPTAR
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        texto: String,
        estatus:Boolean
    }

}
</script>

<style>

</style>