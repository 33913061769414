<template>
  <v-dialog
    v-model="estatus"
    @keydown.esc="AccionCerrar"
    persistent
    :max-width="isMobile?'100%':'40%'"
  >
    <v-tabs show-arrows background-color="#0D47A1" icons-and-text dark grow>
      <v-tabs-slider color="#0D47A1 "></v-tabs-slider>
      <v-tab>
        <v-btn text x-large
          >New task <v-icon right> mdi-page-next</v-icon>
        </v-btn>
      </v-tab>
      <v-tab-item>
        <v-card>
          <v-card-text>
            <v-form ref="loginForm" lazy-validation>
              <v-row>
                <v-card-text>
                  <v-col cols="12" sm="12">
                    <v-row>
                      <v-col v-if="tipoEmpleado == 1" cols="12" sm="12">
                        <v-select
                          :items="departamentos"
                          v-model="selectDepartamentos"
                          label="Departments"
                          outlined
                          small
                          @change="
                            MostrarEncargadosXDepartamentos(selectDepartamentos)
                          "
                          color="indigo"
                          item-value="IdDepartamento"
                          item-text="Nombre"
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col v-if="tipoEmpleado == 1" cols="12" sm="12">
                        <v-combobox
                          v-model="selectEncargados"
                          label="Participant"
                          outlined
                          :items="itemsEncargados"
                          item-value="idEmpleado"
                          item-text="nombre"
                        ></v-combobox>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-text-field
                          label="Title"
                          placeholder="Enter a title for this task"
                          outlined
                          v-model="nombreTarea"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-textarea
                          outlined
                          name="input-7-4"
                          label="Description"
                          placeholder="Enter a description for this task"
                          v-model="descripcion"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-text-field
                          v-model="dates"
                          label="Date range"
                          prepend-icon="mdi-calendar"
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="col text-center" cols="3" sm="3">
                        <v-btn
                          width="100%"
                          x-large
                          color="#EF6C00"
                          @click="dialogCalendar = true"
                          dark
                        >
                          <v-icon left>mdi-calendar</v-icon>
                          Set date
                        </v-btn>
                      </v-col>
                      <v-col cols="3">
                        <v-btn
                          width="100%"
                          x-large
                          :color="isPriority ? '#ff2d00' : '#b1b1b1'"
                          @click="
                            isPriority
                              ? (isPriority = false)
                              : (isPriority = true)
                          "
                          dark
                        >
                          <v-icon left>mdi-alert</v-icon>
                          Priority
                        </v-btn>
                      </v-col>
                      <v-col class="col text-center" cols="12" sm="6">
                        <v-btn
                          x-large
                          v-if="tipoEmpleado == 1"
                          width="100%"
                          :color="
                            selectEncargados != 0 &&
                            selectDepartamentos != 0 &&
                            descripcion != '' &&
                            nombreTarea != '' &&
                            this.dates != ''
                              ? 'green'
                              : '#b1b1b1'
                          "
                          dark
                          @click="EnviarTarea(), ActualizarTabla()"
                        >
                          <v-icon left>mdi-content-save</v-icon>
                          Save
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-card-text>
                <v-card-actions class="col text-right">
                  <v-flex>
                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-btn
                          x-large
                          color="#EF6C00"
                          dark
                          @click="
                            AccionCerrar(),
                              (estatusChat = false),
                              dateRangeText()
                          "
                        >
                          Close
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-flex>
                </v-card-actions>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
    <v-dialog v-model="dialogCalendar" hide-overlay persistent width="350">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-date-picker
                v-model="dates"
                color="indigo"
                range
              ></v-date-picker>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-btn color="indigo" @click="dialogCalendar = false" dark>
                OK
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { db } from "../firebase";
export default {
  props: {
    datos: Array,
    idEmpleado: String,
    estatus: Boolean,
  },
  data: () => ({
    isMobile: false,
    isPriority: false,
    departamentos: [],
    selectDepartamentos: [],
    itemsEncargados: [],
    selectEncargados: [],
    nombreTarea: "",
    descripcion: "",
    dialogCalendar: false,
    dates: [],
    fechas: "",
    tipoEmpleado: 0,
  }),
  created() {},
  mounted() {
    this.tipoEmpleado = sessionStorage.getItem("tipo");

    if (sessionStorage.getItem("tipo") == 2) {
      this.selectEncargados.push("1");
      this.selectDepartamentos.push("1");
    }

    this.ConsultarDepartamentos();
  },
  computed: {
    dateRangeText() {
      this.fechas = this.dates.join(" ~ ");
    },
  },
  methods: {
       ValidarDispositivo() {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        sessionStorage.setItem("isMobile", true);
        this.isMobile = true;
      } else {
        sessionStorage.setItem("isMobile", false);
        this.isMobile = false;
      }
    },
    EditarTareaLista(selectedEvent) {
      console.log(selectedEvent);
      this.edicion = true;
      this.nombreTarea = selectedEvent.nombreTarea;
      this.descripcion = selectedEvent.descripcion;
      this.dates.push(selectedEvent.fechaInicio);
      this.dates.push(selectedEvent.fechaFin);
      this.selectEncargados = {
        nombre: selectedEvent.nombre,
        idEmpleado: selectedEvent.idEmpleado,
      };
      this.btn_Agregar_Actualizar = "Edit";
    },
    EliminarTarea(selectedEvent) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 8);
      parametros.append("idTarea", selectedEvent.idTarea);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            if (response.data == true) {
              this.aviso = "Delete task";
              this.dialogAviso = true;
              //this.MostrarTareasXEmpleado();
            }
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    LimpiarCajas() {
      this.nombreTarea = "";
      this.descripcion = "";
      this.dates = [];
    },
    ConsultarDepartamentos() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 1);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorDepartamentos.php",
        data: parametros,
      })
        .then(
          function (response) {
            //this.departamentos.push({ idDepartamento: 0, Nombre: "All" });
            response.data.forEach((element) => {
              this.departamentos.push(element);
              this.selectDepartamentos.push(element);
            });

            this.selectDepartamentos = 0;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    EnviarTarea() {
      console.log(this.isPriority ? "1" : "0");
      if (
        this.selectEncargados != 0 &&
        this.selectDepartamentos != 0 &&
        this.descripcion != "" &&
        this.nombreTarea != "" &&
        this.dates != ""
      ) {
        let parametros = new URLSearchParams();
        let editor = false;

        this.loading = true;

        parametros.append("id", sessionStorage.getItem("uid"));

        if (this.edicion) {
          parametros.append("accion", 10);
          parametros.append("idTarea", this.selectedEvent.idTarea);

          editor = true;
        } else {
          parametros.append("accion", 1);
        }
        parametros.append("idDepartamento", this.selectDepartamentos);
        parametros.append("idEmpleado", this.selectEncargados.idEmpleado);

        if (this.dates.length > 1) {
          this.fechaInicio = this.dates[0];
          this.fechaFin = this.dates[1];
        } else {
          this.fechaInicio = this.dates[0];
          this.fechaFin = this.dates[0];
        }

        var prioridad = 0;
        if (this.isPriority == 1) {
          prioridad = "1";
        }

        parametros.append("nombreTarea", this.nombreTarea);
        parametros.append("descripcion", this.descripcion);
        parametros.append("fechaInicio", this.fechaInicio);
        parametros.append("fechaFin", this.fechaFin);
        parametros.append("prioridad", prioridad);
        parametros.append("fechaFin", this.fechaFin);
        parametros.append("urgente", prioridad);
        axios({
          method: "post",
          url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
          data: parametros,
        })
          .then(
            function (response) {
              console.log("respuesta: " + response.data);
              this.LimpiarCajas();
              this.dialogAviso = true;
              if (editor) {
                this.aviso = "Save succeful";
                this.btn_Agregar_Actualizar = "Send";
              } else {
                this.aviso = "Save succeful";
                this.Enviarnoty(
                  this.selectEncargados.idEmpleado,
                  response.data
                );
              }
              // location.reload();

              // this.MostrarTareasXEmpleado();
              this.loading = false;
            }.bind(this)
          )
          .catch(
            function (response) {
              //handle error
              console.log(response);
            }.bind(this)
          );
      } else {
        alert("Debe llenar todos los datos");
      }
    },
    EnviarTareaPersonal() {
      if (
        this.descripcion != "" &&
        this.nombreTarea != "" &&
        this.dates != ""
      ) {
        let parametros = new URLSearchParams();
        let editor = false;

        this.loading = true;

        parametros.append("id", sessionStorage.getItem("uid"));

        parametros.append("accion", 1);
        parametros.append("idEmpleado", sessionStorage.getItem("uid"));
        parametros.append(
          "idDepartamento",
          sessionStorage.getItem("idDepartamento")
        );

        if (this.dates.length > 1) {
          this.fechaInicio = this.dates[0];
          this.fechaFin = this.dates[1];
        } else {
          this.fechaInicio = this.dates[0];
          this.fechaFin = this.dates[0];
        }

        parametros.append("nombreTarea", this.nombreTarea);
        parametros.append("descripcion", this.descripcion);
        parametros.append("fechaInicio", this.fechaInicio);
        parametros.append("fechaFin", this.fechaFin);

        axios({
          method: "post",
          url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
          data: parametros,
        })
          .then(
            function (response) {
              console.log("respuesta: " + response.data);
              this.LimpiarCajas();
              this.dialogAviso = true;
              if (editor) {
                this.aviso = "Save succeful";
                this.btn_Agregar_Actualizar = "Send";
              } else {
                this.aviso = "Save succeful";
                this.Enviarnoty(
                  this.selectEncargados.idEmpleado,
                  response.data
                );
              }
              // location.reload();

              // this.MostrarTareasXEmpleado();
              this.loading = false;
            }.bind(this)
          )
          .catch(
            function (response) {
              //handle error
              console.log(response);
            }.bind(this)
          );
      } else {
        alert("You must fill in all the data");
      }
    },
    Enviarnoty(uid, idTarea) {
      var nombre = "";
      var unsubcribe = db
        .collection("noty" + uid)
        .doc("notificacionTarea" + idTarea)
        .set({
          //Nodo padre del que queremos sacar los nodos hijos
          name: nombre,
          type: 1,
          message: "New Task",
          time: Date.now(),
          idTarea: idTarea,
          estatus: true,
          notificado: false,
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error

      unsubcribe();
    },
    MostrarEncargadosXDepartamentos(idDepartamento) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 4);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));
      parametros.append("rol", sessionStorage.getItem("rol"));
      parametros.append("idDepartamento", idDepartamento);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.itemsEncargados = [];
            this.itemsEmpleados = [];
            this.empleadosfiltros = [];
            response.data.forEach((element) => {
              this.itemsEncargados.push(element);
              this.itemsEmpleados.push(element);
              this.empleadosfiltros.push(element);
            });
            this.empleadosfiltros.push({ idEmpleado: 0, nombre: "All" });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },

    MostrarEncargados() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 1);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.itemsEncargados = [];
            this.selectEncargados = [];
            response.data.forEach((element) => {
              this.itemsEncargados.push(element);
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    AccionCerrar() {
      this.$emit("cerrar");
    },
    ActualizarTabla() {
      this.$emit("update");
    },
  },
};
</script>

<style>
</style>