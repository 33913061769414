var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-row',{staticClass:"mt-16 ml-4 mr-4"},[_c('v-col',[_c('v-col',_vm._l((_vm.btns2),function(ref,index){
var tex = ref[0];
var rounded = ref[1];
return _c('v-menu',{key:tex,staticClass:"hidden-sm-and-down",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-card',_vm._g(_vm._b({staticClass:"rounded-xl",attrs:{"height":"70px","color":_vm.taxes ? '#ef6c00' : '#FFFFFF',"elevation":"16"},on:{"click":function($event){(_vm.taxes = true),
                    (_vm.company = false),
                    (_vm.bookkeeping = false),
                    (_vm.payroll = false)}}},'v-card',attrs,false),on),[_c('v-row',[_c('v-btn',{staticClass:"mt-5 hidden-sm-and-down dark--text",attrs:{"text":"","block":""}},[_c('v-icon',{attrs:{"color":_vm.taxes ? '#FFFFFF' : '#0d47a1',"large":"","left":""}},[_vm._v("mdi-cash")]),_c('h3',{staticClass:"ml-2",style:(_vm.taxes ? 'color:#FFFFFF' : 'color:#0d47a1')},[_vm._v(" Payroll ")])],1)],1)],1)]}}],null,true)},[(_vm.departamentos.payroll)?_c('v-list',_vm._l((_vm.toolsPayroll),function(items){return _c('v-list-item',{key:items,attrs:{"link":""},on:{"click":function($event){return _vm.EnviarAccion(items)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(items)}})],1)}),1):_c('v-list',_vm._l((_vm.toolsPayrollBook),function(item){return _c('v-list-item',{key:item,attrs:{"link":""},on:{"click":function($event){return _vm.EnviarAccion(item)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1)}),1)],1)}),1)],1),_c('v-col',[_c('v-col',_vm._l((_vm.btns1),function(ref,index){
                    var texto = ref[0];
                    var rounded = ref[1];
return _c('v-menu',{key:texto,staticClass:"hidden-sm-and-down",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var attrs = ref.attrs;
                    var on = ref.on;
return [_c('v-card',_vm._g(_vm._b({staticClass:"rounded-xl",attrs:{"elevation":"16","color":_vm.bookkeeping ? '#EF6C00' : '#FFFFFF',"height":"70px"},on:{"click":function($event){(_vm.taxes = false),
                    (_vm.company = false),
                    (_vm.bookkeeping = true),
                    (_vm.payroll = false)}}},'v-card',attrs,false),on),[_c('v-row',[_c('v-btn',{staticClass:"mt-5 hidden-sm-and-down dark--text",attrs:{"block":"","text":""}},[_c('v-icon',{attrs:{"color":_vm.bookkeeping ? '#FFFFFF' : '#0d47a1',"large":"","left":""}},[_vm._v("mdi-book")]),_c('h3',{staticClass:"ml-2",style:(_vm.bookkeeping ? 'color:#FFFFFF' : 'color:#0d47a1')},[_vm._v(" Bookkeeping ")])],1)],1)],1)]}}],null,true)},[_c('v-list',{attrs:{"if":_vm.departamentos.bookkepping}},_vm._l((_vm.toolsBookkeeping),function(item){return _c('v-list-item',{key:item,attrs:{"link":""},on:{"click":function($event){return _vm.EnviarAccion(item)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1)}),1)],1)}),1)],1),_c('v-col',[_c('v-col',[_c('v-card',_vm._g(_vm._b({staticClass:"rounded-xl",attrs:{"color":_vm.payroll ? '#EF6C00' : '#FFFFFF',"elevation":"16","height":"70px"},on:{"click":function($event){_vm.EnviarAccion('Taxes Return'),
                (_vm.taxes = false),
                (_vm.company = false),
                (_vm.bookkeeping = false),
                (_vm.payroll = true)}}},'v-card',_vm.attrs,false),_vm.on),[_c('v-row',[_c('v-btn',{staticClass:"mt-5 hidden-sm-and-down dark--text",attrs:{"color":_vm.taxes ? '#0D47A1' : '#FFFFFF',"block":"","text":""}},[_c('v-icon',{attrs:{"color":_vm.payroll ? '#FFFFFF' : '#0d47a1',"large":"","left":""}},[_vm._v("mdi-sale")]),_c('h3',{staticClass:"ml-2",style:(_vm.payroll ? 'color:#FFFFFF' : 'color:#0d47a1')},[_vm._v(" Taxes ")])],1)],1)],1)],1)],1),_c('v-col',[_c('v-col',[_c('v-card',_vm._g(_vm._b({staticClass:"rounded-xl",attrs:{"color":_vm.company ? '#EF6C00' : '#FFFFFF',"elevation":"16","height":"70px"},on:{"click":function($event){_vm.EnviarAccion('Folders'),
                (_vm.taxes = false),
                (_vm.company = true),
                (_vm.bookkeeping = false),
                (_vm.payroll = false)}}},'v-card',_vm.attrs,false),_vm.on),[_c('v-row'),_c('v-btn',{staticClass:"mt-5 hidden-sm-and-down",attrs:{"color":_vm.company ? '#0D47A1' : '#FFFFFF',"block":"","text":""}},[_c('v-icon',{attrs:{"color":_vm.company ? '#FFFFFF' : '#0d47a1',"large":"","left":""}},[_vm._v("mdi-domain")]),_c('h3',{staticClass:"ml-2",style:(_vm.company ? 'color:#FFFFFF' : 'color:#0d47a1')},[_vm._v(" Companies ")])],1)],1),_c('v-list',{attrs:{"if":_vm.departamentos.loans}},_vm._l((_vm.toolsLoands),function(item){return _c('v-list-item',{key:item,attrs:{"link":""},on:{"click":function($event){return _vm.EnviarAccion(item)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1)}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }