<template>
  <v-form class="mt-13">
    <v-row class="ml-2 mr-2">
      <v-col cols="12" class="">
        <v-row>
          <v-col cols="12">
            <v-select
              :items="itemsTareas"
              v-model="selectTareas"
              label="Task status"
              outlined
              color="indigo"
              @change="LLenarTabla()"
              item-text="state"
              item-value="id"
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-chip dark class="ma-2" color="blue"> Active </v-chip>
            <v-chip dark class="ma-2" color="green"> Finished </v-chip>
            <v-chip dark class="ma-2" color="orange"> In progress </v-chip>
            <v-chip dark class="ma-2" color="red"> Canceled </v-chip>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4"
            ><v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field
          ></v-col>
          <v-col cols="12" xs="12" sm="12">
            <v-data-table
              :search="search"
              :headers="headers"
              :items="tareas"
              class="elevation-24"
              @click:row="handleClick"
            >
              <template
                class="hidden-sm-and-down"
                v-slot:[`item.nombreTarea`]="{ item }"
              >
                <v-badge
                  class="mr-10 mt-5"
                  left
                  color="red"
                  dot
                  v-if="item.fechaActual == 1 && item.estatus != 'finished'"
                >
                  <v-chip :color="getColor(item.estatus)" dark text>
                    {{ item.nombreTarea }}
                  </v-chip>
                </v-badge>

                <v-badge v-else color="#ffffff" class="mr-10 mt-5" left dot>
                  <v-chip :color="getColor(item.estatus)" dark text>
                    {{ item.nombreTarea }}
                  </v-chip>
                </v-badge>

                <!--
                  <v-col >
                    <v-badge
                      v-if="item.fechaActual == 1 && item.estatus != 'finished'"
                      class="mr-10 mt-5"
                      left
                      dot
                      color="red"
                    >
                      <v-badge class="mr-10 mt-5" color="#ffffff">
                        <v-chip :color="getColor(item.estatus)" dark text>
                          {{ item.nombreTarea }}
                        </v-chip>
                      </v-badge>
                    </v-badge>
                    <v-badge
                      class="mr-10 mt-5"
                      left
                      dot
                      v-else
                      color="#ffffff"
                      
                    >
                      <v-badge class="mr-10 mt-5" color="#ffffff">
                        <v-chip :color="getColor(item.estatus)" dark text>
                          {{ item.nombreTarea }}
                        </v-chip>
                      </v-badge>
                    </v-badge>
                  </v-col> -->
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogoColaborador" width="400">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12" align="center" sm="12" md="12">
              <v-select
                :items="itemsTareas"
                v-model="selectTareas"
                label="Tareas"
                outlined
                color="indigo"
                @change="LLenarTabla(selectTareas)"
                item-text="state"
                item-value="id"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <DetalleTarea
      v-if="estatus"
      :estatus="dialogoDetalleTarea"
      :datos="detalles"
      @cerrar="cerrarDialogo"
    />

    <NuevTarea
      :datos="datosTarea"
      :idEmpleado="idEmpleado"
      v-if="dialogoNueva"
      @cerrar="cerrarNuevaTarea"
      :estatus="estatusNuevaTarea"
      @update="ActualizarTabla"
    />
    <v-dialog v-model="dialogCalendar" persistent width="350">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12" align="center" sm="12" md="12">
              <h3>Fecha estimada para finalizar la tarea</h3>
            </v-col>
            <v-col cols="12" align="center" sm="12" md="12">
              <v-date-picker
                v-model="dates"
                color="indigo"
                elevation="16"
              ></v-date-picker>
            </v-col>

            <v-col cols="12" align="center" sm="12" md="12">
              <v-btn
                color="orange"
                x-large
                @click="ActualizarFechaFinTarea(), (dialogCalendar = false)"
                dark
              >
                ACEPTAR
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="100"
        >Loading...</v-progress-circular
      >
    </v-overlay>
  </v-form>
</template>

<script>
var time = new Date();
import Titulo from "../components/Titulo";
import Aviso from "../components/Aviso";
import DetalleTarea from "../components/DetalleTarea";
import NuevTarea from "../components/nuevaTarea";
import ChatPersonal from "../components/ChatPersonal";
import { db } from "../firebase";

import EventBus from "../bus";
var month = time.getUTCMonth() + 1; //months from 1-12

export default {
  components: {
    Titulo,
    Aviso,
    DetalleTarea,
    NuevTarea,
    ChatPersonal,
  },
  props: {
      
  },
  data() {
    return {
      search: "",
      dialogoNueva: false,
      estatus: false,
      dialogCalendar: false,
      dialogoColaborador: false,
      overlay: false,
      dates: "",
      fechaActual: new Date(Date.now()),
      fechaInicio: null,
      fechaFin: null,
      fechaCreacion: null,
      loading: false,
      detalles: [],
      datosTarea: [],
      headers: [
        {
          text: "Task",
          align: "start",
          sortable: false,
          value: "nombreTarea",
        },
        { text: "Description", value: "descripcion" },
        //{ text: "Status", value: "estatus" },
      ],
      tareas: [],
      dialogoDetalleTarea: false,
      commit: "",
      idTarea: "",
      itemsTareas: [
        { state: "All tasks", id: "4" },
        { state: "Active", id: "1" },
        { state: "In progress", id: "3" },
        { state: "Finished", id: "2" },
        { state: "Canceled", id: "0" },
      ],
      selectTareas: [],
      parents: [],
      idEmpleado: "",
      estatusNuevaTarea: false,
      tipoEmpleado: 0,
    };
  },
  computed: {
    activeFab() {
      switch (this.tabs) {
        case "one":
          return { class: "purple", icon: "account_circle" };
        case "two":
          return { class: "red", icon: "edit" };
        case "three":
          return { class: "green", icon: "keyboard_arrow_up" };
        default:
          return {};
      }
    },
  },
  watch: {
    top(val) {
      this.bottom = !val;
    },
    right(val) {
      this.left = !val;
    },
    bottom(val) {
      this.top = !val;
    },
    left(val) {
      this.right = !val;
    },
  },
  mounted() {

    this.overlay = false;
    this.tipoEmpleado = sessionStorage.getItem("tipo");
    this.idEmpleado = sessionStorage.getItem("uid");

    //this.LLenarTabla(1);
    //this.ValidarSesion();
    this.InizializarCombo();
    this.ConsultarTareas();

    
    EventBus.$emit("hint-taskcenter");

  },
  methods: {
    getColor(estatus) {

      if (calories > 400) return "red";
      else if (calories > 200) return "orange";
      else return "green";
      
    },
    ConsultarTareas() {
      let parametros = new URLSearchParams();

      parametros.append("accion", 9);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            response.data.forEach((element) => {
              if (element.estatus == 0) {
                element.estatus = "Canceled";
              } else if (element.estatus == 1) {
                element.estatus = "Active";
              } else if (element.estatus == 2) {
                element.estatus = "Finished";
              } else if (element.estatus == 3) {
                element.estatus = "In progress";
              }

              this.tareas = response.data;
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
          }.bind(this)
        );
    },
    InizializarCombo() {},
    getColor(estatus) {
      if (estatus == "Active") return "blue";
      else if (estatus == "Finished") return "green";
      else if (estatus == "In progress") return "orange";
      else return "red";
    },
    ValidarSesion() {
      if (sessionStorage.getItem("uid")) {
      } else {
        this.$router.push("/").catch((failure) => {});
        alert("Sesion expiro");
      }
    },
    LLenarTabla() {
      let id = this.selectTareas;
      let num = 0;
      let parametros = new URLSearchParams();
      if (id == 4) {
        parametros.append("accion", 21);
      } else {
        parametros.append("accion", 3);
      }

      parametros.append("idEmpleado", sessionStorage.getItem("uid"));
      parametros.append("id", id);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.loading = false;
            this.tareas = response.data;

            this.tareas.forEach((element) => {
              if (element.estatus == 0) {
                element.estatus = "Canceled";
              } else if (element.estatus == 1) {
                element.estatus = "Active";
              } else if (element.estatus == 2) {
                element.estatus = "Finished";
              } else if (element.estatus == 3) {
                element.estatus = "In progress";
              }
            });
            //console.log(response.data);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response.data);
          }.bind(this)
        );
    },
    itemRowBackground: function (item) {
      if (item.estatus == "Active") {
        return "style-1";
      } else if (item.estatus == "In progress") {
        return "style-2";
      } else if (item.estatus == "Finished") {
        return "style-3";
      } else if (item.estatus == "Canceled") {
        return "style-4";
      }

      //return item.estatus == 'Active' && item.fechaFin > this.fechaActual ? 'style-1' : 'style-2'
    },

    handleClick(value) {
      this.estatus = true;
      this.detalles = value; //El item contiene todos los valores de la tarea que se pasa por props al detalle de la tarea que es un componente
      this.dialogoDetalleTarea = true;
    },
    AgregarComentario() {
      let parametros = new URLSearchParams();

      parametros.append("accion", 4);
      parametros.append("idTarea", this.idTarea);
      parametros.append("commit", this.commit);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.dialogoDetalleTarea = false;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ActualizarFechaFinTarea() {
      let parametros = new URLSearchParams();

      parametros.append("accion", 11);
      parametros.append("idTarea", this.idTarea);
      parametros.append("fechaFin", this.dates);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.selectTareas = { state: "en proceso", id: "3" };
            this.LLenarTabla(3);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    AlertaPrueba() {
      alert("alerta");
    },
    cerrarDialogo() {
      this.estatus = false;
      this.dialogoDetalleTarea = false; //este es la variable que define el estatus del componente FALSE/TRUE
      if ((this.selectTareas = [])) {
        this.ConsultarTareas();
      } else {
        this.LLenarTabla();
      }

      //this.ConsultarTareas();
    },
    cerrarNuevaTarea() {
      this.ConsultarTareas();
      this.estatusNuevaTarea = false;
      this.dialogoNueva = false; //este es la variable que define el estatus del componente detalleTarea FALSE/TRUE
    },
    ActualizarTabla() {
      this.ConsultarTareas();
    },
    MostrarNotificacionesXTarea() {
      var nombre = sessionStorage.getItem("uid");
      moment.locales("es");
      let ref = db.collection("noty" + nombre); // con el order by ordena los datos de acuerdo al campo especificado.

      this.ConsultarTareas();
    },
  },
};
</script>




<style>
.style-1 {
  background-color: rgb(8, 203, 238);
}
.style-2 {
  background-color: rgb(248, 135, 6);
}
.style-3 {
  background-color: rgb(72, 201, 33);
}
.style-4 {
  background-color: rgb(248, 6, 6);
}
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
</style>