<template>
  <div class="text-center">
    <v-dialog persistent v-model="estatus" :width="isMobile ? '100%' : '50%'">
      <v-card>
        <v-card-title class="headline indigo">
          <h4>Files Excel</h4>
        </v-card-title>

        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="archivos"
            :items-per-page="5"
            class="elevation-5"
          >
            <template v-slot:[`item.download`]="{ item }">
              <v-icon
                color="indigo"
                class="mr-2"
                @click="downloadItem(item.message)"
              >
                mdi-download
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#EF6C00" dark @click="carrar"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { db, storage } from "../firebase";

export default {
  props: {
    nodo: {
      type: String,
      default: () => "",
    },
    estatus: Boolean,
  },
  data: () => ({
    isMobile: false,
    headers: [
      {
        text: "File",
        align: "start",
        sortable: false,
        value: "message",
      },
      { text: "Download", value: "download" },
    ],
    archivos: [],
  }),
  created() {
    this.ValidarDispositivo();
    this.ConsultarArchivos();
  },
  methods: {
    ValidarDispositivo() {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        sessionStorage.setItem("isMobile", true);
        this.isMobile = true;
      } else {
        sessionStorage.setItem("isMobile", false);
        this.isMobile = false;
      }
    },
    carrar() {
      this.$emit("cerrar");
    },
    ConsultarArchivos() {
      moment.locales("es");

      let ref = db
        .collection(this.nodo)
        .where("download", "==", true)
        .orderBy("time", "desc"); // con el order by ordena los datos de acuerdo al campo especificado.

      ref.onSnapshot((querySnapshot) => {
        //this.archivos = querySnapshot;

        querySnapshot.forEach((element) => {
          console.log(element);
          //forEach que recorre toda la coleccion de mensajes
          this.archivos.push({
            name: element.data().name,
            message: element.data().message,
            me: element.data().me,
            time: moment(element.data().time).format("lll"),
          });
        });
      });
    },
    downloadItem(name) {
      storage
        .ref()
        .child("Archivos" + this.nodo)
        .child(name)
        .getDownloadURL()
        .then(function (url) {
          // `url` is the download URL for 'images/stars.jpg'
          axios({
            method: "get",
            url: url,
            responseType: "blob",
            "Access-Control-Allow-Origin": "*",
          })
            .then(
              function (response) {
                console.log(response.data);
                const data = window.URL.createObjectURL(response.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = name;
                link.click();
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        })
        .catch(function (error) {
          console.log(error);
          // Handle any errors
        });
    },
  },
};
</script>

<style>
</style>