<template>
  <v-dialog v-model="estatus" persistent :max-width="isMobile?'100%':'40%'">
    <v-tabs show-arrows background-color="#0D47A1" icons-and-text dark grow>
      <v-tabs-slider color="#0D47A1 "> </v-tabs-slider>
      <v-btn
        class="mt-4 ml-1"
        dark
        text
        @click="(estatus = false), AccionCerrar()"
      >
        <v-icon large>mdi-close</v-icon>
      </v-btn>
      <v-tab>
        <v-btn text x-large>Links <v-icon right> mdi-link</v-icon> </v-btn>
      </v-tab>
      <v-tab-item>
        <v-card>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="links"
              sort-by="calories"
              class="elevation-1"
              :search="search"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title
                    ><v-icon right> mdi-link</v-icon></v-toolbar-title
                  >
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-btn
                    color="#EF6C00"
                    dark
                    class="mb-2"
                    @click="
                      detalleLink ? (detalleLink = false) : (detalleLink = true)
                    "
                  >
                    <v-icon v-if="!detalleLink" class="mr-2"> mdi-eye </v-icon>

                    <v-icon v-else class="mr-2"> mdi-eye-off </v-icon>

                    Link
                  </v-btn>
                  <v-spacer></v-spacer>

                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    class="mr-10"
                    single-line
                    hide-details
                  ></v-text-field>

                  <v-dialog v-model="dialog" max-width="50%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        New link
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                outlined
                                v-model="editedItem.nombre"
                                label="Name"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                              <v-textarea
                                outlined
                                v-model="editedItem.ruta"
                                label="Link"
                              ></v-textarea>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">
                          Cancel
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="save()">
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5"
                        >Are you sure you want to delete this link ?
                      </v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItemConfirm"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>

              <template v-slot:item.ruta="{ item }">
                {{ item.ruta }}
              </template>

              <template v-slot:item.actions="{ item }">
                <v-icon class="mr-2" @click="editItem(item)">
                  mdi-pencil
                </v-icon>

                <v-icon @click="deleteItem(item)"> mdi-delete </v-icon>
              </template>

              <template v-slot:item.redirect="{ item }">
                <v-icon class="mr-2" @click="AbrirRuta(item)">
                  mdi-page-next
                </v-icon>
              </template>

              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize"> Reset </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-dialog>
</template>

<script>
import { db } from "../firebase";
export default {
  props: {
    // @keydown.esc="AccionCerrar"
    estatus: Boolean,
  },
  data: () => ({
    isMobile: false, 
    search: "",
    detalleLink: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "nombre",
      },
      { text: "Redirect", value: "redirect", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    links: [],
    editedIndex: -1,
    editedItem: {
      idRuta: "",
      name: "",
      ruta: "",
    },
    defaultItem: {
      idRuta: "",
      name: "",
      ruta: "",
    },
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Link" : "Edit Item";
    },
  },

  watch: {
    detalleLink(val) {
      if (this.detalleLink) {
        this.headers = [
          {
            text: "Name",
            align: "start",
            sortable: false,
            value: "nombre",
          },
          { text: "Link", value: "ruta", sortable: false },
          { text: "Redirect", value: "redirect", sortable: false },
          { text: "Actions", value: "actions", sortable: false },
        ];
      } else {
        this.headers = [
          {
            text: "Name",
            align: "start",
            sortable: false,
            value: "nombre",
          },
          { text: "Redirect", value: "redirect", sortable: false },
          { text: "Actions", value: "actions", sortable: false },
        ];
      }
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
    this.ConsultarRutas();
  },

  methods: {
       ValidarDispositivo() {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        sessionStorage.setItem("isMobile", true);
        this.isMobile = true;
      } else {
        sessionStorage.setItem("isMobile", false);
        this.isMobile = false;
      }
    },
    ModificarRuta() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 4);
      parametros.append("idRuta", this.editedItem.idRuta);
      parametros.append("nombre", this.editedItem.nombre);
      parametros.append("ruta", this.editedItem.ruta);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorRuta.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.ConsultarRutas();
            this.close();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    EliminarRuta() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 3);
      parametros.append("idRuta", this.editedItem.idRuta);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorRuta.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.ConsultarRutas();
            this.closeDelete();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    AgregarRuta() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 2);
      parametros.append("nombre", this.editedItem.nombre);
      parametros.append("ruta", this.editedItem.ruta);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorRuta.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.ConsultarRutas();
            this.close();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    AccionCerrar() {
      this.$emit("cerrar");
    },
    AbrirRuta(item) {
      //window.location = item.ruta;
      window.open(item.ruta);
    },
    ConsultarRutas() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 1);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorRuta.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.links = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    initialize() {},

    editItem(item) {
      this.editedIndex = this.links.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.links.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.links.splice(this.editedIndex, 1);
      this.EliminarRuta();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.links[this.editedIndex], this.editedItem);

        this.ModificarRuta();
      } else {
        this.links.push(this.editedItem);
        this.AgregarRuta();
      }
      this.close();
    },
  },
};
</script>

<style>
</style>