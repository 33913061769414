<script>
/*
 * Developer: Angel Omar Martinez Campos
 * Clase principal
 * contiene Login, registro de clientes, barra de herramientas, personal chat, notas
 */

import Chat from "vue-beautiful-chat"; // libreria de Personal chat
import Vue from "vue";
import VueChatScroll from "vue-chat-scroll"; // libreria para el scrooll del chat

Vue.use(Chat);
Vue.use(VueChatScroll);

import DetalleTarea from "../components/DetalleTarea"; //Componente para mostrar el detalle de la tarea
import Download from "../components/DescargarExcel"; //Componente para descargar el archivo de excel ya modificado
import Confirmacion from "../components/ConfirmacionSession"; //Componente del dialogo de confimacion de cierre de session
import PanelEstatus from "../components/PanelEstatus";
import { db, storage, data } from "../firebase"; // objetos para trabajar con storage y firestore(base de datos en realtime) ambas herramientas de firebase
import moment from "moment"; // libreria para dar formato a la hora y fecha
import Notas from "../components/Notas"; // Componente de notas
import toolsServices from "../components/toolsServices";
import EventBus from "../bus"; // evento bus para comunicacion entre componentes
import Clientes from "./Clientes.vue";
import TaskCenter from "../components/TaskCenter.vue";
import Rutas from "../components/Rutas.vue";
import { stringify } from "qs"; //Vuelve string los objetos para mandar los valores al back end ejemplo JSON.stringify(this.array);
import chatFlotante from "../components/ChatFlotanteEmpleado.vue";
import Fecha from "./fecha.class";
import otpInput from "../components/tools/otpInput/otp_input.component";

var time = new Date();
var size = "";

moment.locales("es");

export default {
  components: {
    otpInput,
    DetalleTarea,
    Download,
    Confirmacion,
    Notas,
    toolsServices,
    Clientes,
    TaskCenter,
    Rutas,
    chatFlotante,
    PanelEstatus,
  },
  delimiters: ["${", "}"],
  data: () => ({
    opcionToken: 0,
    selectMycompany: 0,
    myCompanies: [],
    isBtnAvaible: false,
    isDialogConfirmDeleteImageEvent: false,
    idImagenEvento: "",
    isChatAvaible: false,
    RGBAZUL: "#0D47A1",
    ChatCliente: "",
    chatCliente: "",
    idClienteChat: "",
    messagesClientes: 0,
    isOpenChatCliente: false,
    notificacionesChatCliente: [],
    isOpenListChatCliente: false,
    nombreCliente: sessionStorage.getItem("cliente"),
    isOpenDialogTokenForgetPassword: false,
    token4: "",
    token3: "",
    token2: "",
    token1: "",
    isOpenDialogToken: false,
    companies: [],
    selectCompanies: "",
    selectTypeClient: "Company",
    TypeClient: ["Company", "Individual"],
    Type: [
      { id: "1", value: "Business" },
      { id: "2", value: "Personal" },
    ],
    nodo: "",
    btns: ["rounded"],
    optionsChat: [{ title: "Open messenger" }, { title: "Create group" }],
    dialogDelete: false,
    isOpenChat: false,
    messageToDelete: [],
    dialogDeleteMessage: false,
    componente: true,
    idEmpleado: "",
    file: [],
    valido: false,
    nombreEncargado: "",
    messages: [],
    activeChat: 1,
    firebase: null,
    messageForm: {
      content: "",
      download: false,
      created_at: "11:11am",
    },
    EditarEvento: false,
    idCreador: "",
    isEditableEvent: false,
    background: "#ebe6e5",
    estadoConexion: "1",
    isExistFilter: false,
    searchChat: "",
    idNotificacion: "",
    sizeScreen: "1201",
    isOpenPanelStatus: false,
    drawer: false,
    group: null,
    searchEvento: "",
    detalleLinkEvento: false,
    dialogEvento: false,
    dialogDeleteEvento: false,
    headersEvento: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "nombre",
      },
      { text: "Redirect", value: "redirect", sortable: false },
      { text: "Actions", value: "actions" },
    ],
    links: [],
    editedIndexEvento: -1,
    editedItemEvento: {
      nombre: "",
      ruta: "",
    },
    defaultItemEvento: {
      nombre: "",
      ruta: "",
    },
    html: "",
    urlMaxImage: "",
    isOpenImage: false,
    countEventos: 0,
    idEvento: "",
    isCreateEvent: false,
    arrayURLVistaPrevia: [],
    header: "",
    mensaje: "",
    selectMiembros: [],
    miembros: [],
    imagenes: [],
    arrayImagenesEventos: [],
    files: [],
    model: 0,
    isOpenDialogAddEvent: false,
    events: [],
    isOpenDialogEvents: false,
    isOpenDialogRegister: false,
    Securitycode: "",
    isOpenDialogChangePassword: false,
    isOpenDialogSecretCode: false,
    checkNumero: false,
    checkMinus: false,
    checkMayus: false,
    checkCaracterEspecial: false,
    checkSize: false,
    idUsuario: "",
    isOpenDialogCreatePassword: false,
    name: "",
    lastName: "",
    codeSecurity: "",
    dates: "",
    email: "",
    dialogCalendar: false,
    conexionFirebase: [],
    isEmpleado: false,
    isMobile: false,
    estatusLinks: false,
    codeVersion: "v - 6.0.0.2",
    unsubscribe: [],
    unsubscribeTeam: [],
    usuariosRestantes: [],
    chatActual: "",
    ref: [],
    meesageToDelete: [],
    dialogDeleteMessageGroup: false,
    sensibilidadMenu: false,
    nombreNuevoGrupo: "",
    idGrupoAuxil: "",
    idGrupoActual: "",
    dialogDeleteGrupo: false,
    menu: false,
    dialog: false,
    dialogDelete: false,
    headersChat: [
      {
        text: "Member",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      idGrupo_Empleado: "",
    },
    defaultItem: {
      name: "",
      idGrupo_Empleado: "",
    },
    nombreGrupo: "",
    gruposChat: [],
    dialogGroup: false,
    switchChat: false,
    nombreGrupo: "",
    taskCenterStatus: 0,
    menuChat: [{ title: "Delete" }],
    offset: true,
    active: false,
    nombreChat: "",
    fechaActualMoment: moment(Date.now()).format("ll"),
    happyBirthday: false,
    fechaActual: "",
    fechaNacimiento: "",
    filelist: [],
    loading: true,
    nombreCliente: "", // nombre del cliente para mostrar en la barra de estado
    nombreServicio: "", // nombre del servicio para mostrar en la barra de estado
    tipoUsuario: "",
    nombreEmpresa: "",
    nombreContacto: "",
    telefonoContacto: "",
    idioma: ["English", "Spanish"],
    selectIdioma: [],
    emailEmpresa: "",
    passwordEmpresa: "",
    passwordEmpresaConfirm: "",
    departamentos: {
      payroll: false,
      bookkepping: false,
      system: false,
      support: false,
      managers: false,
      loans: false,
      taxes: false,
    },
    idDepartamentos: [],
    tipo: 0,
    participanteURL: "",
    urlAvatar: "",
    dialogoFoto: false,
    fab: false,
    usuariosChat: [],
    filtroChat: [],
    dialogoConfirmacion: false,
    rutaLogo: sessionStorage.getItem("ruta") + "/src/assets/logo_azul.png",
    rutaLogoX: sessionStorage.getItem("ruta") + "/src/assets/X1.png",
    group: null,
    dialogoArchivo: false,
    dialogoDescarga: false,
    selectedItem: 1,
    inputFile: null,
    archivo: null,
    notificaciones: [],
    message: "text",
    messages: 0,
    show: false,
    rutaArchivo: "",
    rol: "",
    nombre: "",
    notify: false,
    botonlogin: "",
    iniciales: "",
    fullname: "",
    email: "", //Datos y opciones del avatar
    itemsEncargados: [],
    selectEncargados: [],
    btns: ["rounded"],
    optionsChat: [{ title: "Open messenger" }, { title: "Create group" }],
    items: [
      "Constructora",
      "Herreria",
      "Salon de belleza",
      "Panaderia",
      "Restaurante",
      "Super mercado",
      "Boutique",
      "Floreria",
      "Plomeria",
      "Jardineria",
      "Gruas",
      "Transporte",
    ],
    select: [],
    checkbox: false,
    dialog: false,
    sesion: 0,
    tab: 0,
    tabs: [
      { name: "Login", icon: "mdi-account" },
      { name: "Create new account", icon: "mdi-account-outline" },
    ],
    valid: true,
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    verify: "",
    loginPassword: "",
    loginEmail: "",
    loginEmailRules: [
      (v) => !!v || "Required",
      (v) => /.+@.+\..+/.test(v) || "Email must be valid",
    ],
    emailRules: [
      (v) => !!v || "Required",
      (v) => /.+@.+\..+/.test(v) || "Email must be valid",
    ],
    show1: false,
    rules: {
      number: (value) => value.length <= 4 || "Max characters",
      counter: (value) => value.length <= 4 || "Max characters",
      required: (value) => !!value || "Required.",
      min: (v) => (v && v.length >= 5) || "Min 8 characters",
    },
    rulesCreateGroup: [(v) => v.length <= 20 || "Max 20 characters"],
    wordsRules: [(v) => v.trim().split(" ").length <= 5 || "Max 5 words"],
    btns1: [["Custom1", "b-xl"]],
    btns2: [["Custom2", "b-xl"]],
    btns3: [["Custom3", "b-xl"]],
    btns4: [["Custom4", "b-xl"]],
    btns5: [["Custom5", "b-xl"]],
    btns6: [["Custom6", "b-xl"]],
    //colors: ["", "error", "teal darken-1"],
    toolsAdmin: [
      "Task Manager",
      "Task Center",
      "Users",
      "Links",
      "Bookkeeping Status",
      "Companies",
      "Individual",
      "Calls",
    ],
    tools: [
      "Task Center",
      "Users",
      "Links",
      "Bookkeeping Status",
      "Companies",
      "Individual",
      "Calls",
    ],
    dialogoChat: false,
    validarChat: false,
    headers: [
      {
        text: "Notes",
        align: "start",
        sortable: false,
        value: "titulo",
      },
    ],
    notas: [],
    options: {
      itemsPerPage: 5,
    },
    search: "",
    dialogoNotas: false,
    editNota: [],
    dialogoDetalleTarea: false,
    estatus: false,
    idTarea: "",
    detalles: {},
    participants: [], // the list of all the participant of the conversation. `name` is the user name, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
    titleImageUrl: "", //http://myfastax.com/src/assets/fastaxMini.jpg
    messageList: [], // the list of the messages to show, can be paginated and adjusted dynamically
    newMessagesCount: 0,
    isChatOpen: false, // to determine whether the chat window should be open or closed
    showTypingIndicator: "", // when set to a value matching the participant.id it shows the typing indicator for the specific user
    colors: {
      //Color de las burbujas del beatiful chat
      header: {
        bg: "#0D47A1",
        text: "#ffffff",
      },
      launcher: {
        bg: "#0D47A1",
      },
      messageList: {
        bg: "#ffffff",
      },
      sentMessage: {
        bg: "#4e8cff",
        text: "#ffffff",
      },
      receivedMessage: {
        bg: "#eaeaea",
        text: "#222222",
      },
      userInput: {
        bg: "#f4f7f9",
        text: "#565867",
      },
    }, // specifies the color scheme for the component
    alwaysScrollToBottom: true, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
    messageStyling: true,
    nodo: "",
    nodoGrupo: "",
  }),
  watch: {
    searchChat: function (val) {
      //var filtrados = this.usuariosChat.find((element) => element == this.searchChat);

      var filtrados = this.usuariosChat.filter(
        (element) =>
          element.nombre
            .toUpperCase()
            .indexOf(this.searchChat.toUpperCase()) === 0
      );

      this.filtroChat = filtrados;
    },
    telefonoContacto: function (val) {
      if (isNaN(this.telefonoContacto)) {
        this.telefonoContacto = "";
      }
    },
    group() {
      this.drawer = false;
    },

    detalleLink(val) {
      if (this.detalleLink) {
        this.headers = [
          {
            text: "Name",
            align: "start",
            sortable: false,
            value: "nombre",
          },
          { text: "Link", value: "ruta", sortable: false },
          { text: "Redirect", value: "redirect", sortable: false },
          { text: "Actions", value: "actions", sortable: false },
        ];
      } else {
        this.headers = [
          {
            text: "Name",
            align: "start",
            sortable: false,
            value: "nombre",
          },
          { text: "Redirect", value: "redirect", sortable: false },
          { text: "Actions", value: "actions", sortable: false },
        ];
      }
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    passwordEmpresa: function (val) {
      if (this.passwordEmpresa.length >= 8) {
        this.checkSize = true;
      } else {
        this.checkSize = false;
      }

      if (/\d/.test(this.passwordEmpresa)) {
        this.checkNumero = true;
      } else {
        this.checkNumero = false;
      }

      if (/[a-z]/.test(this.passwordEmpresa)) {
        this.checkMinus = true;
      } else {
        this.checkMinus = false;
      }

      if (/[A-Z]/.test(this.passwordEmpresa)) {
        this.checkMayus = true;
      } else {
        this.checkMayus = false;
      }
      if (/[^0-9a-zA-Z]/.test(this.passwordEmpresa)) {
        this.checkCaracterEspecial = true;
      } else {
        this.checkCaracterEspecial = false;
      }
    },

    isChatOpen: function (val) {
      //listener de la variable isChatOpen
      if (!this.isChatOpen) {
        //si la variable cambia a falso cierra la conexion de chat con firebase
        this.unsubscribe(); //unsubscribe() cierra la conexion de firebase
        this.unsubscribeTeam(); //cierra la conexion de los mensajes grupales
      }
    },
    top(val) {
      this.bottom = !val;
    },
    right(val) {
      this.left = !val;
    },
    bottom(val) {
      this.top = !val;
    },
    left(val) {
      this.right = !val;
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  itemEmpleados: [],
  tipoEmpleado: 0,
  computed: {
    filteredItems() {
      return usuariosChat;
    },
    activeFab() {
      switch (this.tabs) {
        case "one":
          return { class: "purple", icon: "account_circle" };
        case "two":
          return { class: "red", icon: "edit" };
        case "three":
          return { class: "green", icon: "keyboard_arrow_up" };
        default:
          return {};
      }
    },
    dateRangeText() {
      this.fechas = this.dates.join(" ~ ");
    },
    passwordMatch() {
      return () => this.password === this.verify || "Password must match";
    },
  },
  created() {
    //const fecha = new Fecha();
    //console.log(fecha.getYears());
    this.isOpenChatCliente = false;
    this.ConsultarNombreEmpresasInactivas();
    this.GetSizeWindow();
    this.ValidarDispositivo();
    sessionStorage.removeItem("idCliente");
    var URLactual = window.location;

    if (URLactual == "https://myfastax.com/") {
      this.$router.push("/TaskCenter").catch((failure) => {});
    } else {
      this.taskCenterStatus = 0;
    }

    EventBus.$on("add-comment", (item, nombre) => {
      this.nombreCliente = nombre;
      this.nombreServicio = item;
    });
    //EventBus.$emit("reload-table");

    this.nombreServicio = "Task Center";
    this.tipoEmpleado = sessionStorage.getItem("tipo");

    // Rutas
    this.ObtenerURLServer();

    this.rutaLogo =
      sessionStorage.getItem("ruta") + "/src/assets/logo_azul.png";
    if (localStorage.getItem("uid")) {
      this.ObtenerDatosSesion();
    } else {
      //this.Logout();
    }
  },
  methods: {
    ConsultarMisEmpresas() {
      let parametros = new URLSearchParams();

      parametros.append("accion", 58);
      parametros.append("idIndividual", sessionStorage.getItem("idTaxpayer"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            //filtros const resultado = response.data.filter(res => res.name !== 'simon')
            //response.data.map(res=>console.log(res))
            //console.log("Respuesta: " + response.data);
            //  this.companies = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    DeleteImageEvent() {
      this.arrayURLVistaPrevia = [];

      db.collection("Eventos-" + this.idEvento)
        .doc(this.idImagenEvento)
        .delete()
        .then(
          function () {
            console.log("Image successfully deleted!");
            this.isDialogConfirmDeleteImageEvent = false;
            this.isOpenImage = false;
          }.bind(this)
        )
        .catch(function (error) {
          console.error("Error al aceptar notificación: ", error);
        })
        .bind(this);
    },
    filter: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    ConsultarNombreEmpresasInactivas() {
      let parametros = new URLSearchParams();

      parametros.append("accion", 12);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/Authentication.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.companies = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    EliminarRuta(item) {
      cadenaRutas = JSON.stringify(this.links);

      let parametros = new URLSearchParams();

      parametros.append("accion", 8);

      parametros.append("idRuta", item.idRuta);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEventos.php",
        data: parametros,
      })
        .then(function (response) {}.bind(this))
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    AddAllMembers() {
      this.selectMiembros = this.miembros;
      this.ConsultarEmpleados();
      /*this.miembros.forEach((element)=>{
        this.selectMiembros.psuh(element.idEmpleado)
      });*/
    },
    EliminarImagenEvento(item) {
      this.arrayURLVistaPrevia = [];

      db.collection("Eventos-" + this.idEvento)
        .doc(item.id)
        .delete()
        .then(function () {
          console.log("Document successfully deleted!");
        })
        .catch(function (error) {
          console.error("Error al aceptar notificación: ", error);
        })
        .bind(this);
    },
    ValidarExistenciaNodo(uid, message, type, nombreEvento, nombre, idGrupo) {
      var doc = "";

      doc = idGrupo;

      let ref = db
        .collection("notyEvento" + uid)
        .where("idGrupo", "==", idGrupo);

      ref.onSnapshot((querySnapshot) => {
        querySnapshot.forEach((element) => {});
      });

      //funcion que notifica a usuario po medio de firebase RT DB
      /*
      var doc = "";

      doc = idGrupo;

      db.collection("notyEvento" + uid)
        .doc(doc)
        .set({
          //Nodo padre del que queremos sacar los nodos hijos
          name: nombre,
          type: type,
          message: message,
          time: Date.now(),
          idEmpleado: sessionStorage.getItem("uid"),
          estatus: true,
          notificado: false,
          nombreEvento: nombreEvento,
          idGrupo: idGrupo,
          url: this.urlAvatar,
        })
        .then(() => {
          console.log("Document successfully written!");
        })
        .catch((error) => {
          console.log("Erorr: " + error);
        }); //promesa para el error
      //this.ConsultarMensajes();*/
    },
    ModificarEvento() {
      console.log();

      var cadenaMiembros = "";
      var cadenaRutas = "";

      //this.selectMiembros.push(sessionStorage.getItem("uid"));
      cadenaMiembros = JSON.stringify(this.selectMiembros);
      cadenaRutas = JSON.stringify(this.links);
      console.log(cadenaMiembros);

      let parametros = new URLSearchParams();
      parametros.append("accion", 7);
      parametros.append("idEvento", this.idEvento);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));
      parametros.append("miembros", JSON.stringify(cadenaMiembros));
      parametros.append("titulo", this.header);
      parametros.append("mensaje", this.mensaje);
      parametros.append("rutas", cadenaRutas);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEventos.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            if (response.data) {
              this.RecorrerArray(this.idEvento);

              this.AlertaEvento("B");
            }
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    AlertaEvento(key) {
      switch (key) {
        case "A":
          //Cuando cambias a la vista para editar
          alert("Ahora puedes editar el evento");
          break;

        case "B":
          //Cuando das click para guardar los cambios del evento
          alert("Evento modificado");
          break;

        default:
          break;
      }
    },
    ValidarCreadorEvento(idCreador) {
      //console.log(this.sessionStorage.getItem('uid'));
      if (idCreador == sessionStorage.getItem("uid")) {
        this.isEditableEvent = true;
      }
    },
    ConsultarEstatus() {
      let ref = db
        .collection("conexiones")
        .where("idEmpleado", "==", sessionStorage.getItem("uid"));

      ref.onSnapshot((querySnapshot) => {
        querySnapshot.forEach((element) => {
          this.estadoConexion = element.data().tipo;
        });
      });
    },
    GetSizeWindow() {
      window.addEventListener("resize", (evt) => {
        if (window.innerHeight == screen.height) {
        } else {
          this.sizeScreen = window.innerWidth;
        }
      });
    },
    ConsultarLinks() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 6);
      parametros.append("idEvento", this.idEvento);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEventos.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.links = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },

    AbrirRuta(item) {
      //window.location = item.ruta;
      window.open(item.ruta);
    },
    ConsultarRutas() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 1);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorRuta.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.links = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    editItemEvento(item) {
      this.editedIndexEvento = this.links.indexOf(item);
      this.editedItemEvento = Object.assign({}, item);
      this.dialogEvento = true;
    },

    deleteItemEvento(item) {
      this.editedIndexEvento = this.links.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDeleteEvento = true;
    },

    deleteItemConfirmEvento() {
      this.links.splice(this.editedIndexEvento, 1);
      this.EliminarRuta();
    },

    closeEvento() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItemEvento = Object.assign({}, this.defaultItemEvento);
        this.editedIndexEvento = -1;
      });
    },

    closeDeleteEvento() {
      this.dialogDeleteEvento = false;
      this.$nextTick(() => {
        this.editedItemEvento = Object.assign({}, this.defaultItem);
        this.editedIndexEvento = -1;
      });
    },

    saveEvento() {
      if (this.editedIndex > -1) {
        Object.assign(
          this.links[this.editedIndexEvento],
          this.editedItemEvento
        );

        // this.ModificarRuta();
      } else {
        this.links.push(this.editedItemEvento);
        //this.AgregarRuta();
        //this.closeEvento();
        this.dialogEvento = false;
      }
      this.close();
    },

    ValidarDispositivo() {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        sessionStorage.setItem("isMobile", true);
        this.isMobile = true;
      } else {
        sessionStorage.setItem("isMobile", false);
        this.isMobile = false;
      }
    },
    ObtenerDatosSesion() {
      this.NotificarEventos();
      sessionStorage.setItem("uid", localStorage.getItem("uid"));
      this.tipoUsuario = localStorage.getItem("tipo");
      if (localStorage.getItem("tipo") == 1) {
        this.sesion = 1;
        this.isEmpleado = true;
        this.MostrarDatosEmpleado(localStorage.getItem("idUsuario"));
        this.ConsultarMisEventos();
      } else if (localStorage.getItem("tipo") == 2) {
        this.sesion = 2;
        //this.SwitchCompany();

        this.ConsultarDatosClientes(sessionStorage.getItem("idUsuario"));
      }
    },
    ObtenerURLServer() {
      //rutas
      //ruta local
      /*sessionStorage.setItem(
        "ruta",
        "http://192.168.100.96:80/fastaxSolutions"
      );*/
      //
      //ruta remota
      sessionStorage.setItem("ruta", "https://myfastax.com");
    },

    ExpandirImagen(item) {
      this.urlMaxImage = item;
    },

    AgregarAsistenciaEvento() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 5);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));
      parametros.append("idEvento", this.idEvento);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEventos.php",
        data: parametros,
      })
        .then(
          function (response) {
            alert("ready");
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    LimpiarCajasEventos() {
      this.header = "";
      this.mensaje = "";
      this.imagenes = [];
      this.miembros = [];
      this.selectMiembros = [];
    },
    ConsultarArchivos() {
      moment.locales("es");

      let ref = db.collection("Eventos-" + this.idEvento); // con el order by ordena los datos de acuerdo al campo especificado.

      ref.onSnapshot((querySnapshot) => {
        //this.archivos = querySnapshot;
        this.dialog = false;
        this.directorio = [];
        querySnapshot.forEach((element) => {
          //forEach que recorre toda la coleccion de campos con las urls de las imagenes
          this.arrayURLVistaPrevia.push({
            id: element.id,
            url: element.data().url,
          });
        });
      });
    },
    CambiarEstatusEvento(item) {
      var id = sessionStorage.getItem("uid");
      this.ConsultarLinks();

      db.collection("notyEvento" + id)
        .doc(item)
        .update({
          estatus: false,
        }); // Con este metodo actualizamos la notificacion a false.
    },
    AbrirEvento(item) {
      console.log(item);
      this.CambiarEstatusEvento(item); //mandar solo el id, modificar el doc del nodo para que solo este el puro id del evento
      let parametros = new URLSearchParams();
      parametros.append("accion", 4);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));
      parametros.append("idEvento", item);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEventos.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.ValidarCreadorEvento(response.data[0].idCreador);
            this.header = response.data[0].titulo;
            this.mensaje = response.data[0].mensaje;
            this.miembros = [];
            response.data.forEach((element) => {
              this.miembros.push({
                idEmpleado: element.idEmpleado,
                nombre: element.nombre,
              });

              if (element.enterado == 1) {
                this.selectMiembros.push(element.idEmpleado);
              }
            });

            if (this.mensaje.indexOf("http") > -1) {
              var cadena = this.mensaje;

              var urlAux = cadena.split("//");
              var url = urlAux[1].split(".com");

              this.html =
                "<h3 style='color: #464545'>" +
                this.mensaje +
                "</h3>  <a href='https://" +
                url[0] +
                ".com'>" +
                url[0] +
                "</a>";
            } else {
              this.html =
                "<h3 style='color: #464545'>" + this.mensaje + "</h3> ";
            }

            this.isOpenDialogAddEvent = true;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    clearPreview() {
      this.arrayURLVistaPrevia = [];
      this.imagenes = "";
    },
    Preview_image() {
      //this.arrayURLVistaPrevia = [];
      this.arrayURLVistaPrevia.push({
        url: URL.createObjectURL(this.imagenes),
      });
      this.arrayImagenesEventos.push(this.imagenes);
    },
    inputChanged() {
      this.files = [...this.currFiles, ...this.files];
    },
    ConsultarEmpleados() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 3);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEventos.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.miembros = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    RecorrerArray(idEvento) {
      this.arrayImagenesEventos.forEach((element) => {
        this.SubirImagenes(element, idEvento);
      });
    },
    async SubirImagenes(element, idEvento) {
      try {
        const refImagen = storage
          .ref()
          .child("Eventos/" + idEvento)
          .child(element.name);

        const res = await refImagen.put(element);

        const urlDescarga = await refImagen.getDownloadURL();

        this.EnviarURLEventos(
          urlDescarga,
          element.name,
          element.size,
          idEvento
        );
      } catch (error) {
        console.log(error);
      }
    },
    EnviarURLEventos(urlDescarga, fileName, fileSize, idEvento) {
      //subir url de imagen evento
      db.collection("Eventos-" + idEvento)
        .add({
          name: fileName,
          size: fileSize,
          url: urlDescarga,
          time: Date.now(),
          status: 1,
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error
      this.arrayURLVistaPrevia = [];
    },
    AgregarEvento() {
      var cadenaMiembros = "";
      var cadenaRutas = "";

      this.selectMiembros.push(sessionStorage.getItem("uid"));
      cadenaMiembros = JSON.stringify(this.selectMiembros);
      cadenaRutas = JSON.stringify(this.links);

      let parametros = new URLSearchParams();
      parametros.append("accion", 2);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));
      parametros.append("miembros", cadenaMiembros);
      parametros.append("titulo", this.header);
      parametros.append("mensaje", this.mensaje);
      parametros.append("rutas", cadenaRutas);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEventos.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.events = response.data;

            if (response.data > 0) {
              this.RecorrerArray(response.data);

              this.selectMiembros.forEach((element) => {
                if (sessionStorage.getItem("uid") != element) {
                  this.AgregarEventoEnFirebase(
                    element,
                    "Tienes un evento nuevo",
                    5,
                    this.header,
                    "",
                    response.data
                  );
                }
              });

              this.AgregarEventoEnFirebase(
                sessionStorage.getItem("uid"),
                "Tienes un evento nuevo",
                5,
                this.header,
                "",
                response.data
              );
            }
            //validar que se haya insertado el evento para poder subir la imagenes a firebase
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    AgregarEventoEnFirebase(uid, message, type, nombreEvento, nombre, idGrupo) {
      //funcion que notifica a usuario po medio de firebase RT DB
      var doc = "";
      doc = idGrupo;

      db.collection("notyEvento" + uid)
        .doc(doc)
        .set({
          //Nodo padre del que queremos sacar los nodos hijos
          name: nombre,
          type: type,
          message: message,
          time: Date.now(),
          idEmpleado: sessionStorage.getItem("uid"),
          estatus: true,
          notificado: false,
          nombreEvento: nombreEvento,
          idGrupo: idGrupo,
          url: this.urlAvatar,
        })
        .then(() => {
          console.log("Document successfully written!");
        })
        .catch((error) => {
          console.log("Erorr: " + error);
        }); //promesa para el error
      //this.ConsultarMensajes();
    },
    ModificarEventoEnFirebase(
      uid,
      message,
      type,
      nombreEvento,
      nombre,
      idGrupo
    ) {
      //funcion que notifica a usuario po medio de firebase RT DB
      var doc = "";

      doc = idGrupo;

      db.collection("notyEvento" + uid)
        .doc(doc)
        .update({
          //Nodo padre del que queremos sacar los nodos hijos
          name: nombre,
          type: type,
          message: message,
          time: Date.now(),
          idEmpleado: sessionStorage.getItem("uid"),
          estatus: true,
          notificado: false,
          nombreEvento: nombreEvento,
          idGrupo: idGrupo,
          url: this.urlAvatar,
        })
        .then(() => {
          console.log("Document successfully written!");
        })
        .catch((error) => {
          this.AgregarEventoEnFirebase(
            uid,
            message,
            type,
            nombreEvento,
            nombre,
            idGrupo
          );
          console.log("Modificar: " + error);
        }); //promesa para el error
      //this.ConsultarMensajes();
    },
    ConsultarMisEventos() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 1);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEventos.php",
        data: parametros,
      })
        .then(
          function (response) {
            //this.events = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ValidarSecretCode() {
      if (this.email) {
        let parametros = new URLSearchParams();
        parametros.append("accion", 10);
        parametros.append("user", this.email);
        //parametros.append("nip", this.Securitycode);

        axios({
          method: "post",
          url: sessionStorage.getItem("ruta") + "/core/Authentication.php",
          data: parametros,
        })
          .then(
            function (response) {
              console.log(response.data);
              if (response.data > 0) {
                alert(
                  "An SMS has been sent to your phone number, please enter the 4-digit code to verify identity."
                );
                this.idUsuario = response.data;
                this.isOpenDialogSecretCode = false;
                //this.isOpenDialogTokenForgetPassword = true;
                this.opcionToken = 15;
                this.isOpenDialogToken = true;
              } else {
                alert(
                  "The information entered does not match our records, please make sure you have entered the correct information."
                );
              }
            }.bind(this)
          )
          .catch(
            function (response) {
              //handle error
              console.log(response);
            }.bind(this)
          );
      } else {
        alert("Enter an email address");
      }
    },
    RestablecerContrasena() {
      var isPasswordCorrect = true;

      if (this.passwordEmpresa != this.passwordEmpresaConfirm) {
        alert("password don not match");
        isPasswordCorrect = false;
      }

      if (this.passwordEmpresa.length < 8) {
        isPasswordCorrect = false;
        alert("At least 8 characteres missing ");
      }

      if (!/\d/.test(this.passwordEmpresa)) {
        isPasswordCorrect = false;
        alert("At least one number missing");
      }
      if (!/[a-z]/.test(this.passwordEmpresa)) {
        isPasswordCorrect = false;
        alert("At least one lower case letter missing");
      }
      if (!/[A-Z]/.test(this.passwordEmpresa)) {
        isPasswordCorrect = false;
        alert("At least one upper case letter missing");
      }
      if (!/[^0-9a-zA-Z]/.test(this.passwordEmpresa)) {
        isPasswordCorrect = false;
        alert("At least one special character (!@#$%^&*) missing");
      }
      if (isPasswordCorrect) {
        let parametros = new URLSearchParams();
        parametros.append("accion", 11);
        parametros.append("password", this.passwordEmpresa);
        parametros.append("idUsuario", this.idUsuario);
        parametros.append("user", this.email);

        axios({
          method: "post",
          url: sessionStorage.getItem("ruta") + "/core/Authentication.php",
          data: parametros,
        })
          .then(
            function (response) {
              this.isOpenDialogCreatePassword = false;
              this.isOpenDialogTokenForgetPassword = false;
              this.isOpenDialogChangePassword = false;
              alert("You are all set!!");
              // this.EnviarCorreoConfirmacion();
            }.bind(this)
          )
          .catch(
            function (response) {
              //handle error
              console.log(response);
            }.bind(this)
          );
      } else {
      }
    },
    EstablecerContrasena() {
      var isPasswordCorrect = true;

      if (this.passwordEmpresa != this.passwordEmpresaConfirm) {
        alert("Password do not match ");
        isPasswordCorrect = false;
      }

      if (this.passwordEmpresa.length < 8) {
        isPasswordCorrect = false;
        alert("At least 8 Characters");
      }

      if (!/\d/.test(this.passwordEmpresa)) {
        isPasswordCorrect = false;
        alert("At least one number");
      }

      if (!/[a-z]/.test(this.passwordEmpresa)) {
        isPasswordCorrect = false;
        alert("At least one lower case letter");
      }

      if (!/[A-Z]/.test(this.passwordEmpresa)) {
        isPasswordCorrect = false;
        alert("At least one upper case letter");
      }

      if (!/[^0-9a-zA-Z]/.test(this.passwordEmpresa)) {
        isPasswordCorrect = false;
        alert("At least one special character");
      }

      if (isPasswordCorrect) {
        let parametros = new URLSearchParams();
        parametros.append("accion", 9);
        parametros.append("password", this.passwordEmpresa);
        parametros.append("idUsuario", this.idUsuario);
        parametros.append("user", this.email);
        parametros.append("telefonoContacto", this.telefonoContacto);

        axios({
          method: "post",
          url: sessionStorage.getItem("ruta") + "/core/Authentication.php",
          data: parametros,
        })
          .then(
            function (response) {
              console.log(response);
              alert(
                "An SMS has been sent to your phone number, please enter the 4-digit code to verify identity."
              );
              this.opcionToken = 14;
              this.isOpenDialogCreatePassword = false;

              if (response.data == true) {
                this.isOpenDialogToken = true;
              }
              this.isOpenDialogToken = true;
              // this.EnviarCorreoConfirmacion();
            }.bind(this)
          )
          .catch(
            function (response) {
              //handle error
              console.log(response);
            }.bind(this)
          );
      } else {
      }
    },
    EnviarToken() {
      "Company", "Individual";
      var tipoCliente = 0;
      if (this.selectTypeClient == "Company") {
        //se valida que tipo de cliente es, el sistema no permite el acceso si no esta el tipo de cliente.
        tipoCliente = 2; //Tipo cliente empresa
      } else {
        tipoCliente = 3; // Tipo cliente individual
      }

      let parametros = new URLSearchParams();
      parametros.append("accion", 14);
      parametros.append("tipoCliente", tipoCliente);

      parametros.append(
        "token",
        this.token1 + this.token2 + this.token3 + this.token4
      );

      parametros.append("idUsuario", this.idUsuario);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/Authentication.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.isOpenDialogToken = false;
            alert(
              "You are all set!      Now you can log in and have access to your company information."
            );
            location.reload();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    closeDialogToken() {
      this.isOpenDialogToken = false;
    },
    OpenDialogForgetPasword() {
      this.isOpenDialogChangePassword = true;
    },
    EnviarTokenRestablecerPassword() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 15);
      parametros.append(
        "token",
        this.token1 + this.token2 + this.token3 + this.token4
      );
      parametros.append("idUsuario", this.idUsuario);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/Authentication.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            if (response.data[0].idUsuario > 0) {
              this.isOpenDialogTokenForgetPassword = false;
              //this.isOpenDialogChangePassword = true;
            } else {
              alert("Your Code 4-digit it is incorrect");
            }
            console.log(response.data[0].idUsuario);

            //location.reload();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
            alert("Your Code 4-digit it is incorrect");
          }.bind(this)
        );
    },
    ValidarDatosNuevoClienteIndividual() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 52);
      parametros.append("name", this.name);
      parametros.append("lastName", this.lastName);
      parametros.append("codeSecurity", this.codeSecurity);
      parametros.append("dates", this.dates);
      parametros.append("email", this.email);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            if (response.data != 0) {
              this.idUsuario = response.data;
              this.isOpenDialogCreatePassword = true;
            } else {
              alert(
                "Your data has not been registered or you have entered an invalid PIN"
              );
            }
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ValidarDatosNuevoClienteCompany() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 54);
      parametros.append("name", this.name);
      parametros.append("lastName", this.lastName);
      parametros.append("EIN", this.codeSecurity);
      parametros.append("telefonoContacto", this.telefonoContacto);
      //parametros.append("codeSecurity", this.codeSecurity);
      //parametros.append("dates", this.dates);
      parametros.append("email", this.email);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            if (response.data > 0) {
              this.idUsuario = response.data;
              this.isOpenDialogCreatePassword = true;
            } else if (response.data == 0) {
              alert(
                "You may have not entered your information correctly. Please verify your data and try again. If the problem persists, please contact your system administrator to (408) 808 2222"
              );
            } else {
              alert(response.data);
            }
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    CerrarConexionFirebaseChatFlotante() {
      EventBus.$emit("CerrarConexionFirebase");
    },
    CerrarConexionFirebaseChat() {
      EventBus.$emit("CloseConexionFirebaseChat");
    },
    CerrarConexionFirebaseChatGroup() {
      EventBus.$emit("CloseConexionFirebaseChatGroup");
    },
    AgregarNuevoMiembro() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 6);
      parametros.append("idGrupo", this.idGrupoActual);

      var miembros = [];

      this.selectEncargados.forEach((element) => {
        miembros.push(element.id);
      });

      parametros.append("miembros", JSON.stringify(miembros));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorChat.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.dialogGroup = false;
            this.selectEncargados.forEach((element) => {
              this.Enviarnoty(
                element.idEmpleado,
                "New group: " + this.nombreGrupo,
                "3",
                this.idGrupoActual,
                this.nombreGrupo,
                this.idGrupoAuxil
              );
              this.ConsultarMiembrosXGrupo(this.idGrupoActual);
              this.dialog = false;
            });
            //this.CrearGrupoFirebase(); no se si es funcional NO BORRAR
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    EliminarGrupo() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 5);
      parametros.append("idGrupo", this.idGrupoActual);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorChat.php",
        data: parametros,
      })
        .then(
          function (response) {
            if (response.data) {
              this.isChatOpen = false;
              this.ConsultarGrupos();
            } else {
              alert("we could not delete from this group");
            }
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    EliminarMiembroChat() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 4);
      parametros.append("idGrupo_Empleado", this.editedItem.idGrupo_Empleado);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorChat.php",
        data: parametros,
      })
        .then(function (response) {}.bind(this))
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    editItem(item) {
      this.editedIndex = this.participants.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.participants.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.EliminarMiembroChat();
      this.participants.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.participants[this.editedIndex], this.editedItem);
      } else {
        this.participants.push(this.editedItem);
      }
      this.close();
    },

    MenuChat(opcion) {
      switch (opcion) {
        case "Open messenger":
          if (this.switchChat) {
            this.$router.push("/TaskCenter").catch((failure) => {});

            this.$router
              .push("/GroupChat/" + this.idGrupoAuxil)
              .catch((failure) => {});
          } else {
            this.$router
              .push("/ChatNotificacion/" + this.nodo)
              .catch((failure) => {});
          }

          break;

        case "Create group":
          this.dialogGroup = true;
          this.ConsultarIntegrantesGrupo();
          break;

        default:
          alert("sps!");
          break;
      }
    },
    ConsultarGrupos() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 2);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorChat.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.gruposChat = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    CrearGrupo() {
      this.InsertarNombreGrupoDB();
    },
    InsertarNombreGrupoDB() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 1);
      parametros.append("nombreGrupo", this.nombreNuevoGrupo);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));

      var miembros = [];
      console.log(this.selectEncargados);

      this.selectEncargados.forEach((element) => {
        miembros.push(element.idEmpleado);
      });

      console.log(JSON.stringify(miembros));

      parametros.append("miembros", JSON.stringify(miembros));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorChat.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            if (response.data >= 1) {
              this.idGrupoAuxil = response.data; // idGrupoAxuxil contiene el id del grupo para pasar a firebase por una notificacion
              this.dialogGroup = false;
              this.nombreGrupo = this.nombreNuevoGrupo;
              this.selectEncargados.forEach((element) => {
                this.Enviarnoty(
                  element.idEmpleado,
                  "New group: " + this.nombreGrupo,
                  "3",
                  this.idGrupoActual,
                  this.nombreGrupo,
                  this.idGrupoAuxil
                );
                this.ConsultarGrupos();
              });
              this.CrearGrupoFirebase();
            }
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    CrearGrupoFirebase() {
      // var mensaje = this.messageForm.content;
      //this.messageForm.content = "";
      db.collection("grupo-" + this.nombreNuevoGrupo)
        .add({
          //Nodo padre del que queremos sacar los nodos hijos
          author: sessionStorage.getItem("fullname"),
          message: "New group",
          me: true,
          url: "",
          download: false,
          time: Date.now(),
          type: "text",
          name: sessionStorage.getItem("fullname"),
          idEmpleado: sessionStorage.getItem("uid"),
          status: 0,
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error
      // this.ConsultarMensajes();
      this.mensaje = [];

      this.Enviarnoty(
        sessionStorage.getItem("idEmpleadoChat"),
        "New message: " + sessionStorage.getItem("nombre"),
        "3",
        this.idGrupoActual,
        this.nombreGrupo,
        this.idGrupoAuxil
      );
      //this.ConsultarColaboradores();
    },

    ConsultarIntegrantesGrupo() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 17);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.itemsEncargados = [];
            this.selectEncargados = [];
            response.data.forEach((element) => {
              this.itemsEncargados.push(element);
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ActivarTaskCenterListener() {
      EventBus.$on("hint-taskcenter", () => {
        this.taskCenterStatus = 0;
      });
    },
    EliminarMensaje(item) {
      let ref = db
        .collection(this.nodo)
        .doc(item.id)
        .delete()
        .then(function () {
          console.log("Document successfully deleted!");
        })
        .catch(function (error) {
          console.error("Error al aceptar notificación: ", error);
        });
    },
    EliminarMensajeGrupo(item) {
      let ref = db
        .collection(this.nodoGrupo)
        .doc(item.id)
        .delete()
        .then(function () {
          console.log("Document successfully deleted!");
        })
        .catch(function (error) {
          console.error("Error al aceptar notificación: ", error);
        });
    },
    mouseOver: function () {
      this.active = !this.active;
    },
    ConsultarCumpleaños() {
      let parametros = new URLSearchParams();
      this.usuariosChat = [];
      parametros.append("accion", 16);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));
      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            if (response.data.length > 0) {
              var nombre = "";
              nombre = sessionStorage.getItem("fullname");
              db.collection("noty" + sessionStorage.getItem("uid"))
                .doc("notificacionTarea" + sessionStorage.getItem("uid"))
                .set({
                  //Nodo padre del que queremos sacar los nodos hijos
                  name: nombre,
                  type: 4,
                  message:
                    "TODAY IS " + response.data[0].nombre + "´S BIRTHDAY",
                  time: Date.now(),
                  idTarea: 1,
                  estatus: false,
                  notificado: false,
                })
                .catch((error) => {
                  console.log(error);
                }); //promesa para el error
              //console.log(this.usuariosChat);
            } else {
              console.log("No Data");
            }
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    CerrarNota() {
      this.dialogoNotas = false;
      this.ConsultarNotas();
    },
    EditarNotas(value) {
      this.editNota = value;
      this.dialogoNotas = true;
    },
    handleClick() {
      this.editNota = [];
      this.dialogoNotas = true;
    },
    ConsultarNotas() {
      let parametros = new URLSearchParams();
      this.usuariosChat = [];
      parametros.append("accion", 1);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorNotas.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.notas = response.data;

            //console.log(this.usuariosChat);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    AbrirDialogoChat(item) {
      this.chatActual = item.nombre;

      //this.titleImageUrl = item.url;

      this.participanteURL = item.url;

      this.participants = [];

      this.participants.push({
        id: item.idEmpleado,
        name: item.nombre,
        imageUrl: item.avatar,
      });

      //this.itemEmpleados = item.idEmpleado;

      sessionStorage.setItem("idEmpleadoChat", item.idEmpleado);
      this.nodo = "";
      if (
        sessionStorage.getItem("idEmpleadoChat") > sessionStorage.getItem("uid")
      ) {
        this.nodo =
          "chat-" +
          sessionStorage.getItem("idEmpleadoChat") +
          "-" +
          sessionStorage.getItem("uid");
      } else {
        this.nodo =
          "chat-" +
          sessionStorage.getItem("uid") +
          "-" +
          sessionStorage.getItem("idEmpleadoChat");
      }
      this.idNotificacion = "";

      this.nombreEncargado = sessionStorage.getItem("fullname");
      this.ConsultarMensajes();
      this.LimpiarNotificacionChat(item);
      // this.enviarMensajeVacio();
    },
    AbrirDialogoChatMobil(item) {
      this.chatActual = item.nombre;

      //this.titleImageUrl = item.url;

      this.participanteURL = item.url;

      this.participants = [];

      this.participants.push({
        id: item.idEmpleado,
        name: item.nombre,
        imageUrl: item.avatar,
      });

      //this.itemEmpleados = item.idEmpleado;

      sessionStorage.setItem("idEmpleadoChat", item.idEmpleado);
      this.nodo = "";
      if (
        sessionStorage.getItem("idEmpleadoChat") > sessionStorage.getItem("uid")
      ) {
        this.nodo =
          "chat-" +
          sessionStorage.getItem("idEmpleadoChat") +
          "-" +
          sessionStorage.getItem("uid");
      } else {
        this.nodo =
          "chat-" +
          sessionStorage.getItem("uid") +
          "-" +
          sessionStorage.getItem("idEmpleadoChat");
      }
      this.idNotificacion = "";

      this.nombreEncargado = sessionStorage.getItem("fullname");
      this.ConsultarMensajesMobile();
      this.LimpiarNotificacionChat(item);
      // this.enviarMensajeVacio();
    },
    LimpiarNotificacionChat(item) {
      var nombre = sessionStorage.getItem("uid");
      let ref = db
        .collection("noty" + nombre)
        .doc("notificacionPersonal" + item.idEmpleado)
        .update({
          estatus: false,
        }); // Con este metodo actualizamos la notificacion a false.
    },
    ChecarMensaje(idNodo) {
      db.collection(this.nodo).doc(idNodo).update({
        check: true,
      }); // Con este metodo actualizamos la notificacion a false.
    },
    AbrirChatGrupalNoty(item) {
      this.switchChat = true;
      this.isChatOpen = true; // abre el componente chat
      this.titleImageUrl = "";
      this.ConsultarMiembrosXGrupo(item.idGrupo);
      this.nombreGrupo = item.nombre;
      this.idGrupoActual = item.idGrupo;
      this.idGrupoAuxil = item.idGrupo;

      //this.itemEmpleados = item.idEmpleado;

      //sessionStorage.setItem("idEmpleadoChat", item.idEmpleado);
      this.nodoGrupo = "";

      this.nodoGrupo = "grupo-" + item.nombreGrupo;

      this.nombreEncargado = sessionStorage.getItem("fullname");
      this.ConsultarMensajesGrupales(this.nodoGrupo);
      //this.enviarMensajeGruapalVacio(this.nodoGrupo);
    },
    AbrirDialogoChatGrupal(item) {
      this.switchChat = true;
      this.isChatOpen = true; //abre el componente chat
      this.titleImageUrl = "";
      this.ConsultarMiembrosXGrupo(item.idGrupo);
      this.nombreGrupo = item.nombre;
      this.idGrupoActual = item.idGrupo;
      this.idGrupoAuxil = item.idGrupo;

      //this.itemEmpleados = item.idEmpleado;

      //sessionStorage.setItem("idEmpleadoChat", item.idEmpleado);
      this.nodoGrupo = "";

      this.nodoGrupo = "grupo-" + item.nombre;

      this.nombreEncargado = sessionStorage.getItem("fullname");
      this.ConsultarMensajesGrupales(this.nodoGrupo);
    },
    LimpiarNotificacionChatGrupal(idGrupo) {
      var nombre = sessionStorage.getItem("uid");
      let ref = db
        .collection("noty" + nombre)
        .doc("notificacionGrupo" + idGrupo)
        .update({
          estatus: false,
        }); // Con este metodo actualizamos la notificacion a false.
    },
    ConsultarMensajesGrupales(nodo) {
      moment.locales("es");

      let ref = db.collection(nodo).orderBy("time"); // con el order by ordena los datos de acuerdo al campo especificado.

      this.unsubscribeTeam = ref.onSnapshot((querySnapshot) => {
        var idGrupo = "";
        this.messageList = [];
        querySnapshot.forEach((element) => {
          let nombre = "";

          if (element.data().name == sessionStorage.getItem("fullname")) {
            nombre = "me";
          } else {
            nombre = element.data().name;
            idGrupo = element.data().idGrupo;

            if (element.data().check === false && this.isChatOpen) {
              this.ChecarMensajeGrupo(element.id);
            }
          }

          if (element.data().type == "file") {
            this.messageList.push({
              id: element.id,
              author: nombre,
              type: element.data().type,
              isEdited: true,
              check: element.data().check,
              data: {
                file: {
                  name: element.data().message,
                  url: element.data().url,
                },
              },
            });
          } else {
            this.messageList.push({
              id: element.id,
              author: nombre,
              type: element.data().type,
              check: element.data().check,
              isEdited: true,
              data: {
                text: element.data().message,
                meta: moment(element.data().time).format("lll"),
              },
            });
          }
        });

        if (this.isChatOpen) {
          this.LimpiarNotificacionChatGrupal(idGrupo);
        }
      });
    },
    ChecarMensajeGrupo(idNodo) {
      db.collection(this.nodoGrupo).doc(idNodo).update({
        check: true,
      }); // Con este metodo actualizamos la notificacion a false.
    },
    ConsultarMiembrosParaAgregar() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 17);
      parametros.append("idGrupo", this.idGrupoAuxil);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            var participantsAux = this.participants;

            this.usuariosRestantes = [];

            response.data.forEach((element) => {
              this.participants.forEach((elemento) => {
                this.usuariosRestantes.push({
                  id: element.idEmpleado,
                  name: element.nombre,
                  imageUrl: "dscsd",
                  idGrupo_Empleado: element.idGrupo_Empleado,
                });
              });
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarMiembrosXGrupo(idGrupo) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 3);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));
      parametros.append("idGrupo", idGrupo);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorChat.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.participants = [];

            response.data.forEach((element) => {
              this.participants.push({
                id: element.idEmpleado,
                name: element.nombre,
                imageUrl: "dscsd",
                idGrupo_Empleado: element.idGrupo_Empleado,
                idEmpleado: element.idEmpleado,
              });
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    CerrarDialogoChat() {
      this.dialogoChat = false;
      this.validarChat = false;
    },
    ConsultarUsuariosConectados() {
      let ref = db
        .collection("conexiones")
        .where("estatus", "==", "1")
        .orderBy("nombre", "asc");

      ref.onSnapshot((querySnapshot) => {
        this.usuariosChat = [];
        this.filtroChat = [];
        querySnapshot.forEach((element) => {
          if (element.data().nombre != sessionStorage.getItem("fullname")) {
            if (element.data().tipo == 1) {
              this.usuariosChat.push({
                idEmpleado: element.data().idEmpleado,
                nombre: element.data().nombre,
                tipo: element.data().tipo,
                url: element.data().url,
              });

              this.filtroChat.push({
                idEmpleado: element.data().idEmpleado,
                nombre: element.data().nombre,
                tipo: element.data().tipo,
                url: element.data().url,
              });
            }
          } else {
            this.urlAvatar = element.data().url;
          }
        });
      });
    },

    ConsultarUsuariosDesconectados() {
      let ref = db
        .collection("conexiones")
        .where("estatus", "==", "1")
        .orderBy("nombre", "asc");

      ref.onSnapshot((querySnapshot) => {
        querySnapshot.forEach((element) => {
          if (element.data().nombre != sessionStorage.getItem("fullname")) {
            if (element.data().tipo == 0) {
              this.usuariosChat.push({
                idEmpleado: element.data().idEmpleado,
                nombre: element.data().nombre,
                tipo: element.data().tipo,
                url: element.data().url,
              });

              this.filtroChat.push({
                idEmpleado: element.data().idEmpleado,
                nombre: element.data().nombre,
                tipo: element.data().tipo,
                url: element.data().url,
              });
            }
          } else {
            this.urlAvatar = element.data().url;
          }
        });
      });
    },
    ConsultarUsuariosAbsents() {
      let ref = db
        .collection("conexiones")
        .where("estatus", "==", "1")
        .orderBy("nombre", "asc");

      ref.onSnapshot((querySnapshot) => {
        querySnapshot.forEach((element) => {
          if (element.data().nombre != sessionStorage.getItem("fullname")) {
            if (element.data().tipo == 2) {
              this.usuariosChat.push({
                idEmpleado: element.data().idEmpleado,
                nombre: element.data().nombre,
                tipo: element.data().tipo,
                url: element.data().url,
              });

              this.filtroChat.push({
                idEmpleado: element.data().idEmpleado,
                nombre: element.data().nombre,
                tipo: element.data().tipo,
                url: element.data().url,
              });
            }
          } else {
            this.urlAvatar = element.data().url;
          }
        });
      });
    },
    Logout() {
      this.sesion = 0;
      this.isOpenChatCliente = false;
      this.botonlogin = "Log in";
      sessionStorage.removeItem("uid");
      localStorage.removeItem("uid");
      sessionStorage.setItem("uid", null);
      sessionStorage.setItem("nombre", "");

      this.$router.push("/").catch((failure) => {});

      sessionStorage.removeItem("uid");
      sessionStorage.removeItem("rol");

      location.reload();
    },
    limpiarVariables() {
      /* sessionStorage.removeItem("uid");
      sessionStorage.removeItem("uid");
      sessionStorage.removeItem("uid");
      sessionStorage.removeItem("uid");
      sessionStorage.removeItem("uid");
      sessionStorage.removeItem("uid");
      sessionStorage.removeItem("uid");
      sessionStorage.removeItem("uid");
      sessionStorage.removeItem("uid");
      sessionStorage.removeItem("uid");
      sessionStorage.removeItem("uid");
      sessionStorage.removeItem("uid");
      sessionStorage.removeItem("uid");
      sessionStorage.removeItem("uid");
      sessionStorage.removeItem("uid");
      sessionStorage.removeItem("uid");
      sessionStorage.removeItem("uid");
      sessionStorage.removeItem("uid");
      sessionStorage.removeItem("uid");
      sessionStorage.removeItem("uid");
      sessionStorage.removeItem("uid");
      sessionStorage.removeItem("uid");*/
    },
    CerrarConexionesFirebase() {},
    validate() {
      if (this.$refs.loginForm.validate()) {
        // submit form to server/API here...
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    ValidarSesion() {
      if (this.sesion > 0) {
        this.AbrirDialogoConfirmacion();
      } else {
        this.dialog = true;
      }
    },
    Login() {
      this.loading = true;
      let parametros = new URLSearchParams();

      parametros.append("accion", 1);
      parametros.append("password", this.loginPassword);
      parametros.append("user", this.loginEmail);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/Authentication.php",
        data: parametros,
      })
        .then(
          function (response) {
            // La promesa recibe datos del usaurio, correo, tipo y idUsuario
            //para poder consultar datos del empleado y de la empresa.
            console.log(response.data);
            if (response.data == false) {
              alert(
                "Email or password do not match to our records or have not been registered. Please verify the information and try again."
              );
              this.loading = false;
            } else {
              this.loginPassword = "";
              this.loginEmail = "";
              response.data.forEach((element) => {
                localStorage.setItem("tipo", element["tipo"]);
                sessionStorage.setItem("tipo", element["tipo"]);

                this.tipoUsuario = element["tipo"];

                if (element["tipo"] == 1) {
                  this.isEmpleado = true;
                  this.sesion = 1;
                  this.GetSizeWindow();
                  this.ConsultarDatosEmpleado(element["idUsuario"]);

                  //this.taskCenterStatus = 1;
                } else if (element["tipo"] == 2) {
                  this.isEmpleado = false;
                  //Clientes como empresas
                  this.sesion = 2;
                  sessionStorage.setItem("idUsuario", element["idUsuario"]);
                  this.ConsultarDatosClientes(element["idUsuario"]); //Login cliente
                } /*else if (element["tipo"] == 3) {
                  this.isEmpleado = false;
                  //Clientes como personas
                  this.sesion = 2;
                  this.ConsultarTaxPayer(element["idUsuario"]);
                }*/
              });
              this.loading = false;
            }
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    MostrarDatosEmpleado(idUsuario) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 3);
      parametros.append("idUsuario", idUsuario);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/Authentication.php",
        data: parametros,
      })
        .then(
          function (response) {
            response.data.forEach((element) => {
              this.botonlogin = "Log out";
              localStorage.setItem("uid", element["idEmpleado"]);
              sessionStorage.setItem("uid", element["idEmpleado"]);
              sessionStorage.setItem("nombre", element["nombre"]);
              sessionStorage.setItem("aPaterno", element["aPaterno"]);
              sessionStorage.setItem("correo", element["correo"]);
              sessionStorage.setItem("rol", element["rol"]);
              sessionStorage.setItem("tipo", element["tipo"]);
              localStorage.getItem("uid", element["tipo"]);
              sessionStorage.setItem("idUsuario", element["idUsuario"]);
              localStorage.setItem("idUsuario", element["idUsuario"]);
              sessionStorage.setItem("departamento", element["departamento"]);
              sessionStorage.setItem("ChatCliente", element["ChatCliente"]);
              this.chatCliente = element["ChatCliente"];

              if (this.chatCliente == 1) {
                this.isChatAvaible = true;
                this.NotificarChatsClientes();
              } else {
                this.isChatAvaible = true;
                this.isOpenChatCliente = false;
              }

              this.fechaNacimiento = element["birthday"];

              if (this.fechaNacimiento === "1") {
                this.happyBirthday = true;
              } else {
                this.happyBirthday = false;
              }

              this.tipo = sessionStorage.getItem("tipo");
              this.idDepartamentos.push({
                idDepartamento: element["idDepartamento"],
              });

              sessionStorage.setItem(
                "idDepartamento",
                JSON.stringify(this.idDepartamentos)
              );

              //this.ObtenerIniciales(element["nombre"], element["aPaterno"])
              let name = element["nombre"].charAt(0);
              let lastName = element["aPaterno"].charAt(0);
              let iniciales = name + lastName;
              sessionStorage.setItem("iniciales", iniciales);
              sessionStorage.setItem(
                "fullname",
                element["nombre"] + " " + element["aPaterno"]
              );
              this.iniciales = iniciales;
              this.fullname = sessionStorage.getItem("fullname");
              this.email = sessionStorage.getItem("correo");

              this.nombre = element["nombre"];

              this.dialog = false;
              this.rol = element["rol"];

              //this.ConsultarTareasVencidas();
              this.Notificar();
              this.NotificarEventos();
              this.ConsultarUsuariosConectados();
              this.ConsultarUsuariosDesconectados();
              this.ConsultarUsuariosAbsents();
              this.ConsultarGrupos();
              this.ConsultarNotas();
              this.ConectarUsuario();
              this.ConsultarEstatus();
              this.ConsultarCumpleaños();
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    ConsultarDatosEmpleado(idUsuario) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 3);
      parametros.append("idUsuario", idUsuario);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/Authentication.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            response.data.forEach((element) => {
              this.botonlogin = "Log out";
              localStorage.setItem("uid", element["idEmpleado"]);
              sessionStorage.setItem("uid", element["idEmpleado"]);
              sessionStorage.setItem("nombre", element["nombre"]);
              sessionStorage.setItem("aPaterno", element["aPaterno"]);
              sessionStorage.setItem("correo", element["correo"]);
              sessionStorage.setItem("rol", element["rol"]);
              sessionStorage.setItem("tipo", element["tipo"]);
              localStorage.getItem("uid", element["tipo"]);
              sessionStorage.setItem("idUsuario", element["idUsuario"]);
              localStorage.setItem("idUsuario", element["idUsuario"]);
              sessionStorage.setItem("departamento", element["departamento"]);
              sessionStorage.setItem("ChatCliente", element["ChatCliente"]);
              this.chatCliente = element["ChatCliente"];
              this.$router.push("/TaskCenter").catch((failure) => {});

              this.fechaNacimiento = element["birthday"];

              if (this.fechaNacimiento === "1") {
                this.happyBirthday = true;
              } else {
                this.happyBirthday = false;
              }

              this.tipo = sessionStorage.getItem("tipo");
              this.idDepartamentos.push({
                idDepartamento: element["idDepartamento"],
              });

              sessionStorage.setItem(
                "idDepartamento",
                JSON.stringify(this.idDepartamentos)
              );

              //this.ObtenerIniciales(element["nombre"], element["aPaterno"])
              let name = element["nombre"].charAt(0);
              let lastName = element["aPaterno"].charAt(0);
              let iniciales = name + lastName;
              sessionStorage.setItem("iniciales", iniciales);
              sessionStorage.setItem(
                "fullname",
                element["nombre"] + " " + element["aPaterno"]
              );
              sessionStorage.setItem("fullnameClientes", element["nombre"]);
              this.iniciales = iniciales;
              this.fullname = sessionStorage.getItem("fullname");
              this.email = sessionStorage.getItem("correo");
              this.nombre = element["nombre"];
              this.dialog = false;
              this.rol = element["rol"];

              //this.ConsultarTareasVencidas();
              this.Notificar();
              this.NotificarChatsClientes();
              this.NotificarEventos();
              this.ConsultarUsuariosConectados();
              this.ConsultarUsuariosDesconectados();
              this.ConsultarUsuariosAbsents();
              this.ConsultarGrupos();
              this.ConsultarNotas();
              //this.ConectarUsuario();
              sessionStorage.setItem("statusConnection", "1");
              this.ConsultarCumpleaños();
              this.ConsultarEstatus();
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    ConsultarTaxPayer(idUsuario) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 8);
      parametros.append("idUsuario", idUsuario);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/Authentication.php",
        data: parametros,
      })
        .then(
          function (response) {
            response.data.forEach((element) => {
              this.botonlogin = "Log out";

              sessionStorage.setItem("uid", element["idTaxpayer"]);
              sessionStorage.setItem("nombre", element["nombre"]);
              sessionStorage.setItem("correo", element["correo"]);
              sessionStorage.setItem("idUsuario", element["idUsuario"]);

              sessionStorage.setItem(
                "fullname",
                element["nombre"].charAt(0).toUpperCase() +
                  element["nombre"].slice(1) +
                  " " +
                  element["lastname"].charAt(0).toUpperCase() +
                  element["lastname"].slice(1)
              );

              this.fullname = sessionStorage.getItem("fullname");
              this.email = sessionStorage.getItem("correo");
              this.nombre = element["nombre"];
              this.dialog = false;
              this.nombreServicio = "My Tax Return";
              this.$router.push("/PrincipalClients").catch((failure) => {});
              //this.ConsultarTareasVencidas();
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    SwitchCompany() {
      //enviar por bus

      this.fullname = sessionStorage.getItem("fullname");
      var letter = "";
      letter = this.nombreIndividual.split("");
      this.firstLetterName = letter[0];
      if (this.selectMycompany === sessionStorage.getItem("idIndividual")) {
        //alert('individual: '+this.selectMycompany);
        this.$router.push("/PrincipalCliente").catch((failure) => {});
        return;
      }
      let parametros = new URLSearchParams();
      parametros.append("accion", 16);
      parametros.append("idUsuario", this.selectMycompany);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/Authentication.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.GoHome();

            sessionStorage.setItem("nombreEmpresa", response.nombre);
            response.data.forEach((element) => {
              this.botonlogin = "Log out";

              sessionStorage.setItem("uid", element["idCliente"]);
              localStorage.setItem("uid", element["idCliente"]);
              sessionStorage.setItem("nombre", element["nombre"]);
              sessionStorage.setItem("correo", element["correo"]);
              // sessionStorage.setItem("idUsuario", element["idUsuario"]);
              sessionStorage.setItem("tipo", element["tipo"]);
              sessionStorage.setItem("fullname", element["nombre"]);
              sessionStorage.setItem("idIndividual", element["idTaxpayer"]);

              this.myCompanies.push({
                idCliente: element["idCliente"],
                nombreEmpresa: element["nombre"],
              });

              this.ConsultarMisEmpresas();

              this.fullname = sessionStorage.getItem("fullname");
              var letter = "";
              letter = this.fullname.split("");
              this.firstLetterName = letter[0];
              this.email = sessionStorage.getItem("correo");
              this.nombre = element["nombre"];
              this.dialog = false;

              this.$router.push("/MyBusiness").catch((failure) => {});
              EventBus.$emit("InicializarDatosEmpresa", " Home ");
            });

            EventBus.$emit("MyNews");
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    ConsultarDatosIndividual() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 4);
      parametros.append("idUsuario", idUsuario);
      console.log(idUsuario);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/Authentication.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            sessionStorage.setItem("nombreEmpresa", response.data[0].nombre);
            response.data.forEach((element) => {
              this.botonlogin = "Log out";

              sessionStorage.setItem("uid", element["idCliente"]);
              localStorage.setItem("uid", element["idCliente"]);
              sessionStorage.setItem("nombre", element["nombre"]);
              sessionStorage.setItem("correo", element["correo"]);
              sessionStorage.setItem("idUsuario", element["idUsuario"]);
              sessionStorage.setItem("tipo", element["tipo"]);
              sessionStorage.setItem("fullname", element["nombre"]);
              sessionStorage.setItem("idIndividual", element["idTaxpayer"]);

              this.myCompanies.push({
                idCliente: element["idCliente"],
                nombreEmpresa: element["nombre"],
              });

              this.ConsultarMisEmpresas();

              this.fullname = sessionStorage.getItem("fullname");
              var letter = "";
              letter = this.fullname.split("");
              this.firstLetterName = letter[0];
              this.email = sessionStorage.getItem("correo");
              this.nombre = element["nombre"];
              this.dialog = false;

              this.$router.push("/MyBusiness").catch((failure) => {});
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    ConsultarDatosClientes(idUsuario) {
      this.myCompanies = [];
      let parametros = new URLSearchParams();
      parametros.append("accion", 4);
      parametros.append("idUsuario", idUsuario);
      sessionStorage.setItem("idUsuario", idUsuario);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/Authentication.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            sessionStorage.setItem("nombreEmpresa", response.data[0].nombre);

            response.data.forEach((element) => {
              console.log(element);
              this.botonlogin = "Log out";

              sessionStorage.setItem("uid", element["idCliente"]);
              localStorage.setItem("uid", element["idCliente"]);
              sessionStorage.setItem("nombre", element["nombre"]);
              sessionStorage.setItem("correo", element["correo"]);
              //sessionStorage.setItem("idUsuario", element["idUsuario"]);
              sessionStorage.setItem("tipo", element["tipo"]);
              sessionStorage.setItem("fullname", element["nombre"]);
              sessionStorage.setItem("idIndividual", element["idTaxpayer"]);
              sessionStorage.setItem(
                "nombreIndividual",
                element["nombreIndividual"]
              );
              this.nombreIndividual = element["nombreIndividual"];

              if (element["nombre"] != null) {
                this.myCompanies.push({
                  idCliente: element["idCliente"],
                  nombreEmpresa: element["nombre"],
                });
              }

              //this.ConsultarMisEmpresas();

              this.fullname = sessionStorage.getItem("fullname");
              var letter = "";
              letter = this.nombreIndividual.split("");
              this.firstLetterName = letter[0];
              this.email = sessionStorage.getItem("correo");
              this.nombre = element["nombre"];
              this.dialog = false;

              this.$router.push("/MyBusiness").catch((failure) => {});
            });
            this.myCompanies.push({
              idCliente: sessionStorage.getItem("idIndividual"),
              nombreEmpresa: sessionStorage.getItem("nombreIndividual"),
            });

            this.selectMycompany = this.myCompanies[0].idCliente;
            this.SwitchCompany();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    GoHome() {
      //this.$router.push("/MyBusiness").catch((failure) => {});
      EventBus.$emit("goHome");
    },
    AccionMenu(opcion) {
      switch (opcion) {
        case "Task Manager":
          this.nombreServicio = "Task Manager";
          this.nombreCliente = "";
          this.$router.push("/adminTask").catch((failure) => {});
          this.taskCenterStatus = 0;
          this.CerrarConexionFirebaseChat();
          this.CerrarConexionFirebaseChatGroup();
          break;

        case "Task Center":
          this.nombreServicio = "Task Center";
          this.nombreCliente = "";
          this.$router.push("/TaskCenter").catch((failure) => {});
          this.taskCenterStatus = 0;
          this.CerrarConexionFirebaseChat();
          this.CerrarConexionFirebaseChatGroup();
          break;

        case "Users":
          this.nombreServicio = "Users";
          this.nombreCliente = "";
          this.$router.push("/Usuarios").catch((failure) => {});
          this.taskCenterStatus = 0;
          this.CerrarConexionFirebaseChat();
          this.CerrarConexionFirebaseChatGroup();
          break;

        case "Companies":
          this.nombreServicio = "Companies";
          this.nombreCliente = "";
          this.$router.push("/Companies").catch((failure) => {});
          this.taskCenterStatus = 0;
          this.CerrarConexionFirebaseChat();
          this.CerrarConexionFirebaseChatGroup();
          break;

        case "Links":
          this.nombreServicio = "Links";
          this.nombreCliente = "";
          //this.$router.push("/Companies").catch((failure) => {});
          //this.taskCenterStatus = 0;
          //this.CerrarConexionFirebaseChat();
          //this.CerrarConexionFirebaseChatGroup();
          this.estatusLinks = true;

          break;

        case "Bookkeeping Status":
          this.nombreServicio = "BookKeeping Status";
          this.nombreCliente = "";
          this.$router.push("/StatusBookKeeping").catch((failure) => {});

          break;
        case "Individual":
          this.nombreServicio = "Individuals";
          this.nombreCliente = "";

          this.$router.push("/Individuals").catch((failure) => {});

          break;

        case "Calls":
          this.nombreServicio = "Calls";
          this.nombreCliente = "";

          this.$router.push("/LLamadas").catch((failure) => {});

          break;

        default:
          break;
      }
    },
    ObtenerIniciales(nombre, aPaterno) {
      var cadena = nombre + " " + aPaterno,
        separador = " ", // un espacio en blanco
        arregloDeSubCadenas = cadena.split(separador); // SEPARA EL NOMBRE EN CADENAS INDIVIDUALES

      // IMPRIME EL NOMBRE INGRESADO

      /* for (let x = 0; x < arregloDeSubCadenas.length; x++) {
        
      }*/
      // IMPRIME LA PRIMERA LETRA DE CADA CADENA

      for (let j = 0; j < arregloDeSubCadenas.length; j++) {
        subCadena = arregloDeSubCadenas[j].substring(0, 1);
      }
    },
    ConsultarTareasVencidas() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 15);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.notificaciones = [];
            this.messages = 0;
            response.data.forEach((element) => {
              this.notificaciones.push("Tarea vencida: " + element.nombreTarea);
              this.messages += 1;
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    AccederRol() {
      this.rol = sessionStorage.getItem("rol");
    },
    CerrarDialogo() {
      this.dialogoArchivo = false;
    },
    cerrarDialogoRutas() {
      this.estatusLinks = false;
    },
    AbrirDialogoDescarga() {
      this.dialogoDescarga = true;
    },
    CerrarDialogoDescarga() {
      this.dialogoDescarga = false;
    },
    AbrirDialogoConfirmacion() {
      this.dialogoConfirmacion = true;
    },
    CerrarDialogoConfirmacion() {
      this.dialogoConfirmacion = false;
    },
    sumarDias(fecha, dias) {
      fecha.setDate(fecha.getDate() + dias);
      return fecha;
    },

    Notificar() {
      //este metodo es para notificar al usuario
      var nombre = sessionStorage.getItem("uid");
      moment.locales("es");
      let ref = db
        .collection("noty" + nombre)
        .orderBy("time", "desc")
        .limit(100); // con el order by ordena los datos de acuerdo al campo especificado.

      ref.onSnapshot((querySnapshot) => {
        this.messages = 0;
        this.notificaciones = [];

        querySnapshot.forEach((element) => {
          //forEach que recorre toda la coleccion de mensajes

          if (element.data().estatus) {
            this.messages = this.messages + 1;
            if (element.data().notificado === false) {
              this.CambiarEstatusNotificiacionActiva(element);
              //2393
              if (this.idNotificacion != element.id) {
                this.idNotificacion = element.id;
                this.notificacionPush(element);
              }
            }
          }

          this.notificaciones.push({
            mensaje:
              element.data().message +
              "  " +
              moment(element.data().time).format("ll"),
            id: element.id,
            time: moment(element.data().time).format("ll"),
            type: element.data().type,
            idTarea: element.data().idTarea,
            idEmpleado: element.data().idEmpleado,
            nombre: element.data().name,
            estatus: element.data().estatus,
            idGrupo: element.data().idGrupo,
            url: element.data().url,
          });
        });
      });
    },

    NotificarChatsClientes() {
      //este metodo es para notificar al usuario
      var nombre = sessionStorage.getItem("uid");
      moment.locales("es");
      let ref = db
        .collection("ChatClienteNotificaciones")
        .orderBy("time", "desc")
        .limit(60); // con el order by ordena los datos de acuerdo al campo especificado.

      ref.onSnapshot((querySnapshot) => {
        this.messagesClientes = 0;
        this.notificacionesChatCliente = [];

        querySnapshot.forEach((element) => {
          //forEach que recorre toda la coleccion de mensajes

          if (element.data().estatus) {
            this.messagesClientes = this.messagesClientes + 1;
            if (element.data().notificado === false) {
              this.CambiarEstatusNotificiacionActiva(element);
              //2393
              if (this.idNotificacion != element.id) {
                this.idNotificacion = element.id;
                this.notificacionPush(element);
              }
            }
          }

          this.notificacionesChatCliente.push({
            mensaje:
              element.data().message +
              "  " +
              moment(element.data().time).format("ll"),
            id: element.id,
            time: moment(element.data().time).format("ll"),
            type: element.data().type,
            idTarea: element.data().idTarea,
            idCliente: element.data().idCliente,
            idEmpleado: element.data().idEmpleado,
            nombre: element.data().name,
            estatus: element.data().estatus,
            idGrupo: element.data().idGrupo,
            url: element.data().url,
          });
        });
      });
    },
    CambiarEstatusEventoActivo(element) {
      var nombre = sessionStorage.getItem("uid");

      let ref = db
        .collection("notyEvento" + nombre)
        .doc(element)
        .update({
          notificado: true,
        })
        .then(() => {
          this.notificacionPush(element);
        })
        .catch((error) => {
          console.log(error);
        }); // Con este metodo actualizamos la notificacion a false.
    },

    NotificarEventos() {
      //Funcion para mostrar la lista de eventos
      //Get list of events in firebase
      var idEmpleado = sessionStorage.getItem("uid");
      moment.locales("es");
      let ref = db
        .collection("notyEvento" + idEmpleado)
        .orderBy("time", "desc")
        .limit(10); // con el order by ordena los datos de acuerdo al campo especificado.

      ref.onSnapshot((querySnapshot) => {
        this.countEventos = 0;
        //this.notificaciones = [];
        this.events = [];

        querySnapshot.forEach((element) => {
          //forEach que recorre toda la coleccion de mensajes

          if (element.data().estatus) {
            this.countEventos = this.countEventos + 1;
            if (element.data().notificado === false) {
              this.notificacionPush(element);
              this.CambiarEstatusEventoActivo(element.id);
            }
          }

          this.events.push({
            mensaje:
              element.data().message +
              "  " +
              moment(element.data().time).format("ll"),
            id: element.id,
            time: moment(element.data().time).format("ll"),
            type: element.data().type,
            idTarea: element.data().idTarea,
            idEmpleado: element.data().idEmpleado,
            idCreador: element.data().idCreador,
            nombre: element.data().name,
            estatus: element.data().estatus,
            idEvento: element.data().idGrupo,
            url: element.data().url,
            nombreEvento: element.data().nombreEvento,
          });
        });
      });
    },
    EliminarNotificacion(item) {
      var nombre = sessionStorage.getItem("uid");
      let ref = db

        .collection("noty" + nombre)
        .doc(item.id)
        .delete()
        .then(function () {
          this.AccionMenu(item.type);
          this.Notificar();
          console.log("Document successfully deleted!");
        })
        .catch(function (error) {
          console.error("Error al aceptar notificación: ", error);
        });
    },
    AbrirEnlanceNotificacion(item) {
      //Determina que tipo de notificacion. noty de tarea, chat personal o grupal

      this.notify = false; //Cerramos el dialogo que muestra las notificaciones.
      this.CambiarEstatusNotificacion(item);
      if (item.type == 1) {
        // validamos que tipo de notificacion es 1 notificacion de tarea nueva o chat de la tarea
        this.AbrirDialogo(item);
      } else if (item.type == 2) {
        this.switchChat = false;
        // validamos si es 2 es una notificacion del chat personal.
        //cambiamos el estatus de la notificacion para que no la marque como nueva y cambie su color
        //this.isChatOpen = true;
        this.AbrirDialogoChat(item);
        //abrimos el dialogo del chat personal.
      } else if (item.type == 3) {
        //this.isChatOpen = true;
        this.ValidarGrupo(item);

        //this.AbrirChatGrupalNoty(item); //abrimos el dialogo del chat personal.
      } else if (item.type == 5) {
        this.AbrirDialogoEventos();
      }
    },
    InicializarChatCliente() {
      EventBus.$emit("get-messages", "0");
    },
    AbrirEnlanceNotificacionCliente(item) {
      sessionStorage.setItem("idClienteChat", item.idCliente);
      console.log(item);
      this.idClienteChat = item.idCliente;
      //EventBus.$emit("get-messages", item.idCliente);
      this.isOpenChatCliente = true;
      // this.CambiarEstatusNotificacionCliente()
      //Determina que tipo de notificacion. noty de tarea, chat personal o grupal
      /*
      this.notify = false; //Cerramos el dialogo que muestra las notificaciones.
      this.CambiarEstatusNotificacion(item);
      if (item.type == 1) {
        // validamos que tipo de notificacion es 1 notificacion de tarea nueva o chat de la tarea
        this.AbrirDialogo(item);
      } else if (item.type == 2) {
        this.switchChat = false;
        // validamos si es 2 es una notificacion del chat personal.
        //cambiamos el estatus de la notificacion para que no la marque como nueva y cambie su color
        //this.isChatOpen = true;
        this.AbrirDialogoChat(item);
        //abrimos el dialogo del chat personal.
      } else if (item.type == 3) {
        //this.isChatOpen = true;
        this.ValidarGrupo(item);

        //this.AbrirChatGrupalNoty(item); //abrimos el dialogo del chat personal.
      } else if (item.type == 5) {
        this.AbrirDialogoEventos();
      }*/
    },
    AbrirDialogoEventos(item) {
      this.isOpenDialogEvents = true;
      this.ConsultarMisEventos();
    },
    ValidarGrupo(item) {
      let parametros = new URLSearchParams();

      parametros.append("accion", 8);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));
      parametros.append("idGrupo", item.idGrupo);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorChat.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            if (response.data[0].Resultado == "1") {
              this.switchChat = true;
              this.AbrirDialogoChatGrupal(item);
            } else {
              alert("you can't access this group");
            }
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error

            console.log("Error" + response);
          }.bind(this)
        );
    },
    cerrarChat() {
      this.isOpenChatCliente = false;
    },
    cerrarDialogo() {
      this.estatus = false;
      this.dialogoDetalleTarea = false; //este es la variable que define el estatus del componente detalleTarea FALSE/TRUE
    },
    AbrirDialogo(item) {
      //este es la variable que define el estatus del componente FALSE/TRUE
      this.ConsultarDatos(item);
    },
    ConsultarDatos(item) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 20);
      parametros.append("idTarea", item.idTarea);
      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            response.data.forEach((element) => {
              this.detalles = element;
              this.estatus = true;
              this.dialogoDetalleTarea = true;
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    ConectarUsuario() {
      db.collection("conexiones")
        .doc(sessionStorage.getItem("uid"))
        .update({
          idEmpleado: sessionStorage.getItem("uid"),
          nombre: sessionStorage.getItem("fullname"),
          tipo: 1,
          estatus: "1",
        })
        .then((response) => {})
        .catch((error) => {
          console.log("Dio error");
          console.log(error);
          this.AgregarUsuarioFirebase(); //En caso de que el usuario no este registrado en la plataforma de firebase arroja un error si lo rregistra con esta funcion
        });
    },
    AgregarUsuarioFirebase() {
      db.collection("conexiones")
        .doc(sessionStorage.getItem("uid"))
        .set({
          //Nodo padre del que queremos sacar los nodos hijos
          idEmpleado: sessionStorage.getItem("uid"),
          nombre: sessionStorage.getItem("fullname"),
          tipo: 1,
          estatus: "1",
        })
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    },
    sendMessage(text) {
      if (text.length > 0) {
        this.newMessagesCount = this.isChatOpen
          ? this.newMessagesCount
          : this.newMessagesCount + 1;
        this.onMessageWasSent({
          author: "me",
          type: "text",
          check: false,
          isEdited: true,
          data: { text },
        });
      }
    },
    onMessageWasSent(message) {
      if (message.type == "file") {
        if (this.switchChat) {
          this.BuscarImagenGrupo(message);
        } else {
          this.BuscarImagen(message);
        }
      } else {
        if (this.switchChat) {
          this.enviarMensaje(message, this.nodoGrupo);
          this.participants.forEach((element) => {
            this.Enviarnoty(
              element.id,
              "New message: " + this.nombreGrupo,
              "3",
              this.idGrupoActual,
              this.nombreGrupo,
              this.idGrupoAuxil
            );
          });
        } else {
          this.enviarMensaje(message, this.nodo);

          this.Enviarnoty(
            sessionStorage.getItem("idEmpleadoChat"),
            "New message: " + sessionStorage.getItem("nombre"),
            "2",
            " ",
            sessionStorage.getItem("fullname"),
            " "
          );
        }
      }
      this.messageList = [...this.messageList, message];
      // called when the user sends a message
    },
    openChat() {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true;
      this.newMessagesCount = 0;
    },
    closeChat() {
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false;
    },
    handleScrollToTop() {
      //called when the user scrolls message list to top
      //leverage pagination for loading another page of messages
    },
    handleOnType() {},
    editMessage(message) {
      const m = this.messageList.find((m) => m.id === message.id);
      m.isEdited = true;
      m.data.text = message.data.text;
    },
    downloadItem(name) {
      storage
        .ref()
        .child("Archivos" + this.nodo)
        .child(name)
        .getDownloadURL()
        .then(function (url) {
          // `url` is the download URL for 'images/stars.jpg'
          axios({
            method: "get",
            url: url,
            responseType: "blob",
            "Access-Control-Allow-Origin": "*",
          })
            .then(
              function (response) {
                const data = window.URL.createObjectURL(response.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = name;
                link.click();
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        })
        .catch(function (error) {
          console.log(error);
          // Handle any errors
        });
    },
    cerrar() {
      this.$emit("cerrar");
    },
    enviarMensaje(message, nodo) {
      // var mensaje = this.messageForm.content;
      //this.messageForm.content = "";

      db.collection(nodo)
        .add({
          //Nodo padre del que queremos sacar los nodos hijos
          author: message.author,
          message: message.data.text,
          me: true,
          avatar: this.urlAvatar,
          download: false,
          time: Date.now(),
          type: "text",
          name: sessionStorage.getItem("fullname"),
          idEmpleado: sessionStorage.getItem("uid"),
          status: 1,
          nodo: nodo,
          idGrupo: this.idGrupoAuxil,
          check: false,
        })
        .catch((error) => {
          console.log(error);

          //problema actual
        }); //promesa para el error

      this.mensaje = [];
    },
    subirURLImgen() {
      db.collection("conexiones")
        .doc(sessionStorage.getItem("uid"))
        .update({
          //Nodo padre del que queremos sacar los nodos hijos
          url: "https://firebasestorage.googleapis.com/v0/b/prueba-b309a.appspot.com/o/8%2Ffastax.jpg?alt=media&token=e2693d09-f988-41c9-97bf-59081daada4f",
        })
        .then((response) => {
          // console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    ListenerChat() {
      alert("listener");
    },
    ConsultarMensajes() {
      EventBus.$emit("ConsultarConversacion", this.nodo);

      sessionStorage.setItem("nodo", this.nodo);

      moment.locales("es");
      var ref = db
        .collection(this.nodo)
        // .where("status", "==", 1)
        .orderBy("time", "asc")
        .limitToLast(100); // con el order by ordena los datos de acuerdo al campo especificado.

      this.unsubscribe = ref.onSnapshot((querySnapshot) => {
        this.messageList = [];

        var item = [];
        querySnapshot.forEach((element) => {
          let nombre = "";
          if (element.data().name == sessionStorage.getItem("fullname")) {
            nombre = "me";
          } else {
            nombre = element.data().name;
            item.push({
              idEmpleado: element.data().idEmpleado,
            });

            if (element.data().check === false) {
              this.ChecarMensaje(element.id);
            }
          }

          if (element.data().type == "file") {
            this.messageList.push({
              id: element.id,
              author: nombre,
              type: element.data().type,
              isEdited: true,
              check: element.data().check,
              data: {
                file: {
                  name: element.data().message,
                  url: element.data().url,
                },
              },
            });
          } else {
            this.messageList.push({
              id: element.id,
              author: nombre,
              type: element.data().type,
              check: element.data().check,
              isEdited: true,
              data: {
                text: element.data().message,
                meta: moment(element.data().time).format("lll"),
              },
            });
          }
          this.isChatOpen = true;
        });
        this.isChatOpen = true;

        if (this.isChatOpen) {
          this.DesactivarNotificacion(item[0].idEmpleado);
        }
      });
    },
    ConsultarMensajesMobile() {
      EventBus.$emit("ConsultarConversacion", this.nodo);

      sessionStorage.setItem("nodo", this.nodo);

      moment.locales("es");
      var ref = db
        .collection(this.nodo)
        // .where("status", "==", 1)
        .orderBy("time"); // con el order by ordena los datos de acuerdo al campo especificado.

      this.unsubscribe = ref.onSnapshot((querySnapshot) => {
        this.messageList = [];

        var item = [];
        querySnapshot.forEach((element) => {
          let nombre = "";
          if (element.data().name == sessionStorage.getItem("fullname")) {
            nombre = "me";
          } else {
            nombre = element.data().name;
            item.push({
              idEmpleado: element.data().idEmpleado,
            });

            if (element.data().check === false) {
              this.ChecarMensaje(element.id);
            }
          }

          if (element.data().type == "file") {
            this.messageList.push({
              id: element.id,
              author: nombre,
              type: element.data().type,
              isEdited: true,
              check: element.data().check,
              data: {
                file: {
                  name: element.data().message,
                  url: element.data().url,
                },
              },
            });
          } else {
            this.messageList.push({
              id: element.id,
              author: nombre,
              type: element.data().type,
              check: element.data().check,
              isEdited: true,
              data: {
                text: element.data().message,
                meta: moment(element.data().time).format("lll"),
              },
            });
          }
          // this.isChatOpen = true;
          this.$router
            .push("/ChatNotificacion/" + this.nodo)
            .catch((failure) => {});
        });
        //this.isChatOpen = true;
        this.$router
          .push("/ChatNotificacion/" + this.nodo)
          .catch((failure) => {});

        if (this.isChatOpen) {
          this.DesactivarNotificacion(item[0].idEmpleado);
        }
      });
    },
    /*
    ConsultarMensajes() {
      EventBus.$emit("ConsultarConversacion", this.nodo);
      sessionStorage.setItem("nodo", this.nodo);

      moment.locales("es");
      var ref = db
        .collection(this.nodo)
        // .where("status", "==", 1)
        .orderBy("time")
        .limit(100); // con el order by ordena los datos de acuerdo al campo especificado.

      this.unsubscribe = ref.onSnapshot((querySnapshot) => {
        

         let nombre = "";

          if (element.data().name == sessionStorage.getItem("fullname")) {
            nombre = "me";
          } else {
            nombre = element.data().name;

            if (element.data().check === false && this.componente) {
              this.ChecarMensaje(element.id);
            }
          }

          this.messageList.push({
            name: element.data().name,
            message: element.data().message,
            me: element.data().me,
            check: element.data().check,
            author: nombre,
            url: element.data().url,
            download: element.data().download,
            time: moment(element.data().time).format("lll"),
            id: element.id,
          });
      });
    },*/
    DesactivarNotificacion(item) {
      var nombre = sessionStorage.getItem("uid");
      let ref = db
        .collection("noty" + nombre)
        .doc("notificacionPersonal" + item)
        .update({
          estatus: false,
        }); // Con este metodo actualizamos la notificacion a false.
    },
    ConsultarColaboradores() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 18);
      parametros.append("idTarea", this.datos.idTarea);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            response.data.forEach((element) => {});
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    ValidarExistenciaNotificacion(
      uid,
      message,
      type,
      nombreGrupo,
      nombre,
      idGrupo,
      doc
    ) {
      /*
      //este metodo es para notificar al usuario

      moment.locales("es");
      console.log("noty" + uid + " **" + doc);

      let ref = db.collection("noty" + uid).doc(doc); // con el order by ordena los datos de acuerdo al campo especificado.

      ref.onSnapshot((querySnapshot) => {
        if (querySnapshot.data().notificado) {
          db.collection("noty" + uid)
            .doc(doc)
            .set({
              //Nodo padre del que queremos sacar los nodos hijos
              name: nombre,
              type: type,
              message: message,
              time: Date.now(),
              idEmpleado: sessionStorage.getItem("uid"),
              estatus: true,
              notificado: false,
              nombreGrupo: nombreGrupo,
              idGrupo: idGrupo,
              url: this.urlAvatar,
            })
            .then(() => {
              console.log("Document successfully written!");
            })
            .catch((error) => {
              console.log(error);
            }); //promesa para el error
          //this.ConsultarMensajes();
        } else {
        }

        ref.unsubscribe();
      });
      ref.unsubscribe();*/
    },
    Enviarnoty(uid, message, type, nombreGrupo, nombre, idGrupo) {
      //funcion que notifica a usuario po medio de firebase RT DB
      var doc = "";

      if (type == 3) {
        doc = "notificacionGrupo" + idGrupo;
      } else {
        doc = "notificacionPersonal" + sessionStorage.getItem("uid");
      }

      /* this.ValidarExistenciaNotificacion(
        uid,
        message,
        type,
        nombreGrupo,
        nombre,
        idGrupo,
        doc
      ); */

      db.collection("noty" + uid)
        .doc(doc)
        .set({
          //Nodo padre del que queremos sacar los nodos hijos
          name: nombre,
          type: type,
          message: message,
          time: Date.now(),
          idEmpleado: sessionStorage.getItem("uid"),
          estatus: true,
          notificado: false,
          nombreGrupo: nombreGrupo,
          idGrupo: idGrupo,
          url: this.urlAvatar,
        })
        .then(() => {
          console.log("Document successfully written!");
        })
        .catch((error) => {
          console.log(error);
        });
      //this.ConsultarMensajes();
    },
    CerrarSelect() {
      this.encargados = false;
      this.estatusSelect = false;
    },
    CerrarArchivos() {
      this.estatusArchivo = false;
      this.estatusArchivos = false;
    },
    BuscarImagen(message) {
      this.archivo = message.data.file;
      this.SubirImagen();
    },
    BuscarImagenGrupo(message) {
      this.archivo = message.data.file;
      this.SubirImagenGrupo();
    },
    async SubirImagenGrupo() {
      try {
        const refImagen = storage
          .ref()
          .child("Archivos" + this.nodoGrupo)
          .child(this.archivo.name);

        const res = await refImagen.put(this.archivo);

        const urlDescarga = await refImagen.getDownloadURL();

        this.EnviarURLGrupo(urlDescarga, this.archivo.name);
      } catch (error) {
        console.log(error);
      }
    },
    EnviarURLGrupo(url, name) {
      var nombre = sessionStorage.getItem("fullname");
      var urk = url;
      var mensaje = name;
      db.collection(this.nodoGrupo)
        .add({
          name: nombre,
          message: mensaje,
          url: url,
          type: "file",
          download: true,
          time: Date.now(),
          status: 1,
        })
        .catch((error) => {
          console.log(error);
        });

      this.selectEncargados.forEach((element) => {
        this.Enviarnoty(
          element.idEmpleado,
          "New group: " + this.nombreGrupo,
          "3",
          this.idGrupoActual,
          this.nombreGrupo,
          this.idGrupoAuxil
        );
        this.ConsultarGrupos();
      });
    },

    async SubirImagen() {
      try {
        const refImagen = storage
          .ref()
          .child("Archivos" + this.nodo)
          .child(this.archivo.name);

        const res = await refImagen.put(this.archivo);

        const urlDescarga = await refImagen.getDownloadURL();

        this.EnviarURL(urlDescarga, this.archivo.name);
        this.Enviarnoty(
          sessionStorage.getItem("idEmpleadoChat"),
          "New message: " + sessionStorage.getItem("nombre"),
          "2",
          " ",
          sessionStorage.getItem("fullname"),
          " "
        );
      } catch (error) {
        console.log(error);
      }
    },
    EnviarURL(url, name) {
      var nombre = sessionStorage.getItem("fullname");
      var urk = url;
      var mensaje = name;
      db.collection(this.nodo)
        .add({
          name: nombre,
          message: mensaje,
          url: url,
          type: "file",
          download: true,
          time: Date.now(),
          status: 1,
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error
    },
    CambiarEstatusNotificacion(item) {
      var id = sessionStorage.getItem("uid");

      let ref = db
        .collection("noty" + id)
        .doc(item.id)
        .update({
          estatus: false,
        }); // Con este metodo actualizamos la notificacion a false.
    },
    CambiarEstatusNotificacionCliente(item) {
      var id = sessionStorage.getItem("uid");

      let ref = db.collection("ChatClienteNotificaciones").doc(item.id).update({
        estatus: false,
      }); // Con este metodo actualizamos la notificacion a false.
    },
    ReactivarEstatusNotificacion(item) {
      var nombre = sessionStorage.getItem("uid");
      let ref = db
        .collection("noty" + nombre)
        .doc(item.id)
        .update({
          estatus: true,
        }); // Con este metodo actualizamos la notificacion a false.
    },
    IngresarFoto() {
      this.dialogoFoto = true;
    },
    SubirFoto(event) {
      this.SubirAvatar(event);
    },
    async SubirAvatar(event) {
      try {
        const refImagen = storage
          .ref()
          .child(sessionStorage.getItem("uid"))
          .child(event.name);

        const res = await refImagen.put(event);

        const urlDescarga = await refImagen.getDownloadURL();

        this.subirURLAvatar(urlDescarga);
      } catch (error) {
        console.log(error);
      }
    },
    subirURLAvatar(url) {
      //Actualizacion de foto de perfil
      db.collection("conexiones")
        .doc(sessionStorage.getItem("uid"))
        .update({
          url: url,
        })
        .then((response) => {
          // console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    notificacionPush(element) {
      //Manda las notificaciones al buscador google, microsoft edge, etc.
      if (Notification.permission !== "granted") {
        Notification.requestPermission();
      } else {
        var notificacion = new Notification("MyFastax.com", {
          icon: "https://myfastax.com/src/assets/X1.png",
          body: element.data().message,
        });

        notificacion.onclick = function () {
          //this.idNotificacion = '';
          if (element.data().type == 1) {
            window.location =
              "https://myfastax.com/TaskDetail/" + element.data().idTarea;
          } else if (element.data().type == 2) {
            if (element.data().idEmpleado > sessionStorage.getItem("uid")) {
              this.nodo =
                "chat-" +
                element.data().idEmpleado +
                "-" +
                sessionStorage.getItem("uid");
            } else {
              this.nodo =
                "chat-" +
                sessionStorage.getItem("uid") +
                "-" +
                element.data().idEmpleado;
            }
            window.location =
              "https://myfastax.com/ChatNotificacion/" + this.nodo;
          } else if (element.data().type == 3) {
            window.location =
              "https://myfastax.com/GroupChat/" + element.data().nombreGrupo;
          }

          this.close();
        };
      }
    },
    MostrarDepartamentos() {
      //no funcional
      this.idDepartamentos.forEach((element) => {
        if (element.idDepartamento == 12) {
          this.departamentos.payroll = true;
        }

        if (element.idDepartamento == 88) {
          this.departamentos.bookkepping = true;
        }

        if (element.idDepartamento == 89) {
          this.departamentos.system = true;
        }

        if (element.idDepartamento == 90) {
          this.departamentos.support = true;
        }

        if (element.idDepartamento == 91) {
          this.departamentos.managers = true;
        }

        if (element.idDepartamento == 92) {
          this.departamentos.loans = true;
        }

        if (element.idDepartamento == 93) {
          this.departamentos.taxes = true;
        }
      });
    },
    CambiarEstatusNotificiacionActiva(element) {
      var nombre = sessionStorage.getItem("uid");
      let ref = db
        .collection("noty" + nombre)
        .doc(element.id)
        .update({
          notificado: true,
        })
        .then(() => {})
        .catch((error) => {
          console.log(error);
        }); // Con este metodo actualizamos la notificacion a false.
    },
    InsertarCliente() {
      if (this.passwordEmpresa == this.passwordEmpresaConfirm) {
        let parametros = new URLSearchParams();
        parametros.append("accion", 9);
        parametros.append("nombreEmpresa", this.nombreEmpresa);
        parametros.append("nombreContacto", this.nombreContacto);
        parametros.append("telefonoContacto", this.telefonoContacto);
        parametros.append("idioma", this.idioma);
        parametros.append("correo", this.emailEmpresa);
        parametros.append("password", this.password);

        axios({
          method: "post",
          url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
          data: parametros,
        })
          .then(
            function (response) {
              //console.log(response.data);
            }.bind(this)
          )
          .catch(
            function (response) {
              //handle error
              console.log("Error" + response);
            }.bind(this)
          );
      } else {
        alert("Passwords do not match or Not Registered ");
      }
    },
    VerListaCompanies() {
      this.nombreCliente = "";
      this.nombreServicio = "list of companies";
      sessionStorage.removeItem("idCliente");
      EventBus.$emit("reload-table");
      this.$router.push("/Clientes").catch((failure) => {});
    },
    VerListaClientes() {
      this.nombreCliente = "";
      this.nombreServicio = "List of Clients";
      sessionStorage.removeItem("idCliente");
      EventBus.$emit("reload-table");
      this.$router.push("/Clients").catch((failure) => {});
    },
    Permisos() {
      if (!("Notification" in window)) {
        // el navegador no soporta la API de notificaciones
        alert("Su navegador no soporta la API de Notificaciones :(");
        return;
      } else if (Notification.permission === "granted") {
        // Se puede emplear las notificaciones

        notification = new Notification("Hola Mundo");
      } else if (Notification.permission !== "denied") {
        // se pregunta al usuario para emplear las notificaciones
        Notification.requestPermission(function (permission) {
          if (permission === "granted") {
            notification = new Notification("Hola Mundo");
          }
        });
      }
    },
    onChange() {
      this.filelist = [...this.$refs.file.files];
    },
    remove(i) {
      this.filelist.splice(i, 1);
      this.filelist.clear();
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      this.BuscarArchivo(event.dataTransfer.files);
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    BuscarArchivo(file) {
      this.dialog = true;
      this.archivo = file[0];
      this.SubirImagen();
    },
  },
};
document.addEventListener("DOMContentLoaded", function () {
  if (!Notification) {
    alert("las notificaciones no soportan en tu navegador, baja");
    return;
  }

  if (Notification.permission !== "granted") {
    Notification.requestPermission();
  }
});
</script>
