<template>
  <v-form>
    <v-card>
      <v-row>
        <v-col cols="6" class="ml-15 mr-4"
          ><v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field
        ></v-col>
      </v-row>

      <v-data-table
        v-if="!idCliente"
        :search="search"
        :headers="headers"
        :items="clientes"
        class="elevation-24 ml-2"
        @click:row="ObtenerIdCliente"
      >
      </v-data-table>
    </v-card>
  </v-form>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      idCliente: sessionStorage.getItem("idCliente"),
      search: "",
      clientes: [],
      headers: [
        {
          text: "company",
          align: "start",
          sortable: false,
          value: "nombre",
          align: "start",
        },
      ],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.ConsultarClientes();
  },
  methods: {
    ShowTools() {
      this.$emit("show");
    },
    ConsultarClientes() {
      let parametros = new URLSearchParams();
      this.usuariosChat = [];
      parametros.append("accion", 1);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.clientes = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ObtenerIdCliente(item) {
      sessionStorage.setItem("idCliente", item.idCliente);
      sessionStorage.setItem("businessType", item.businessType);
      sessionStorage.setItem("cliente", item.nombre);
      this.idCliente = item.idCliente;
      this.ShowTools();
    },
  },
};
</script>

<style>
</style>